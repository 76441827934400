const DATA_TEST_IDS = {
  fragmentsHamburgerButton:
    'layouts__main-layout_header-stripe_hamburger-button',
  fragmentsQuestionMarkButton:
    'layouts__main-layout_header-stripe_question-mark-button',
  fragmentsQuestionMarkButtonMenu:
    'layouts__main-layout_header-stripe_question-mark-button-menu',
} as const;

export default DATA_TEST_IDS;
