import React, { FC } from 'react';
import { useTranslate } from '@/i18n/useTranslate.ts';
import { Warning } from '@/components';
import { Typography } from '@mui/material';
import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';
export const SelectionDialogWarning: FC<{
  open: boolean;
  deselect: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}> = ({ open, deselect, onCancel, onSubmit }) => {
  const { t } = useTranslate('module');
  return (
    <Warning
      data-test-id={testId(
        DATA_TEST_IDS.fragmentsSelectionDialogWarning + '_warning-dialog'
      )}
      open={open}
      title={
        deselect ? t('DESELECT_ALL_PERMISSIONS') : t('SELECT_ALL_PERMISSIONS')
      }
      onClose={onCancel}
      buttons={[
        {
          children: t('NO'),
          variant: 'secondary',
          onClick: onCancel,
        },
        {
          children: t('YES'),
          variant: 'primary',
          onClick: onSubmit,
        },
      ]}
    >
      <Typography component={'strong'} variant={'16_24_700'}>
        {deselect
          ? t('ARE_YOU_SURE_YOU_WANT_TO_DESELECT_ALL_PERMISSIONS')
          : t('ARE_YOU_SURE_YOU_WANT_TO_SELECT_ALL_PERMISSIONS')}
      </Typography>
      <Typography variant={'16_24_400'}>
        {deselect
          ? t(
              'DESELECTING_ALL_PERMISSIONS_WILL_OVERWRITE_YOUR_PREVIOUS_CHOICES'
            )
          : t('SELECTING_ALL_PERMISSIONS_WILL_OVERWRITE_YOUR_PREVIOUS_CHOICES')}
      </Typography>
    </Warning>
  );
};
