import { FC } from 'react';
import { DateTimeFormat, DEFAULT_DATE_FORMAT } from './constants.ts';
import { formatDate } from './formatDate.tsx';

export const DateFormat: FC<{
  children: string | undefined | null;
  format?: DateTimeFormat;
}> = ({ children, format = DEFAULT_DATE_FORMAT }) => {
  const date = formatDate(children, format);
  return <>{date}</>;
};
