import { Breadcrumbs } from '@/components/Breadcrumbs/Breadcrumbs.tsx';
import { Link } from '@/components';
import React, { ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';
import ChevronLeft from 'ui-kit/lib/icons/24/Arrow/ChevronLeft';
import { Stack } from '@mui/material';
import { useBreakpoint } from 'ui-kit';
import { inheritDataTestId } from '@/utils/testId.ts';

type BDirs = 'left' | 'right';

interface BreadcrumbsFragmentProps {
  direction: BDirs;
  to?: LinkProps['to'];
  onClick?: () => void;
  children: ReactNode;
}
export const BreadcrumbsFragment = ({
  direction,
  to,
  onClick,
  children,
  ...props
}: BreadcrumbsFragmentProps) => {
  const { xsOnly } = useBreakpoint('xs');
  return (
    <Stack
      direction={'row'}
      gap={3}
      sx={styles.container}
      onClick={onClick}
      data-test-id={inheritDataTestId(props, 'container')}
    >
      {direction === 'left' && <ChevronLeft size={xsOnly ? 12 : 18} />}
      <Breadcrumbs>
        <Link to={to ?? ''} data-test-id={inheritDataTestId(props, 'bc-link')}>
          {children}
        </Link>
      </Breadcrumbs>
    </Stack>
  );
};

const styles = {
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 3,
    '*': {
      fontSize: { xs: '12px!important', sm: '16px' },
      lineHeight: { xs: '22px!important' },
    },
  },
};
