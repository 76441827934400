/** @jsxImportSource @emotion/react */
import { H3 } from '@/components/Text';
import { FC, PropsWithChildren } from 'react';
import {
  Box,
  styled,
  SxProps,
  Theme,
  Typography,
  TypographyTypeMap,
} from '@mui/material';
import { spreadSx } from '@/utils/spreadSx';
import { boxPaddingsSx } from '@/components/WhiteBox/constants.ts';

export const BoxBody = styled(Box)`
  flex: 1 1 auto;
`;

/**
 * @deprecated - use BoxHeader
 */
export const BoxHeaderDeprecated = styled(H3)`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`;
BoxHeaderDeprecated.defaultProps = {
  marginBottom: { xs: 36, sm: 64, lg: 36 },
};
export const BoxHeader: FC<
  PropsWithChildren<{
    sx?: SxProps<Theme>;
    mb?: TypographyTypeMap['props']['mb'];
  }>
> = ({ children, sx, mb }) => {
  return (
    <Typography
      mb={mb}
      component={'h2'}
      sx={[
        {
          fontSize: { xs: '20px', sm: '22px', lg: '24px' },
          lineHeight: { xs: '24px', sm: '30px', lg: '30px' },
          fontWeight: { xs: 500 },
          mb: mb ?? { xs: 24, sm: 36 },
        },
        ...spreadSx(sx),
      ]}
    >
      {children}
    </Typography>
  );
};

const sx = (t: Theme) => ({
  backgroundColor: t.colors.background,
  padding: boxPaddingsSx.padding,
  width: '100%',
  boxSizing: 'border-box',
  border: `1px solid ${t.colors.borderLight}`,
  display: 'flex',
  flexDirection: 'column',
  [t.breakpoints.between('xs', 'lg')]: {
    height: 'auto',
  },
});
export const WhiteBox: FC<
  Parameters<typeof Box>[0] & { noXPaddings?: boolean }
> = ({ noXPaddings, ...props }) => {
  return (
    <Box
      className={'white-box'}
      {...props}
      sx={[
        sx,
        noXPaddings
          ? { paddingLeft: '0!important', paddingRight: '0!important' }
          : {},
        ...spreadSx(props.sx),
      ]}
    />
  );
};
