import { Stack } from '@mui/material';
import { FC } from 'react';
import { useMQuery } from '@/hooks/useMQuery';
import { ProfileMenuContainer } from '@/features/profile-menu';
import { HamburgerButton } from './fragments/HamburgerButton';
import { HeaderStripeView } from './HeaderStripeView';
import { QuestionMarkButtonMenu } from './fragments/QuestionMarkButtonMenu';
import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';
export const HeaderStripe: FC<{
  onClick: React.MouseEventHandler;
  toggleMenu: () => void;
  menuIsOpen: boolean;
}> = ({ onClick, toggleMenu, menuIsOpen }) => {
  const { mobile, tablet } = useMQuery();
  const onlyForDesktopAndMore = !mobile && !tablet;
  return (
    <HeaderStripeView
      onClick={onClick}
      leftContent={
        tablet || mobile ? (
          <HamburgerButton
            data-test-id={testId(DATA_TEST_IDS.headerStripe + '_hamburger-btn')}
            toggle={toggleMenu}
            open={menuIsOpen}
          />
        ) : undefined
      }
      rightContent={
        <Stack
          direction={'row'}
          sx={{ justifyContent: 'center', alignItems: 'center', gap: 24 }}
        >
          <ProfileMenuContainer />
          {onlyForDesktopAndMore && <QuestionMarkButtonMenu />}
        </Stack>
      }
    />
  );
};
