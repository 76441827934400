import { AxiosHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { AppRoutes } from '@/routes/appRoutes';
import { Statuses } from './types';
import { browserHistory, RootState } from '@/store/store';
import { EnhancedStore } from '@reduxjs/toolkit';
import { permissionsActions } from '@/store/permissions/slice';
import { UUID } from '@/types';
import { RequestParams } from '@/api/__generated__/webApi/http-client';

const PERMISSIONS_DIGEST_HEADER_NAME = 'Permissions-Digest';
const PERMISSIONS_INVALID_HEADER_NAME = 'Permissions-Invalid';
let reduxStore: EnhancedStore<RootState>;

type RequestInterceptor = [
  onFulfilled: (
    value: InternalAxiosRequestConfig
  ) => InternalAxiosRequestConfig,
  // eslint-disable-next-line
  onRejected?: (error: any) => any,
];
type ResponseInterceptor = [
  onFulfilled: (value: AxiosResponse) => AxiosResponse,
  // eslint-disable-next-line
  onRejected?: (error: any) => any,
];

export const injectStore = (store: EnhancedStore<RootState>) => {
  reduxStore = store;
};

const unauthorizedResponseInterceptor: ResponseInterceptor = [
  (response: AxiosResponse) => {
    return response;
  },
  // eslint-disable-next-line
  (e: any): any => {
    if (e.response.status === Statuses.UNAUTHORIZED) {
      if (browserHistory.location.pathname !== AppRoutes.AUTH) {
        console.info?.(
          'Redirected due to 401 response to ',
          e.request.responseURL
        );
        const url = `${AppRoutes.AUTH}${
          window.location.search ? `?${window.location.search}` : ''
        }`;
        browserHistory.push(url);
      }
      return;
    }
    return Promise.reject(e);
  },
];
export const withPermissionDigestForCompany = (companyId: UUID) => {
  return { query: { permissionsCompanyId: companyId } } as RequestParams;
};
const permissionsRequestInterceptor: RequestInterceptor = [
  (request) => {
    const url = new URL(request.url ?? '', request.baseURL);
    const companyId = url.searchParams.get('permissionsCompanyId');
    if (!companyId) {
      return request;
    } else {
      url.searchParams.delete('permissionsCompanyId');
      request.url = url.pathname + url.search;
      const hash =
        reduxStore.getState().permissions.permissions?.byID[companyId]
          ?.permissionsDigest;
      (request.headers as unknown as AxiosHeaders).set(
        PERMISSIONS_DIGEST_HEADER_NAME,
        hash
      );
    }
    return request;
  },
  (e) => e,
];

/**
 * @description
 * If you see the error in console
 * 'REQUIRED `Permission-Digest` HEADER IS UNDEFINED FOR THE REQUEST' -
 * then you're probably making some company related request without permissions token
 * What to do? use withPermissionDigestForCompany fn defined above as last param for your api method
 * (you also can find some examples by searching in the code)
 */
const permissionsResponseInterceptor: ResponseInterceptor = [
  (response) => {
    const invalidPermissionsCompanyUuid: string = (
      response?.headers as unknown as AxiosHeaders
    )?.get(PERMISSIONS_INVALID_HEADER_NAME) as string;
    if (invalidPermissionsCompanyUuid) {
      const permissionsDigestExists = (
        response?.config?.headers as unknown as AxiosHeaders
      )?.get(PERMISSIONS_DIGEST_HEADER_NAME);
      if (!permissionsDigestExists) {
        console.error(
          '[API_INTERCEPTORS -> PERMISSION_INTERCEPTORS] REQUIRED `Permission-Digest` HEADER IS UNDEFINED FOR THE REQUEST',
          response
        );
      }
      reduxStore.dispatch(
        permissionsActions.requestCompanyPermissions({
          companyId: invalidPermissionsCompanyUuid,
        })
      );
    }
    return response;
  },
  (e) => {
    const response = e.response as AxiosResponse;
    const invalidPermissionsCompanyUuid: string = (
      response.headers as unknown as AxiosHeaders
    )?.get(PERMISSIONS_INVALID_HEADER_NAME) as string;
    if (invalidPermissionsCompanyUuid) {
      reduxStore.dispatch(
        permissionsActions.requestCompanyPermissions({
          companyId: invalidPermissionsCompanyUuid,
        })
      );
    }
    return Promise.reject(e);
  },
];

export const allResponseInterceptors = [
  unauthorizedResponseInterceptor,
  permissionsResponseInterceptor,
];
export const allRequestInterceptors = [permissionsRequestInterceptor];
