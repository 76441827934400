/** @jsxImportSource @emotion/react */
import React, { FC, useContext, useEffect, useId, useState } from 'react';
import { FileInput } from './FileInput';
import { DropZoneViewComp } from './DndDropZoneView';
import { FileContext } from './FileContext.tsx';
import { inheritDataTestId } from '@/utils/testId.ts';

export const XLSX_ACCEPT_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export type FileChangeHandler = (
  e: { target: { name: string; files: FileList } },
  files: FileList
) => void;

export interface DnDProps {
  label?: string;
  accept?: string;
  multiple?: boolean;
  dropZoneView: JSX.Element;
}
export const DnDFileInput: FC<DnDProps> = ({
  label,
  accept,
  multiple,
  dropZoneView,
  ...props
}) => {
  const { handleChange, handleAddFiles } = useContext(FileContext);
  const [active, setActive] = useState(false);
  const dropzoneId = useId();
  const dropZoneViewCloned = React.cloneElement<
    Parameters<DropZoneViewComp>[0]
  >(dropZoneView as JSX.Element, { active, label });

  const handleDragEnter: React.DragEventHandler = (e) => {
    e.preventDefault();
    setActive(true);
  };
  const handleDragLeave: React.DragEventHandler = (e) => {
    setActive(false);
    e.preventDefault();
  };

  const handleFileDrop: React.DragEventHandler = (e) => {
    setActive(false);
    e.preventDefault();
    const files = e?.dataTransfer.files;
    handleAddFiles(files);
  };

  useEffect(() => {
    const handler = function (e: DragEvent) {
      if (
        (e.target as HTMLElement)?.id !== dropzoneId &&
        (e.currentTarget as HTMLElement)?.id !== dropzoneId
      ) {
        e.preventDefault();
        if (e.dataTransfer) {
          e.dataTransfer.effectAllowed = 'none';
          e.dataTransfer.dropEffect = 'none';
        }
      }
    };
    window.addEventListener('dragenter', handler, false);
    window.addEventListener('dragover', handler);
    window.addEventListener('drop', handler);
    return () => {
      window.removeEventListener('dragenter', handler, false);
      window.removeEventListener('dragover', handler);
      window.removeEventListener('drop', handler);
    };
  }, [dropzoneId]);

  return (
    <FileInput
      id={dropzoneId}
      accept={accept}
      multiple={multiple}
      onChange={handleChange}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDropCapture={handleFileDrop}
      data-test-id={inheritDataTestId(props)}
    >
      {dropZoneViewCloned}
    </FileInput>
  );
};
