/** @jsxImportSource @emotion/react */
import { css as cssClassName } from '@emotion/css';
import React, { FC, ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import IconSVGSuccess from 'ui-kit/lib/icons/IconsFilled/24/Alert/Success';
import { H5, Text } from '@/components/Text';
import { TKeys, useTranslate } from '@/i18n/useTranslate';
import { css } from '@mui/material';
import { paletteColors } from '@/theme';

import IconError from 'ui-kit/lib/icons/IconsFilled/24/Alert/Error';
import IconClose from 'ui-kit/lib/icons/24/Action/Close';

export const IconCloseBtn = () => {
  return (
    <div
      css={css`
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <IconClose />
    </div>
  );
};

// error

const ErrorToastContent: FC<{
  header?: string;
  text?: TKeys<'common' | 'validationErrors'>;
}> = ({ header, text }) => {
  const { t } = useTranslate('common');
  return (
    <>
      <H5 lh={20} fz={14} weight={700} mb={4}>
        {header ?? t('TOAST_ERROR_HEADER')}
      </H5>
      <Text lh={20} fz={12}>
        {text && t(text)}
      </Text>
    </>
  );
};
// eslint-disable-next-line react-refresh/only-export-components
export const customErrorStyles = cssClassName`
    border: 1px solid ${paletteColors.error[200]};
    .Toastify {
        &__progress-bar {
            background: ${paletteColors.error[200]};
        }
    }
`;
// eslint-disable-next-line react-refresh/only-export-components
export const errorToast = (
  data: { header?: string; text: TKeys<'common' | 'validationErrors'> },
  options?: ToastOptions
) => {
  toast.error(() => <ErrorToastContent {...data} />, {
    type: toast.TYPE.DEFAULT,
    className: customErrorStyles,
    icon: <IconError />,
    closeButton: <IconCloseBtn />,
    toastId: data.text,
    ...options,
  });
  toast.update(data.text, options);
};

// success

const SuccessToastContent: FC<{ header?: string; text?: ReactNode }> = ({
  header,
  text,
}) => {
  const { t } = useTranslate('common');
  return (
    <>
      <H5 lh={20} fz={14} weight={700} mb={4}>
        {header ?? t('SUCCESS_TOAST_HEADER')}
      </H5>
      <Text lh={20} fz={12}>
        {text}
      </Text>
    </>
  );
};
const customSuccessStyles = cssClassName`
    border: 1px solid ${paletteColors.success[200]};
    .Toastify {
        &__progress-bar {
            background: ${paletteColors.success[200]};
        }
    }
`;
// eslint-disable-next-line react-refresh/only-export-components
export const successToast = (
  data: { header?: string; text: ReactNode },
  options?: ToastOptions
) => {
  toast.success(() => <SuccessToastContent {...data} />, {
    type: toast.TYPE.DEFAULT,
    className: customSuccessStyles,
    icon: <IconSVGSuccess />,
    closeButton: <IconCloseBtn />,
    autoClose: 10000,
    ...options,
  });
};
