/** @jsxImportSource @emotion/react */
import {
  AppRoutes,
  generatePath,
  UrlToModuleTypeMap,
} from '@/routes/appRoutes';
import { FormikConfig } from 'formik';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { companiesActions } from '@/store/companies/slice';
import { useNewRoleForm } from '@/store/companies/hooks';
import { RoleFormView } from '@/features/module/ModulePage/fragments/RoleFormView';
import { SxProps, Theme } from '@mui/material';
import { NEW_ROLE_ID_STUB } from '@/features/module/constants.ts';
import { ModuleType } from '@/api/__generated__/webApi';

export interface RoleFormType {
  name: string;
  description: string;
}

interface RoleFormProps {
  back: () => void;
  className?: string;
  sx?: SxProps<Theme>;
}

export const CreateNewRoleForm: FC<RoleFormProps> = ({
  back,
  className,
  sx,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    resetRoleErrors,
    roleCreationErrors,
    newRoleData: role,
  } = useNewRoleForm();
  const handleSubmit: FormikConfig<RoleFormType>['onSubmit'] = useCallback(
    (v) => {
      if (!params.moduleId || !params.module) {
        console.error('[handleSubmit] moduleId not found');
        return;
      }
      dispatch(
        companiesActions.setRoles({
          moduleId: params.moduleId,
          roles: [{ ...v, id: NEW_ROLE_ID_STUB, editable: true }],
        })
      );
      const moduleType = UrlToModuleTypeMap[params.module];
      if (moduleType === ModuleType.LIS) {
        navigate({
          pathname: generatePath(
            AppRoutes.COMPANY_MODULES_PERMISSIONS_LIMS_EDIT,
            {
              companyId: params.companyId,
              module: params.module,
              moduleId: params.moduleId,
              roleId: NEW_ROLE_ID_STUB,
            }
          ),
          search: window.location.search,
        });
      } else {
        navigate({
          pathname: generatePath(AppRoutes.COMPANY_MODULES_PERMISSIONS_EDIT, {
            companyId: params.companyId,
            module: params.module,
            moduleId: params.moduleId,
            roleId: NEW_ROLE_ID_STUB,
          }),
          search: window.location.search,
        });
      }
    },
    [dispatch, navigate, params.companyId, params.module, params.moduleId]
  );
  return (
    <>
      <RoleFormView
        onSubmit={handleSubmit}
        resetErrors={resetRoleErrors}
        cancel={back}
        values={role as RoleFormType}
        errors={roleCreationErrors}
        className={className}
        sx={sx}
      />
    </>
  );
};
