/** @jsxImportSource @emotion/react */
import { Grid } from '@/components';
import { useTranslate } from '@/i18n/useTranslate';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProfileData } from '@/store/profile/hooks';
import { Button } from '@/components/Button';
import { WhiteBox, BoxHeaderDeprecated, BoxBody } from '@/components/WhiteBox';
import { useMQuery } from '@/hooks/useMQuery';
import IconSVGArrowDown from 'ui-kit/lib/icons/24/Arrow/ChevronDown';
import IconSVGArrowUp from 'ui-kit/lib/icons/24/Arrow/ChevronUp';
import { Box, css } from '@mui/material';
import { Dl } from '@/components/Dl';
import { DateFormat } from '@/components/DateFormat';
import {
  BIRTH_SEX_ENUM_MAP,
  ETHNICITY_ENUM_MAP,
  GENDER_ENUM_MAP,
  RACE_ENUM_MAP,
  SEXUAL_ORIENTATION_ENUM_MAP,
} from '@/features/profile/ProfileEdit/enumMaps';
import { EMPTY } from '@/constants';
import { BoxFooter } from '@/components/WhiteBox/BoxFooter.tsx';
import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';
export const ProfileInfo: FC = () => {
  const { t } = useTranslate('profile');
  const [open, setOpen] = useState(false);
  const { details: profile } = useProfileData(true);
  const navigate = useNavigate();
  const { mobile } = useMQuery();
  const dateOfBirth = profile?.contact?.birthDate;

  const toggle = () => setOpen((o) => !o);
  if (mobile && !open) {
    return (
      <WhiteBox
        onClick={toggle}
        data-test-id={testId(
          DATA_TEST_IDS.profileProfileInfo + '_clickable-container'
        )}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <BoxHeaderDeprecated mb={0}>{t('PERSONAL_DATA')}</BoxHeaderDeprecated>
          <IconSVGArrowDown />
        </div>
      </WhiteBox>
    );
  }

  return (
    <WhiteBox
      onClick={toggle}
      data-test-id={testId(
        DATA_TEST_IDS.profileProfileInfo + '_clickable-container'
      )}
    >
      {mobile ? (
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 36px;
          `}
        >
          <BoxHeaderDeprecated mb={0}>{t('PERSONAL_DATA')}</BoxHeaderDeprecated>
          <IconSVGArrowUp />
        </div>
      ) : (
        <BoxHeaderDeprecated>{t('PERSONAL_DATA')}</BoxHeaderDeprecated>
      )}
      <BoxBody>
        <Grid container columnSpacing={24} rowSpacing={{ xs: 12, sm: 48 }}>
          <Grid xs={12} sm={4} md={4}>
            <Dl>
              <dt>{t('DATE_OF_BIRTH')}</dt>
              <dd>
                {dateOfBirth ? <DateFormat>{dateOfBirth}</DateFormat> : '—'}
              </dd>
            </Dl>
            <Box width={'100%'} height={{ xs: 24, sm: 0 }} />
          </Grid>
          <Grid
            xs={12}
            sm={4}
            md={4}
            sx={{ display: { xs: 'none', sm: 'block' } }}
          />
          <Grid
            xs={12}
            sm={4}
            md={4}
            sx={{ display: { xs: 'none', sm: 'block' } }}
          />
          <Grid xs={12} sm={4} md={4}>
            <Dl>
              <dt>{t('BIRTH_SEX')}</dt>
              <dd>
                {profile?.contact?.birthSex
                  ? t(BIRTH_SEX_ENUM_MAP[profile.contact.birthSex])
                  : EMPTY}
              </dd>
            </Dl>
          </Grid>
          <Grid xs={12} sm={4} md={4}>
            <Dl>
              <dt>{t('GENDER')}</dt>
              <dd>
                {profile?.contact?.gender
                  ? t(GENDER_ENUM_MAP[profile.contact.gender])
                  : EMPTY}
              </dd>
            </Dl>
          </Grid>
          <Grid xs={12} sm={4} md={4}>
            <Dl>
              <dt>{t('SEXUAL_ORIENTATION')}</dt>
              <dd>
                {profile?.contact?.sexualOrientation
                  ? t(
                      SEXUAL_ORIENTATION_ENUM_MAP[
                        profile.contact.sexualOrientation
                      ]
                    )
                  : EMPTY}
              </dd>
            </Dl>
            <Box width={'100%'} height={{ xs: 24, sm: 0 }} />
          </Grid>
          <Grid xs={12} sm={4} md={4}>
            <Dl>
              <dt>{t('RACE')}</dt>
              <dd>
                {profile?.contact?.race
                  ? t(RACE_ENUM_MAP[profile.contact.race])
                  : EMPTY}
              </dd>
            </Dl>
          </Grid>
          <Grid xs={12} sm={4} md={4}>
            <Dl>
              <dt>{t('ETHNICITY')}</dt>
              <dd>
                {profile?.contact?.ethnicity
                  ? t(ETHNICITY_ENUM_MAP[profile.contact.ethnicity])
                  : EMPTY}
              </dd>
            </Dl>
          </Grid>
        </Grid>
      </BoxBody>
      <BoxFooter
        text={t(
          'PLEASE_COMPLETE_THE_INFORMATION_IN_YOUR_PROFILE_IT_WILL_BE_NEEDED_WHEN_PLACING_AN_ORDER_AND_PAYING_FOR_IT'
        )}
      >
        <Button
          data-test-id={testId(
            DATA_TEST_IDS.profileProfileInfo + '_button-edit'
          )}
          color="primary"
          fullWidth={mobile}
          onClick={() =>
            navigate({ pathname: 'edit', search: window.location.search })
          }
        >
          {t('EDIT')}
        </Button>
      </BoxFooter>
    </WhiteBox>
  );
};

export default ProfileInfo;
