import * as yup from 'yup';
import { useTranslate } from '@/i18n/useTranslate';
import { parsePhoneNumber } from 'react-phone-number-input';
import { TranslationKeys } from '@/i18n/TranslationKeys';
import { envs } from '@/constants';
// allows to load this huge package as a separate code chunk, in the background
const zxcvbn = import('zxcvbn');
export const phoneValidator = (
  t: ReturnType<typeof useTranslate<'auth'>>['t']
) =>
  yup
    .string()
    .required(t('PHONE_IS_REQUIRED'))
    .test('validate-phone', t('PHONE_VALIDATION_ERROR'), (value) => {
      const phoneNumber = parsePhoneNumber(value ?? '', 'US');
      return phoneNumber?.isValid() ?? false;
    });

export const phoneNotRequiredValidator = (
  t: ReturnType<typeof useTranslate<'auth'>>['t']
) =>
  yup
    .string()
    .nullable()
    .test('validate-phone', t('PHONE_VALIDATION_ERROR'), (value) => {
      if (!value) {
        return true;
      }
      const phoneNumber = parsePhoneNumber(value ?? '', 'US');
      return phoneNumber?.isValid() ?? false;
    });

export const passwordValidator = (
  t: ReturnType<typeof useTranslate<'password'>>['t']
) =>
  yup
    .string()
    .required(t('PASSWORD_IS_REQUIRED'))
    .test('strongPasswordTest', '', async function (this, value = '') {
      const { path, createError } = this;
      const result = (await zxcvbn).default(value);
      let errText = '';
      const resultErrorText =
        result.feedback.warning || result.feedback.suggestions[0];
      // env var can be 1 - 4, for the code 0 - 3
      // (in fact - 3 or 4, since setting up something less than 3 does not make any sense as well as more than 4)
      const envMaxScore = (Number(envs.MAX_PASSWORD_SCORE) || 4) - 1;
      if (result.score < 3) {
        errText = resultErrorText;
      } else if (result.score >= 3) {
        if (envMaxScore >= 3) {
          errText = resultErrorText;
        }
      }

      if (errText) {
        return createError({
          path,
          message: t(errText as keyof TranslationKeys['password']),
        });
      }
      return true;
    });
