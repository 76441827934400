import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import { rootSaga } from './sagas';
import { createBrowserHistory } from 'history';
import { authReducer, authStateName } from './auth/slice';
import { queryReducer, queryStateName } from './query/slice';
import { phonesReducer, phonesStateName } from '@/store/phones/slice';
import { npiReducer, npiStateName } from './npi-individual/slice';
import { companyNpiReducer, companyNpiStateName } from './npi-org/slice';
import { companiesReducer, companiesStateName } from '@/store/companies/slice';
import { emailsStateName, emailsStateReducer } from './emails/slice';
import { profileReducer, profileStateName } from './profile/slice';
import { notifyReducer, notifyStateName } from './notifications/slice';
import { permissionsReducer, permissionsStateName } from './permissions/slice';
import {
  REHYDRATE,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { cookieReducer, cookieStateName } from '@/features/cookies/redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { insuranceName, insuranceReducer } from '@/store/insurance/slice';
import {
  companiesSettingsStateName,
  companiesSettingsStateReducer,
} from '@/store/companies-settings/slice';
import { addressApi } from '@/features/profile-address/store/api';
import { contactAddressSlice } from '@/features/profile-address/store/slice';
import { profileQrSlice } from '@/store/profile-qr/slice';
import { profilePwdChangeSlice } from '@/store/profile-new-password/slice';
import { moduleSlice } from '@/store/module/slice';

const sagaMiddleware = createSagaMiddleware({
  onError(error, errorInfo) {
    console.error('[onError] Fatal error', error, errorInfo);
  },
});

const store = configureStore({
  reducer: {
    [authStateName]: authReducer,
    [queryStateName]: queryReducer,
    [phonesStateName]: phonesReducer,
    [npiStateName]: npiReducer,
    [companyNpiStateName]: companyNpiReducer,
    [companiesStateName]: companiesReducer,
    [companiesSettingsStateName]: companiesSettingsStateReducer,
    [emailsStateName]: emailsStateReducer,
    [profileStateName]: profileReducer,
    [notifyStateName]: notifyReducer,
    [permissionsStateName]: permissionsReducer,
    [cookieStateName]: cookieReducer,
    [insuranceName]: insuranceReducer,
    [addressApi.reducerPath]: addressApi.reducer,
    [contactAddressSlice.reducerPath]: contactAddressSlice.reducer,
    [profileQrSlice.reducerPath]: profileQrSlice.reducer,
    [profilePwdChangeSlice.reducerPath]: profilePwdChangeSlice.reducer,
    [moduleSlice.reducerPath]: moduleSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware, addressApi.middleware);
  },
  devTools: import.meta.env.DEV,
});

sagaMiddleware.run(rootSaga);
export const browserHistory = createBrowserHistory();

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
