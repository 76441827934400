import { useCompanyData } from '@/store/companies/hooks';
import { useAppDispatch, useAppSelector } from '@/store/store';
import { useParams } from '@/routes/appRoutes';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { companiesSettingsActions } from '@/store/companies-settings/slice';

export const useSettings = () => {
  const settingsState = useAppSelector((r) => r.companiesSettings);
  const { company } = useCompanyData();
  const { companyId } = useParams() as { companyId: string };
  const descriptionState = settingsState.descriptionForm[companyId];
  const nameState = settingsState.nameForm[companyId];
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(companiesSettingsActions.resetState());
    };
  }, [dispatch]);

  const callbacks = useMemo(() => {
    return {
      submitName() {
        dispatch(companiesSettingsActions.submitName({ companyId }));
      },
      submitDescription() {
        dispatch(companiesSettingsActions.submitDescription({ companyId }));
      },
      handleChange(e: ChangeEvent<HTMLInputElement>) {
        const name: 'name' | 'description' = e.target.name as
          | 'name'
          | 'description';
        switch (name) {
          case 'name':
            dispatch(
              companiesSettingsActions.setNameForm({
                value: e.target.value,
                companyId,
              })
            );
            break;
          case 'description':
            dispatch(
              companiesSettingsActions.setDescriptionForm({
                value: e.target.value,
                companyId,
              })
            );
            break;
        }
      },
      toggleDescriptionForm() {
        dispatch(companiesSettingsActions.toggleEditDescription({ companyId }));
      },
    };
  }, [companyId, dispatch]);

  return {
    description: descriptionState?.active
      ? (descriptionState?.data ?? company?.description)
      : company?.description,
    descriptionIsDraft:
      descriptionState?.data != null && descriptionState?.active
        ? descriptionState.data !== company?.description
        : false,
    name: nameState?.data ?? company?.name,
    nameIsDraft: nameState?.data != null && nameState?.data !== company?.name,
    validationErrors: {
      description: descriptionState?.validationError,
      name: nameState?.validationError,
    },
    hasName: !!company?.name,
    hasDescription: !!company?.description,
    descriptionFormIsActive: !!descriptionState?.active,
    ...callbacks,
  };
};
