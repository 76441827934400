import { AppRoutes, generatePath, useParams } from '@/routes/appRoutes';
import { useInit } from '@/hooks/useInit';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { UUID } from '@/types';
import { companiesActions as actions, FormNames } from '../slice';
import { useCompaniesState } from './useCompaniesState';
import { useNavigate } from 'react-router-dom';
import { NEW_ROLE_ID_STUB } from '@/features/module/constants.ts';

export const useFailedRoleCreationRedirect = () => {
  const state = useCompaniesState();
  const navigate = useNavigate();
  const { companyId, moduleId, module } = useParams();
  useEffect(() => {
    if (state.forms[FormNames.NewRole]?.state === 'failed') {
      navigate({
        pathname: generatePath(AppRoutes.COMPANY_MODULES, {
          companyId,
          module,
          moduleId,
          roleId: NEW_ROLE_ID_STUB,
        }),
        search: window.location.search,
      });
    }
  }, [companyId, module, moduleId, navigate, state.forms]);
};

export const usePermissions = () => {
  const dispatch = useDispatch();
  const state = useCompaniesState();
  const { companyId, roleId, moduleId } = useParams();
  const role =
    moduleId && roleId ? state.roles?.[moduleId]?.byID?.[roleId] : null;
  const permissions =
    roleId && moduleId
      ? (state.rolePermissions?.[moduleId]?.[roleId] ?? null)
      : null;
  const modulePermissions = moduleId
    ? state.modulePermissions?.[moduleId]
    : null;

  useLayoutEffect(() => {
    dispatch(actions.resetErrors());
  }, [dispatch, companyId, roleId, moduleId]);

  useEffect(() => {
    if (!companyId || state.error) {
      return;
    }
    if (moduleId && roleId && roleId !== NEW_ROLE_ID_STUB) {
      dispatch(actions.requestRolePermissions({ companyId, moduleId, roleId }));
    }
  }, [dispatch, companyId, moduleId, roleId, state.error]);

  useEffect(() => {
    if (!companyId || state.error || !role || !role?.editable) {
      return;
    }
    if (moduleId) {
      dispatch(actions.requestModulePermissions({ companyId, moduleId }));
    }
  }, [dispatch, companyId, moduleId, role, state.error]);

  useInit(() => {
    if (!moduleId || roleId !== NEW_ROLE_ID_STUB) {
      return;
    }
    dispatch(actions.deletePermissions({ moduleId, roleId: NEW_ROLE_ID_STUB }));
  });

  const callbacks = useMemo(() => {
    return {
      addPermission: (id: UUID) => {
        if (companyId && moduleId && roleId) {
          dispatch(
            actions.requestAddRolePermissions({
              ids: [id],
              companyId,
              moduleId,
              roleId,
            })
          );
        } else {
          console.error('[addPermissions] Insufficient number of parameters', {
            companyId,
            moduleId,
            roleId,
          });
        }
        dispatch(actions.resetErrors());
      },
      removePermission: (id: UUID) => {
        if (companyId && moduleId && roleId) {
          dispatch(
            actions.requestRemoveRolePermissions({
              ids: [id],
              companyId,
              moduleId,
              roleId,
            })
          );
        }
        dispatch(actions.resetErrors());
      },
      resetError: () => {
        dispatch(actions.resetErrors());
      },
    };
  }, [dispatch, companyId, moduleId, roleId]);

  return {
    permissions,
    modulePermissions,
    errors: state.errors,
    ...callbacks,
  };
};
