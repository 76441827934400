import { PropsWithChildren, useMemo, useRef } from 'react';
import { styled, SxProps, Theme } from '@mui/material';
import { InfiniteScrollContainer } from '@/components/InfiniteScrollContainer';
import {
  TableContext,
  TableContextType,
} from '@/components/Table/useTableContext.tsx';
import { INHERITED_DATA_TEST_ID } from '@/utils/testId.ts';

interface TableProps {
  onSort?: TableContextType['onSort'];
  className?: string;
  sortState?: TableContextType['sortState'];
  sx?: SxProps<Theme>;
}

export const StyledTable = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
});

export const DSTable = ({
  children,
  className,
  onSort,
  sortState,
  sx,
  ...props
}: PropsWithChildren<TableProps>) => {
  const ref = useRef<TableContextType>({
    onSort,
  });
  ref.current = { onSort };
  const ctx: TableContextType = useMemo(() => {
    return { onSort: ref.current.onSort, sortState };
  }, [sortState]);

  return (
    <TableContext.Provider value={ctx}>
      <StyledTable
        data-test-id={INHERITED_DATA_TEST_ID}
        className={className}
        sx={sx}
        {...props}
      >
        {children}
      </StyledTable>
    </TableContext.Provider>
  );
};

type ISTableProps = Omit<TableProps, 'setScrollRef' | 'scrollbarCss'> & {
  onLoad?: () => void;
  threshold?: number;
  sx?: SxProps<Theme>;
};
export const Table = (props: PropsWithChildren<ISTableProps>) => {
  return (
    <InfiniteScrollContainer onLoad={props.onLoad}>
      <DSTable data-test-id={INHERITED_DATA_TEST_ID} {...props} />
    </InfiniteScrollContainer>
  );
};
