import IconSVGHamburger from 'ui-kit/lib/icons/24/Menu/BurgerMenu';
import IconSVGTimesMenu from 'ui-kit/lib/icons/24/Action/Close';
import { IconButton } from '@/components/IconButton';
import { INHERITED_DATA_TEST_ID } from '@/utils/testId.ts';
export const HamburgerButton = ({
  toggle,
  open,
  ...props
}: {
  toggle: () => void;
  open: boolean;
}) => {
  return (
    <IconButton
      data-test-id={INHERITED_DATA_TEST_ID}
      onClick={toggle}
      size={'lg'}
      color={'quaternary'}
      {...props}
    >
      {open ? <IconSVGTimesMenu /> : <IconSVGHamburger />}
    </IconButton>
  );
};
