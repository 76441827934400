import { AnyAction } from 'redux';
import { Statuses } from '@/api/client/types';
import { AxiosError } from 'axios';
import { phoneEmailsActions as actions } from './slice';

import { ValidationErrorType } from '@/types/ValidationError';
import { RequiredAny } from 'ui-kit/lib/types';

export const skipErrorHandler = (
  _error: RequiredAny,
  action?: RequiredAny
): AnyAction[] => (action ? [action] : []);

export const baseErrorHandler = (
  error: AxiosError<RequiredAny>,
  _action: RequiredAny
): AnyAction[] => {
  switch (error?.response?.status) {
    case Statuses.BAD_REQUEST: {
      if (error?.response?.data?.fieldErrors || error?.response?.data?.error) {
        return [actions.setValidationErrors(error.response.data)];
      }
      break;
    }
    case Statuses.UNAUTHORIZED:
      // redirect to login page will be made by means of global api client interceptor
      return skipErrorHandler(error);
    default: {
      console.error('[baseErrorHandler] Error', error.message, error);
      return [
        actions.setErrors({
          common: { base: ValidationErrorType.UNKNOWN_ERROR },
        }),
      ];
    }
  }
  console.error('[BaseErrorHandler] Error', error);
  return skipErrorHandler(error);
};
