import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createNormalizedState, NormalizedState } from '../common/normalized';
import { UUID } from '@/types';
import { persistReducer } from 'redux-persist';
import { globalStateResetAction } from '../common/actions';
import storage from 'redux-persist/lib/storage';
import { CompanyPermission } from '@/api/__generated__/webApi';
interface PermissionsState {
  permissions: NormalizedState<CompanyPermission> | null;
}
const initialState: PermissionsState = {
  permissions: null,
};

const persistConfig = {
  key: 'permission',
  version: 1,
  storage,
};
export const permissions = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    requestAllPermissions() {},
    requestCompanyPermissions(
      _,
      _action: PayloadAction<{ companyId: UUID }>
    ) {},
    updatePermissions(state, { payload }: PayloadAction<CompanyPermission[]>) {
      if (!state.permissions) {
        state.permissions = createNormalizedState([]);
      }
      for (const companyPermissions of payload) {
        if (!state.permissions.byID[companyPermissions.companyId]) {
          state.permissions.allIDs.push(companyPermissions.companyId);
        }
        state.permissions.byID[companyPermissions.companyId] =
          companyPermissions;
      }
    },
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(globalStateResetAction, () => {
      return initialState;
    });
  },
});
export const permissionsActions = permissions.actions;
export const _permissionsReducer = permissions.reducer;
export const permissionsStateName = permissions.name;
export const permissionsReducer = persistReducer(
  persistConfig,
  _permissionsReducer
);
