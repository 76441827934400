import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { globalStateResetAction } from '@/store/common/actions';
import {
  UUID,
  PartialRecord,
  Phone,
  PhoneType,
  TimestampMs,
  CodeType,
} from '@/types';
import { PayloadActions } from '@/features/profile/phones-and-emails/types';
import { ValidationErrorType } from '@/types/ValidationError';
export enum PopupType {
  ALLOW_DELIVERY = 'allowDelivery',
  DELETE_ITEM = 'deleteItem',
  SET_AS_MAIN = 'setAsMain',
}

export type Popup =
  | {
      type: null;
      id: null;
      allow?: boolean;
      open?: boolean;
    }
  | {
      type: PopupType;
      id: UUID;
      allow?: boolean;
      open?: boolean;
    };
export interface PhonesState {
  phones: {
    byId: Record<UUID, Phone>;
    allIds: UUID[];
    idOfMain: UUID | null;
  };
  npiPhones: string[];
  validationErrors: Record<string, ValidationErrorType> | null;
  errors: PartialRecord<string | 'base' | 'common', ValidationErrorType> | null;
  resendTime: number | null;
  isFetching: number;
  confirmPopup: Popup | null;
  editState: 'edit' | 'pin-code' | null;
  form?: NewPhoneForm | OwnershipForm;
}
export interface NewPhoneForm {
  formType: 'new-phone';
  phone: string;
  type: PhoneType;
  source: 'new' | 'npi';
  validationErrors?: Record<'common' | string, ValidationErrorType> | undefined;
}
export interface OwnershipForm {
  formType: 'confirmOwnership';
  state: 'success' | 'edit' | 'init';
  pinCode?: string[] | null;
  resendTime: TimestampMs | undefined;
  phoneId?: UUID;
  validationErrors?: Record<'common' | string, ValidationErrorType> | undefined;
}

const initialState: PhonesState = {
  phones: {
    byId: {},
    idOfMain: null,
    allIds: [],
  },
  npiPhones: [],
  validationErrors: {},
  errors: null,
  resendTime: null,
  isFetching: 0,
  confirmPopup: null,
  editState: null,
};
export const slice = createSlice({
  name: 'phones',
  initialState,
  reducers: {
    // GET /api/account/phones
    requestGetPhones() {},
    // GET /api/account/phones/npi
    requestGetNPIPhones() {},
    // DELETE /api/account/phones/{id}
    requestDeletePhone(state, _action: PayloadActions['requestDeletePhone']) {
      state.confirmPopup = null;
      state.validationErrors = {};
    },
    // PATCH /api/account/phones/{id}/allow-results-delivery/{value}
    requestSetAllowDelivery(
      state,
      _action: PayloadActions['requestSetAllowDelivery']
    ) {
      state.confirmPopup = null;
      state.validationErrors = {};
    },
    // PATCH /api/account/phones/{id}/make-main
    requestSetPhoneAsMain(
      state,
      _action: PayloadActions['requestSetPhoneAsMain']
    ) {
      state.confirmPopup = null;
      state.validationErrors = {};
    },
    // POST /api/code/send
    requestResendPinCode(
      state,
      _action: PayloadAction<{ codeType: CodeType }>
    ) {
      if (state.form && 'pinCode' in state.form) {
        state.form.pinCode = null;
        state.form.validationErrors = undefined;
      }
    },
    // POST /api/account/phones
    requestSavePhone(state, action: PayloadActions['requestSavePhone']) {
      state.form = { ...action.payload, formType: 'new-phone' };
      state.validationErrors = {};
    },
    // POST /api/account/confirm/mobile-phone
    requestConfirmMobilePhone(
      _,
      _action: PayloadActions['requestConfirmMobilePhone']
    ) {},
    // ------
    requestConfirmPhoneOwnership(
      _,
      _action: PayloadAction<{ phoneId: UUID; pinCode: string }>
    ) {},
    requestPhoneConfirmationPinCode(
      _,
      _action: PayloadAction<{ phoneId: UUID }>
    ) {},
    setForm(
      state,
      {
        payload,
      }: PayloadAction<
        | { form: PhonesState['form'] }
        | { form: Partial<PhonesState['form']>; merge: true }
      >
    ) {
      if ('merge' in payload && payload?.merge) {
        state.form = { ...state.form, ...payload.form } as PhonesState['form'];
      } else {
        state.form = payload.form as NewPhoneForm | OwnershipForm | undefined;
      }
    },
    resetFormError(state) {
      if (state.form?.validationErrors) {
        state.form.validationErrors = undefined;
      }
    },
    setPhones(state, action: PayloadActions['setPhones']) {
      const newPhones = action.payload.phones;

      if (action.payload.replace) {
        state.phones = {
          allIds: [],
          byId: {},
          idOfMain: null,
        };
      }

      for (const phone of newPhones) {
        if ('main' in phone && phone.main) {
          const prevMainId = state.phones.idOfMain;
          if (prevMainId) {
            const prevMain = state.phones.byId[prevMainId];
            if ('main' in prevMain) {
              prevMain.main = null;
            }
          }
          state.phones.idOfMain = phone.id;
        }
        state.phones.byId[phone.id] = phone;
        if (action.payload.replace || !state.phones.allIds.includes(phone.id)) {
          state.phones.allIds.push(phone.id);
        }
      }
    },
    setNPIPhones(state, action: PayloadActions['setNPIPhones']) {
      state.npiPhones = action.payload;
    },
    setResendTime(state, action: PayloadActions['setResendTime']) {
      state.resendTime = action.payload;
    },
    setValidationErrors(state, action: PayloadActions['setValidationErrors']) {
      if (!action.payload) {
        state.validationErrors = null;
      }
      if (action.payload) {
        state.validationErrors = action.payload;
      }
    },
    setErrors(
      state,
      {
        payload,
      }: PayloadAction<{
        common: PartialRecord<'base' | string, ValidationErrorType>;
      }>
    ) {
      state.errors = payload.common;
    },
    setIsFetching(state, action: PayloadActions['setIsFetching']) {
      state.isFetching = action.payload
        ? state.isFetching + 1
        : state.isFetching - 1;
      if (state.isFetching < 0) {
        //  throw new Error('Fetching counter has negative value');
      }
    },
    showConfirmation(state, action: PayloadActions['showConfirmation']) {
      state.confirmPopup = action.payload;
    },
    reset(state) {
      state.errors = null;
      state.isFetching = 0;
      state.validationErrors = {};
      if (state.form?.validationErrors) {
        state.form.validationErrors = undefined;
      }
    },
    setEditState(state, action: PayloadActions['setEditState']) {
      state.editState = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(globalStateResetAction, () => {
      return initialState;
    });
  },
});

export const phoneEmailsActions = slice.actions;
export const phonesReducer = slice.reducer;
export const phonesStateName = slice.name;
