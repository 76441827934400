/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useCompany, useUpdateDetails } from '@/store/companies/hooks';
import { AddModuleButton } from '../module/AddModuleButton';
import { Tab, TabPanel, Tabs } from '@/components/TabPanel';
import { useTranslate } from '@/i18n/useTranslate';
import { CompanyHeader } from '@/features/company/fragments/CompanyHeader';
import { ParamNames, useParams } from '@/routes/appRoutes';
import { Stack } from '@mui/material';
import { Button } from '@/components/Button';
import { useADAuthorize } from '@/store/companies/hooks/useADConnect';
import { useBreakpoint } from 'ui-kit';
import { BackButton } from '@/features/company-details/BackButton';
import { useRouteTabs } from '@/components/TabPanel/useRouteTabs.tsx';
import { CompanyDetailsTabId } from '@/features/company-details/CompanyDetailsTabId';
import { useTabsContent } from '@/features/company-details/useTabsContent';

import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

export const CompanyDetailsPage: FC = () => {
  useUpdateDetails();
  const { md } = useBreakpoint('md');
  const { t, ready } = useTranslate('companies');
  const mobileView = !md;
  const params = useParams();
  const { company } = useCompany(params.companyId);
  const { tabsContent: tabs, allTabs } = useTabsContent({ npi: company?.npi });
  const { activeTab, handleTabChange } = useRouteTabs(
    tabs,
    allTabs[CompanyDetailsTabId.MobileRoot]
  );
  const authorize = useADAuthorize({ companyId: params[ParamNames.CompanyId] });
  const showAuthorizeButton = !!company && company?.locked;
  if (!ready) {
    return null;
  }

  return (
    <>
      <CompanyHeader />
      <Tabs
        value={
          activeTab?.id === CompanyDetailsTabId.MobileRoot
            ? ''
            : activeTab?.path
        }
        onChange={(_, path) => handleTabChange(path)}
        enableMobileView
        variant={'fullWidth'}
        defaultTab={allTabs[CompanyDetailsTabId.CompanyName].path}
      >
        {tabs.map((tab, i) => {
          return (
            <Tab
              data-test-id={testId(
                DATA_TEST_IDS.companyDetailsCompanyDetailsPage +
                  '_tabs-details_tab_' +
                  i
              )}
              label={tab.label}
              value={tab.path}
              key={tab.id}
            />
          );
        })}
      </Tabs>
      <Stack sx={{ flexGrow: 1 }}>
        {mobileView ? (
          <>
            {tabs.map(({ Component, id }) => {
              return (
                <TabPanel value={id} selected={activeTab?.id || ''} key={id}>
                  <BackButton
                    data-test-id={testId(
                      DATA_TEST_IDS.companyDetailsCompanyDetailsPage +
                        '_back-button'
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      handleTabChange(
                        allTabs[CompanyDetailsTabId.MobileRoot].path
                      );
                    }}
                  />
                  <Component />
                </TabPanel>
              );
            })}
          </>
        ) : (
          <>
            {tabs.map(({ Component, id }) => {
              return (
                <TabPanel value={id} selected={activeTab?.id || ''} key={id}>
                  <Component />
                </TabPanel>
              );
            })}
          </>
        )}
      </Stack>
      {mobileView && activeTab?.id === CompanyDetailsTabId.MobileRoot && (
        <>
          <Stack gap={12}>
            <AddModuleButton variant={'contained'} />
            {showAuthorizeButton && (
              <Button
                data-test-id={testId(
                  DATA_TEST_IDS.companyDetailsCompanyDetailsPage +
                    '_button-auth'
                )}
                color={'secondary'}
                onClick={() => authorize()}
              >
                {t('AUTHORIZE')}
              </Button>
            )}
          </Stack>
        </>
      )}
    </>
  );
};
