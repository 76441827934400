import { useEffect, useRef, useState } from 'react';

interface InfiniteScrollHookParams {
  onLoad?: () => void;
  threshold: number;
  hasMore?: boolean;
}

const useScrollDown = (
  container: HTMLElement | null,
  cb: (el: HTMLElement) => void
) => {
  const cbRef = useRef(cb);
  cbRef.current = cb;
  useEffect(() => {
    let scrollTopPrev = container?.scrollTop ?? 0;
    let tmt: number;
    const scrollHandler = (e: Event) => {
      window.clearTimeout(tmt);
      tmt = window.setTimeout(() => {
        const element: HTMLElement = e.target as HTMLElement;
        const scrollY = scrollTopPrev !== element.scrollTop;
        if (scrollY) {
          const scrollingDown = scrollTopPrev - element.scrollTop < 0;
          if (scrollingDown) {
            cbRef.current?.(element);
          }
          scrollTopPrev = element.scrollTop;
        }
      }, 100);
    };
    container?.addEventListener('scroll', scrollHandler, { passive: true });
    return () => {
      window.clearTimeout(tmt);
      container?.removeEventListener('scroll', scrollHandler);
    };
  }, [container]);
};
export const useInfiniteScroll = ({
  onLoad,
  threshold,
}: InfiniteScrollHookParams) => {
  const ref = useRef(false);
  const [container, setContainer] = useState<HTMLElement | null>(null);

  const validateMainCondition = (container: HTMLElement, threshold: number) => {
    const scrollSpaceLeftOnTheBottom =
      container.scrollHeight - container.scrollTop - container.offsetHeight;
    return scrollSpaceLeftOnTheBottom - threshold <= 0;
  };

  useScrollDown(container, (el) => {
    const mainCondition = validateMainCondition(el, threshold);
    if (mainCondition) {
      onLoad?.();
    }
    ref.current = mainCondition;
  });

  return {
    setContainer,
  };
};
