import { MutableRefObject, FC, Fragment, useState } from 'react';
import { ProfileMenuItem } from '../ProfileMenu';
import { UlContainer, MenuLink, MenuIcon, ListItem } from './styles';
import { Button, Nowrap, Text } from '@/components';
import IconSVGUSFlag from '../../us_flag.svg?react';
import IconSVGArrowDown from 'ui-kit/lib/icons/24/Arrow/ChevronDown';
import { useTranslate } from '@/i18n/useTranslate';
import { Box, Collapse, Stack, Theme, Typography } from '@mui/material';
import { UUID } from '@/types';
import { useBreakpoints, Tooltip } from 'ui-kit';
import { To } from 'react-router-dom';

import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

const styles = {
  langButton: {
    paddingX: '16px',
    marginX: -16,
    minWidth: 'initial',
    '.MuiButton-startIcon': { width: 'auto', mr: 12 },
  },
  listItem: (t: Theme) => ({
    minHeight: 48,
    paddingX: 16,
    paddingY: 0,
    borderBottom: `1px solid ${t.colors.borderLight}`,
  }),
  expandableItemContainer: (t: Theme) => ({
    backgroundColor: t.palette.grey[100],
  }),
  menuItem: { alignItems: 'center', justifyContent: 'center' },
  labelContainer: { flex: '1 1 auto' },
  iconItemExpanded: {
    transform: 'rotate(180deg)',
  },
};

const handleToParameter = (to: To | undefined) => {
  const pathname = typeof to === 'string' ? to : (to?.pathname ?? '');
  const search =
    typeof to === 'string' ? window.location.search : (to?.search ?? '');
  const toParam: To = to ? pathname + search : '';
  return toParam;
};

interface DropdownMenuProps {
  open: boolean;
  menu: ProfileMenuItem[];
  onClose: () => void;
  parentRef: MutableRefObject<HTMLElement | null>;
  containerRef: MutableRefObject<HTMLUListElement | null>;
}

export const MenuList: FC<DropdownMenuProps> = ({
  open,
  menu,
  onClose,
  parentRef,
  containerRef,
}) => {
  const rect = parentRef.current?.getBoundingClientRect();
  const { t } = useTranslate('common');
  const b = useBreakpoints();
  const coords = {
    top: (rect?.bottom ?? 0) + 10,
    right: b.xsOnly
      ? 0
      : window.document.body.offsetWidth - (rect?.right ?? 0) - 20,
  };
  const { xsOnly: mobile } = useBreakpoints();
  const [expandedState, setExpanded] = useState<Record<UUID, boolean>>({});
  if (!open) {
    return null;
  }
  return (
    <UlContainer
      style={coords}
      onClick={(e) => {
        e.stopPropagation();
      }}
      ref={containerRef}
    >
      <ListItem
        data-test-id={testId(DATA_TEST_IDS.menuList + '_list-item-lang')}
        sx={styles.listItem}
      >
        <Button
          data-test-id={testId(DATA_TEST_IDS.menuList + '_button-english')}
          startIcon={<IconSVGUSFlag />}
          color={'inherit'}
          variant={'text'}
          cancelIconFilling
          sx={styles.langButton}
        >
          {t('ENGLISH')}
        </Button>
      </ListItem>
      {menu.map((item, i) => {
        const { to, onClick, label, icon, title, items, href } = item;
        const clickHandler: React.MouseEventHandler = (e) => {
          e.preventDefault();
          if (items?.length) {
            setExpanded((s) => {
              return {
                ...s,
                [item.id]: !s[item.id],
              };
            });
          } else {
            onClick?.();
            window.setTimeout(() => {
              onClose();
            }, 300);
          }
        };
        return (
          <Fragment key={i}>
            <ListItem
              data-test-id={testId(
                DATA_TEST_IDS.menuList + '_list-item-menu' + i
              )}
              key={i}
            >
              <MenuLink
                data-test-id={testId(DATA_TEST_IDS.menuList + '_menu-link' + i)}
                href={href}
                to={handleToParameter(to)}
                onClick={to || href ? () => {} : clickHandler}
                onAuxClick={to || href ? () => {} : clickHandler}
                withIcon={!!icon}
                sx={items?.length ? styles.expandableItemContainer : undefined}
              >
                {items?.length ? (
                  <Stack direction={'row'} sx={styles.menuItem} gap={6}>
                    <Tooltip
                      data-test-id={testId(DATA_TEST_IDS.menuList + '_tooltip')}
                      title={label}
                      placement={mobile ? 'top' : 'right'}
                    >
                      <Nowrap sx={styles.labelContainer}>
                        <Typography variant={'14_20_700'} display={'inline'}>
                          {label}
                        </Typography>
                      </Nowrap>
                    </Tooltip>

                    <Box
                      component={IconSVGArrowDown}
                      size={18}
                      color={(t) => t.palette.ddrow.iconright.default}
                      sx={[expandedState[item.id] && styles.iconItemExpanded]}
                    />
                  </Stack>
                ) : (
                  <Typography variant={'14_20_400'} pb={2}>
                    {label}
                  </Typography>
                )}
                {title && (
                  <Typography variant={'12_18_500'}>{title}</Typography>
                )}
                {icon && <MenuIcon>{icon}</MenuIcon>}
              </MenuLink>
            </ListItem>
            <Collapse in={expandedState[item.id]}>
              {items?.map(({ to, href, title, label, onClick }, i) => {
                const onCloseCb = () => {
                  window.setTimeout(() => {
                    onClose();
                  }, 300);
                };
                return (
                  <ListItem
                    data-test-id={testId(
                      DATA_TEST_IDS.menuList + '_list-item' + i
                    )}
                    key={i}
                  >
                    <MenuLink
                      data-test-id={testId(
                        DATA_TEST_IDS.menuList + '_menu-link' + i
                      )}
                      sx={{ paddingX: 36 }}
                      href={href}
                      to={handleToParameter(to)}
                      onClick={
                        to || href
                          ? onCloseCb
                          : (e) => {
                              e.preventDefault();
                              onClick?.();
                              onCloseCb();
                            }
                      }
                    >
                      <Text weight={title ? 700 : 400} fz={14}>
                        {label}
                      </Text>
                    </MenuLink>
                  </ListItem>
                );
              })}
            </Collapse>
          </Fragment>
        );
      })}
    </UlContainer>
  );
};
