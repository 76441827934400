import { Stack } from '@mui/material';
import { Grid } from '@/components';
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { useIndividualNPI } from '@/store/npi-individual/hooks';
import { useOrgNPI } from '@/store/npi-org/hooks';
import { NPIDataConfirmationOrg } from './NPISearchForms/NPIOrg/NPIDataViewOrg';
import { useBackUrl } from './useBackUrl';
import { NPIFormContainer, NPIFormContainerOrg } from './NPISearchForms';
import { Warning } from '@/components/Dialog';
import { Addresses } from '@/features/profile-address';
import { Documents } from '@/features/profile/Documents';
import {
  NPIDataIndividualInfo,
  NPIDataConfirmationIndividual,
} from '@/features/profile/NPISearchForms/NPIIndividual/NPIDataViewIndividual';
import { useBreakpoints } from 'ui-kit';
import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

export const MainProfilePage: FC = () => {
  useBackUrl();
  const {
    showRequestForm: showNPIRequestFormForIndividual,
    resetRequestSuccess,
    requestSent,
  } = useIndividualNPI();
  const {
    resetRequestSuccess: resetRequestSuccessOrg,
    requestSent: requestSentOrg,
  } = useOrgNPI();
  const { t } = useTranslate('profile');
  const { xl, xsOnly, smOnly, mdOnly } = useBreakpoints();
  const twoColumnsForNpiForms = showNPIRequestFormForIndividual;
  return (
    <>
      <Grid container columnSpacing={{ xl: 36 }} height={'100%'}>
        <Grid xs>
          <Stack gap={{ xs: 24, lg: 36 }} height={{ lg: '100%' }}>
            <Outlet />
            <NPIDataConfirmationIndividual />
            <NPIDataConfirmationOrg />
            <Addresses />
            <Documents />
            <NPIDataIndividualInfo />
            {!xl && (
              <Grid container spacing={{ xs: 24, lg: 36 }}>
                {showNPIRequestFormForIndividual && (
                  <Grid xs={12} sm>
                    <NPIFormContainer
                      narrowView={
                        xsOnly || ((smOnly || mdOnly) && twoColumnsForNpiForms)
                      }
                    />
                  </Grid>
                )}
                <Grid xs={12} sm>
                  <NPIFormContainerOrg
                    narrowView={
                      xsOnly || ((smOnly || mdOnly) && twoColumnsForNpiForms)
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Stack>
        </Grid>
        {xl && (
          <Grid xl={4.4} xxl={3.4}>
            <Stack gap={{ xs: 24, lg: 36 }}>
              {showNPIRequestFormForIndividual && (
                <NPIFormContainer narrowView={false} />
              )}
              <NPIFormContainerOrg narrowView={false} />
            </Stack>
          </Grid>
        )}
      </Grid>
      <Warning
        data-test-id={testId(DATA_TEST_IDS.profileMainProfilePage + '_warning')}
        type={'success'}
        title={t('SUCCESS')}
        open={requestSent || requestSentOrg}
        onClose={() => {
          resetRequestSuccess();
          resetRequestSuccessOrg();
        }}
      >
        {t('NPI_REGISTRATION_REQUEST_SUCCESSFUL')}
      </Warning>
    </>
  );
};

export default MainProfilePage;
