import { ISODateString } from '@/types';
import { DateTimeFormat, DEFAULT_DATE_FORMAT } from './constants.ts';
import { DateTime } from 'luxon';

export const formatDate = (
  date: ISODateString | null | undefined,
  format: DateTimeFormat = DEFAULT_DATE_FORMAT
) => {
  if (!date) {
    return date;
  }
  const luxonDate = DateTime.fromISO(date);
  if (luxonDate.isValid) {
    return luxonDate.toFormat(format);
  }
  return date;
};
