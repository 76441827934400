import { Typography, TypographyProps, TypographyTypeMap } from '@mui/material';
import { FC } from 'react';

import { spreadSx } from '@/utils/spreadSx';
import { Trans } from 'react-i18next';
import { testId } from '@/utils/testId.ts';

const styles = {
  text: {
    display: 'inline-block',
    fontWeight: 500,
    lineHeight: '16px',
    fontSize: { xs: '10px' },
    color: 'grey.500',
    a: {
      color: 'secondary.main',
      textDecoration: 'none',
      '&:hover': {
        color: 'secondary.light',
      },
      '&:active': {
        color: 'secondary.dark',
      },
    },
  },
};

export const RecaptchaNote: FC<
  TypographyTypeMap['props'] & TypographyProps
> = ({ className, sx, ...restProps }) => {
  return (
    <Trans>
      <Typography
        sx={[styles.text, ...spreadSx(sx)]}
        className={className}
        component={'span'}
        {...restProps}
      >
        THIS_SITE_IS_PROTECTED_BY_RECAPTCHA_AND_THE_GOOGLE
        <a
          href="https://policies.google.com/privacy"
          data-test-id={testId('common__google-recaptcha_link-privacy-policy')}
        >
          PRIVACY_POLICY
        </a>
        AND
        <a
          href="https://policies.google.com/terms"
          data-test-id={testId('common__google-recaptcha_link-terms-of-use')}
        >
          TERMS_OF_SERVICE
        </a>
        APPLY
      </Typography>
    </Trans>
  );
};
