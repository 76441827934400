import { useCompaniesState } from './useCompaniesState';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { companiesActions as actions, FormNames } from '../slice';
import { useParams } from '@/routes/appRoutes';
import { EditAddressForm } from '@/features/company-addresses/EditAddressDialog';
import { useTranslatedErrors } from '@/hooks/useConnectServerValidationErrors';
import { ValidationErrorType } from '@/types/ValidationError.ts';
export const useEditAddressForm = (addressId?: string) => {
  const { forms } = useCompaniesState();
  const params = useParams();
  const theForm = forms[FormNames.EditAddress];
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      resetErrors() {
        dispatch(actions.resetErrors());
        dispatch(actions.resetFormErrors({ formName: FormNames.EditAddress }));
      },
      openDialog() {
        if (theForm?.state === 'done') {
          dispatch(actions.resetForm({ formName: FormNames.EditAddress }));
        }
        dispatch(
          actions.setFormState({
            formName: FormNames.EditAddress,
            formState: 'active',
          })
        );
      },
      closeDialog() {
        dispatch(
          actions.setFormState({
            formName: FormNames.EditAddress,
            formState: null,
          })
        );
        dispatch(actions.resetFormErrors({ formName: FormNames.EditAddress }));
      },
      submit(form: EditAddressForm) {
        if (!params.companyId || !addressId) {
          return;
        }
        dispatch(
          actions.requestEditAddress({
            companyId: params.companyId,
            addressId,
            partialAddress: {
              address2: { value: form.address2 },
              endDate: form.endDate,
              faxNumber: form.faxNumber,
              telephoneNumber: form.telephoneNumber,
              invoicesReceiving: form.invoicesReceiving ?? null,
              insuranceBillType: { value: form.insuranceBillType },
              clientBillType: { value: form.clientBillType },
              patientBillType: { value: form.patientBillType },
            },
          })
        );
      },
    };
  }, [addressId, dispatch, params.companyId, theForm?.state]);

  const serverTranslatedErrors = useTranslatedErrors<
    Record<string, ValidationErrorType>
  >(theForm?.errors);
  return {
    dialogOpened: theForm?.state && theForm.state !== 'done',
    fetching: theForm?.state === 'sending',
    errors: serverTranslatedErrors,
    ...callbacks,
  };
};
