import React, { useCallback, useEffect, useRef, useState } from 'react';

export const useTabs = (
  tab: number | string,
  cb?: (tab: number | string) => void
) => {
  const cbRef = useRef(cb);
  cbRef.current = cb;
  const [selectedTabValue, setSelectedTabValue] = useState(tab);
  const onTabChange = useCallback(
    (e: React.SyntheticEvent | null, v: number | string) => {
      cbRef.current?.(v);
      setSelectedTabValue(v);
    },
    []
  );
  useEffect(() => {
    setSelectedTabValue(tab);
  }, [tab]);
  return { index: selectedTabValue, handleChange: onTabChange };
};
