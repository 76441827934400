import { useTranslate } from '@/i18n/useTranslate';
import { useDelete } from '@/features/profile-address/store/hooks';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@/components';
import { Typography } from '@mui/material';
import { Button } from 'ui-kit';
import React from 'react';
import { styles } from './styles';

import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

export const DeleteDialog = () => {
  const { t } = useTranslate('profile');
  const { deleteAddress, isOpen, dismiss } = useDelete();
  return (
    <Dialog
      open={isOpen}
      onClose={dismiss}
      data-test-id={testId(DATA_TEST_IDS.deleteDialog + '_dialog')}
    >
      <DialogTitle
        data-test-id={testId(DATA_TEST_IDS.deleteDialog + '_dialog-title')}
        onClose={dismiss}
      >
        {t('DELETE')}
      </DialogTitle>
      <DialogContent>
        <Typography sx={styles.text}>
          {t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ADDRESS')}
        </Typography>
      </DialogContent>
      <DialogActions spaceBetween noStack noBorder>
        <Button
          data-test-id={testId(DATA_TEST_IDS.deleteDialog + '_button-no')}
          color={'secondary'}
          variant={'outlined'}
          onClick={dismiss}
        >
          {t('NO')}
        </Button>
        <Button
          data-test-id={testId(DATA_TEST_IDS.deleteDialog + '_button-yes')}
          color={'secondary'}
          onClick={() => {
            void deleteAddress();
          }}
        >
          {t('YES')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
