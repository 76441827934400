/** @jsxImportSource @emotion/react */
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { PinCell } from './styles';
import { Box, styled } from '@mui/material';
import classNames from 'classnames';
import { inheritDataTestId, INHERITED_DATA_TEST_ID } from '@/utils/testId.ts';

const CELL_WIDTH = 32;
const CellContainer = styled(Box)(() => ({
  width: CELL_WIDTH,
  minWidth: `${CELL_WIDTH}px`,
  position: 'relative',
  flexShrink: 0,
  height: '100%',
}));
interface PinInputProps {
  value: string;
  index: number;
  onChange?: (index: number, val: string) => void;
  onPaste?: (str: string, index: number) => void;
  onSelectNext?: (index: number) => void;
  onSelectPrev?: (index: number, isBackspace?: boolean) => void;
  id?: string | undefined;
  fullWidth?: boolean | null;
  error?: string | boolean | null;
  selected?: boolean | null;
  success?: boolean;
}

export const NumberPinCell = forwardRef<HTMLInputElement, PinInputProps>(
  (
    {
      value: externalValue,
      onChange,
      id,
      index,
      onSelectPrev,
      onSelectNext,
      error,
      onPaste,
      selected,
      success,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState(externalValue || '');
    const internalRef = useRef<HTMLInputElement | null>(null);
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      if (e.target.value.length > 2) {
        onPaste?.(e.target.value, index);
        return;
      }

      const originalValue = e.target.value;
      const caretPosition = e.target.selectionStart;

      const symbol =
        caretPosition === 1
          ? originalValue.slice(0, 1)
          : originalValue.slice(-1);

      if (
        (symbol && isNaN(Number(originalValue))) ||
        !originalValue.trim().length
      ) {
        return;
      }
      onChange?.(index, symbol);
      setValue(symbol);
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (e.key === 'ArrowLeft') {
        onSelectPrev?.(index);
      }
      if (e.key === 'ArrowRight') {
        onSelectNext?.(index);
      }
      if (e.key === 'Backspace') {
        onSelectPrev?.(index);
        onChange?.(index, '');
      }
    };
    useEffect(() => {
      setValue((val) => {
        if (val !== externalValue && externalValue?.length <= 1) {
          return externalValue;
        }
        return val;
      });
    }, [externalValue]);

    return (
      <CellContainer
        onClick={() => {
          internalRef.current?.focus();
        }}
        data-test-id={inheritDataTestId(props, 'cell-container')}
      >
        <PinCell
          data-test-id={INHERITED_DATA_TEST_ID}
          className={classNames({ selected, invalid: !!error, success })}
          autoComplete={'new-password'}
          aria-autocomplete={'none'}
          ref={(e) => {
            internalRef.current = e;
            if (ref instanceof Function) {
              ref(e);
            } else if (ref) {
              ref.current = e;
            }
          }}
          id={id}
          name={`${String(index)}${Math.random()}`}
          type={'tel'}
          inputMode={'decimal'}
          onFocus={(e) => {
            e.target.setSelectionRange(1, 1);
          }}
          value={externalValue ?? value}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          {...props}
        />
      </CellContainer>
    );
  }
);
NumberPinCell.displayName = 'NumberPinCell';
