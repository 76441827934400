import { PartialRecord } from '@/types';

export type KnownSearchParams = PartialRecord<SearchParamNames, string> & {[SearchParamNames.QueuedTask]?: QueuedTaskType}
export const toSearchParams = (searchParamsObj:KnownSearchParams ) => {
  return new URLSearchParams(searchParamsObj).toString();
};


export enum SearchParamNames {
  CompanyId= 'companyId',
  ModuleType = 'moduleType',
  QueuedTask = 'queuedTask',
  IsRedirectBackAfterAuth = 'isRedirectBackAfterAuth'
}
export enum QueuedTaskType {
  ModuleRedirect = 'moduleRedirect',
}
