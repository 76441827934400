/** @jsxImportSource @emotion/react */
import { FC, MutableRefObject, useLayoutEffect, useRef, useState } from 'react';
import { Box, css as _, styled } from '@mui/material';
import { CornerHandle, EdgeHandle, ToolsHandle } from './Handlers';
import SVGMask from '@/images/mask.svg?react';

const CROP_MIN_SIZE = 120;

interface DimensionsState {
  size: number;
  x: number;
  y: number;
  rotate: number;
}
interface CropProps {
  round: boolean;
  rotate?: (params: {
    angle: number;
    transformOrigin: { x: number; y: number };
  }) => void;
  pan?: (params: {
    x: number;
    y: number;
    type: 'start' | 'stop' | 'move';
  }) => void;
  resetRef: MutableRefObject<() => void>;
}
export const CropRotateHandler: FC<CropProps> = ({
  round,
  rotate,
  pan,
  resetRef,
}) => {
  const canvasRef = useRef<HTMLElement | undefined>();
  const maskRef = useRef<SVGSVGElement>(null);
  const [dimensions, setDimensions] = useState<DimensionsState>({
    size: 0,
    x: 0,
    y: 0,
    rotate: 0,
  });

  const setInitial = () => {
    if (!canvasRef.current) {
      return;
    }
    const canvasHeight = canvasRef.current?.offsetHeight;
    const canvasWidth = canvasRef.current?.offsetWidth;

    const landscape = canvasWidth > canvasHeight;
    const margin = 0;
    const maskSize = (landscape ? canvasHeight : canvasWidth) - margin;
    const x = landscape ? canvasWidth / 2 - maskSize / 2 : margin / 2;
    const y = landscape ? margin / 2 : canvasHeight / 2 - maskSize / 2;

    setDimensions(() => {
      return {
        rotate: 0,
        size: maskSize,
        h: maskSize,
        y,
        x,
      };
    });
  };
  resetRef.current = setInitial;

  useLayoutEffect(() => {
    setInitial();
  }, []);
  const startDimensionsRef = useRef<DimensionsState>({ ...dimensions });
  const eventsHandler: ResizeHandlerFn = ({ coords: { x, y }, type }) => {
    const canvasEl = canvasRef.current;
    if (canvasEl) {
      const boundingBox = canvasEl.getBoundingClientRect();
      switch (type) {
        case 'move':
          setDimensions((d) => {
            const newX = startDimensionsRef.current.x - x;
            const newY = startDimensionsRef.current.y - y;
            const xConstrained = Math.min(
              Math.max(newX, 0),
              boundingBox.width - d.size
            );
            const yConstrained = Math.min(
              Math.max(newY, 0),
              boundingBox.height - d.size
            );
            return {
              ...d,
              x: xConstrained,
              y: yConstrained,
            };
          });
          break;
        case 'resize-top-left': {
          x = x + startDimensionsRef.current.size;
          y = y + startDimensionsRef.current.size;
          const size = Math.max(CROP_MIN_SIZE, (x - y) / 2 + y);
          setDimensions((d) => {
            const sizeMax = Math.min(size, d.size + d.x, d.size + d.y);
            return {
              ...d,
              y: d.y + (d.size - sizeMax),
              x: d.x + (d.size - sizeMax),
              size: sizeMax,
            };
          });
          break;
        }
        case 'resize-top-right': {
          x = -x + startDimensionsRef.current.size;
          y = y + startDimensionsRef.current.size;
          const size = Math.max(CROP_MIN_SIZE, (x - y) / 2 + y);
          setDimensions((d) => {
            const sizeMax = Math.min(
              size,
              boundingBox.width - d.x,
              d.size + d.y
            );
            return {
              ...d,
              y: d.y + (d.size - sizeMax),
              size: sizeMax,
            };
          });
          break;
        }
        case 'resize-bottom-right': {
          x = -x + startDimensionsRef.current.size;
          y = -y + startDimensionsRef.current.size;
          const size = Math.max(CROP_MIN_SIZE, (x - y) / 2 + y);
          setDimensions((d) => {
            const sizeMax = Math.min(
              size,
              boundingBox.width - d.x,
              boundingBox.height - d.y
            );
            return {
              ...d,
              size: sizeMax,
            };
          });
          break;
        }
        case 'resize-bottom-left': {
          x = x + startDimensionsRef.current.size;
          y = -y + startDimensionsRef.current.size;
          const size = Math.max(CROP_MIN_SIZE, (x - y) / 2 + y);
          setDimensions((d) => {
            const sizeMax = Math.min(
              size,
              d.size + d.x,
              boundingBox.height - d.y
            );
            return {
              ...d,
              x: d.x + (d.size - sizeMax),
              size: sizeMax,
            };
          });
          break;
        }
        case 'rotate': {
          const angle = y / 2;
          const all = angle + startDimensionsRef.current.rotate;
          const transformOrigin = {
            x:
              startDimensionsRef.current.x +
              startDimensionsRef.current.size / 2,
            y:
              startDimensionsRef.current.y +
              startDimensionsRef.current.size / 2,
          };
          setDimensions((d) => {
            return {
              ...d,
              rotate: all,
            };
          });
          rotate?.({ angle: all, transformOrigin });
          break;
        }
        case 'pan': {
          pan?.({
            x: -x,
            y: -y,
            type: 'move',
          });
          break;
        }
      }
    }
  };

  const changeStartHandler = (type: MovementType) => {
    switch (type) {
      case 'pan': {
        pan?.({ x: 0, y: 0, type: 'start' });
        break;
      }
    }
    startDimensionsRef.current = { ...dimensions };
  };
  const changeEndHandler = (type: MovementType) => {
    switch (type) {
      case 'pan': {
        pan?.({ x: 0, y: 0, type: 'stop' });
        break;
      }
    }
  };

  const blinders = (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: '100%',
          left: '50%',
        }}
      >
        <Blinder ml={'-50%'} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: '100%',
          left: '50%',
        }}
      >
        <Blinder ml={'-50%'} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          left: '100%',
          top: '50%',
        }}
      >
        <Blinder mt={'-50%'} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          right: '100%',
          top: '50%',
        }}
      >
        <Blinder mt={'-50%'} />
      </Box>
    </>
  );

  return (
    <MaskedCanvas ref={canvasRef}>
      <Box
        id={'crop_area'}
        style={{
          transform: `rotate(${0}deg)`,
          width: dimensions.size,
          height: dimensions.size,
          top: dimensions.y,
          left: dimensions.x,
          opacity: 0.95,
        }}
        ref={maskRef}
        position={'absolute'}
      >
        {round && <SVGMaskStyled />}
        {blinders}
        <TransformHandler
          onChange={eventsHandler}
          onChangeStart={changeStartHandler}
          onChangeEnd={changeEndHandler}
        />
      </Box>
    </MaskedCanvas>
  );
};
const MaskedCanvas = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
type MovementType =
  | 'move'
  | 'rotate'
  | 'pan'
  | 'resize-top'
  | 'resize-top-right'
  | 'resize-right'
  | 'resize-bottom-right'
  | 'resize-bottom'
  | 'resize-bottom-left'
  | 'resize-left'
  | 'resize-top-left';
type ResizeHandlerFn = (e: {
  coords: { x: number; y: number };
  type: MovementType;
}) => void;

interface ResizeHandlerProps {
  onChange: ResizeHandlerFn;
  onChangeStart: (t: MovementType) => void;
  onChangeEnd?: (t: MovementType) => void;
}

export const TransformHandler: FC<ResizeHandlerProps> = ({
  onChange,
  onChangeStart,
  onChangeEnd,
}) => {
  const ref = useRef<HTMLElement>(null);
  const onMouseDown = (
    e: React.MouseEvent | React.TouchEvent,
    t: MovementType
  ) => {
    let tmt: number;
    const initialX = 'pageX' in e ? e.pageX : e.touches[0].pageX;
    const initialY = 'pageY' in e ? e.pageY : e.touches[0].pageY;
    onChangeStart(t);
    const moveHandler = (e: MouseEvent | TouchEvent) => {
      const pageX = 'pageX' in e ? e.pageX : e.touches[0].pageX;
      const pageY = 'pageY' in e ? e.pageY : e.touches[0].pageY;
      window.cancelAnimationFrame(tmt);
      tmt = window.requestAnimationFrame(() => {
        onChange({
          coords: {
            x: initialX - pageX,
            y: initialY - pageY,
          },
          type: t,
        });
      });
    };
    const mouseUpHandler = () => {
      window.removeEventListener('mousemove', moveHandler);
      window.removeEventListener('mouseup', mouseUpHandler);

      window.removeEventListener('touchmove', moveHandler);
      window.removeEventListener('touchend', mouseUpHandler);
      onChangeEnd?.(t);
    };
    window.addEventListener('mousemove', moveHandler);
    window.addEventListener('mouseup', mouseUpHandler);

    window.addEventListener('touchmove', moveHandler);
    window.addEventListener('touchend', mouseUpHandler);
  };

  const mouseDownHandler =
    (m?: MovementType) => (e: React.TouchEvent | React.MouseEvent) => {
      let mtFromId: MovementType | undefined;
      //
      if (!m) {
        mtFromId = e.currentTarget.id as MovementType;
      }
      if (!m && !mtFromId) {
        console.error('Movement type is undefined');
        return;
      }
      e.stopPropagation();
      e.preventDefault();
      onMouseDown(e, m ?? (mtFromId as MovementType));
    };

  return (
    <Box
      ref={ref}
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        boxSizing: 'border-box',
        border: '1px solid white',
        cursor: 'grab',
        '&:active': {
          cursor: 'grabbing',
        },
      }}
      onMouseDown={mouseDownHandler('move')}
      onTouchStart={mouseDownHandler('move')}
    >
      <EdgeHandle
        height={'100%'}
        left={-4}
        sx={{ cursor: 'ew-resize' }}
        onMouseDown={mouseDownHandler('resize-left')}
        onTouchStart={mouseDownHandler('resize-left')}
      />
      <EdgeHandle
        height={'100%'}
        right={-4}
        sx={{ cursor: 'ew-resize' }}
        onMouseDown={mouseDownHandler('resize-right')}
        onTouchStart={mouseDownHandler('resize-right')}
      />
      <EdgeHandle
        width={'100%'}
        height={8}
        top={-4}
        sx={{ cursor: 'ns-resize' }}
        onMouseDown={mouseDownHandler('resize-top')}
        onTouchStart={mouseDownHandler('resize-top')}
      />
      <EdgeHandle
        width={'100%'}
        height={8}
        bottom={-4}
        sx={{ cursor: 'ns-resize' }}
        onMouseDown={mouseDownHandler('resize-bottom')}
        onTouchStart={mouseDownHandler('resize-bottom')}
      />
      <CornerHandle
        css={_`cursor: nwse-resize`}
        onMouseDown={mouseDownHandler('resize-top-left')}
        onTouchStart={mouseDownHandler('resize-top-left')}
      />
      <CornerHandle
        rotate={90}
        css={_`right: 0; cursor: nesw-resize`}
        onMouseDown={mouseDownHandler('resize-top-right')}
        onTouchStart={mouseDownHandler('resize-top-right')}
      />
      <CornerHandle
        rotate={180}
        css={_`right: 0; bottom: 0; cursor: nwse-resize`}
        onMouseDown={mouseDownHandler('resize-bottom-right')}
        onTouchStart={mouseDownHandler('resize-bottom-right')}
      />
      <CornerHandle
        rotate={270}
        css={_`bottom: 0; cursor: nesw-resize`}
        onMouseDown={mouseDownHandler('resize-bottom-left')}
        onTouchStart={mouseDownHandler('resize-bottom-left')}
      />
      <ToolsHandle
        onMouseDown={mouseDownHandler()}
        onTouchStart={mouseDownHandler()}
      />
    </Box>
  );
};
export const SVGMaskStyled = styled(SVGMask)(
  (_d) => _`
  position: absolute;
  cursor: grab;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`
);
export const Blinder = styled(Box)(
  ({ theme: t }) => _`
  width: 1000px;
  height: 1000px;
  background: ${t.palette.grey[700]};
`
);
