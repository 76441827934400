/** @jsxImportSource @emotion/react */
import AppLogo from '@/images/logo.svg?react';
import { ButtonBase, styled, Theme } from '@mui/material';
import { HEADER_HEIGHTS } from './fragments/HeaderStripe/styles';

export const NotifyBtn = styled(ButtonBase)`
  background: transparent;
  border: none;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  div {
    position: relative;
  }
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.secondary5};
    svg path {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.secondary10};
  }
  div div:last-child {
    --size: 16px;
    position: absolute;
    min-width: var(--size);
    height: var(--size);
    border-radius: 8px;
    padding: 0 4px;
    background-color: ${({ theme }) => theme.colors.all.error[200]};
    top: -1px;
    left: 10px;
    color: white;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
`;
NotifyBtn.defaultProps = {
  type: 'button',
};
export const StyledAppLogo = styled(AppLogo)`
  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  width: 100px;
`;
export const PageBody = styled('div')`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const GridContainer = styled('div')`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  position: relative;
`;
export const SIDE_MENU_WIDTH = 340; // 64
export const SIDE_MENU_WIDTH_COLLAPSED = 64;
export const MENU_COLLAPSED_CLASS = 'menu-collapsed';

export const Content = styled('div')`
  margin-left: ${SIDE_MENU_WIDTH}px;
  transition: all 0.3s;
  flex: 1 1 auto;
  padding: 36px;
  background-color: ${({ theme }) => theme.colors.background2};
  display: flex;
  flex-direction: column;
  gap: 36px;
  box-sizing: border-box;
  min-width: 0;
  overflow: hidden;
  overflow-y: visible;
  .${MENU_COLLAPSED_CLASS} & {
    margin-left: ${SIDE_MENU_WIDTH_COLLAPSED}px;
  }
  .mobile-layout & {
    margin-left: 0;
    padding: 16px;
    gap: 16px;
    width: 100vw;
  }
  .tablet-layout & {
    margin-left: 0;
    padding: 36px 24px;
    gap: 24px;
    width: 100vw;
  }
`;

export const FloatMenu = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

// eslint-disable-next-line react-refresh/only-export-components
export const styles = {
  main: {
    paddingTop: {
      xs: HEADER_HEIGHTS.xs,
      sm: HEADER_HEIGHTS.tablet,
      lg: HEADER_HEIGHTS.desktop,
    },
    minHeight: 0,
    flex: '1 1 auto',
    display: 'flex',
  },
  gridNavPanel: (t: Theme) => ({
    width: SIDE_MENU_WIDTH,
    flex: '1 1 auto',
    height: {
      xs: `calc(100% - ${HEADER_HEIGHTS.xs - 1}px)`,
      sm: `calc(100% - ${HEADER_HEIGHTS.tablet - 1}px)`,
      lg: `calc(100% - ${HEADER_HEIGHTS.desktop - 1}px)`,
    },
    backgroundColor: (t: Theme) => t.colors.background,
    borderRight: (t: Theme) => t.colors.borderLight,
    position: 'fixed',
    zIndex: 100,
    [`.${MENU_COLLAPSED_CLASS} &`]: {
      width: SIDE_MENU_WIDTH_COLLAPSED,
      background: (t: Theme) => t.palette.secondary.main,
    },
    '.tablet-layout &, .mobile-layout &': {
      position: 'fixed',
      transition: 'all .3s',
      borderRight: 'none',
      height: {
        xs: `calc(100% - ${HEADER_HEIGHTS.xs - 1}px)`,
        sm: `calc(100% - ${HEADER_HEIGHTS.tablet - 1}px)`,
        lg: `calc(100% - ${HEADER_HEIGHTS.desktop - 1}px)`,
      },
      bottom: 0,
      left: 0,
      transform: 'translate3d(-100%, 0, 0)',
      [t.breakpoints.down('sm')]: {
        maxWidth: '100%',
        minWidth: '100%',
      },
    },
    '.sandwich-menu-open &': {
      transform: 'translate3d(0%, 0, 0)',
    },
  }),
  backdrop: {
    bottom: 0,
    top: 'auto',
    height: {
      xs: `calc(100% - ${HEADER_HEIGHTS.xs - 1}px)`,
      sm: `calc(100% - ${HEADER_HEIGHTS.tablet - 1}px)`,
      lg: `calc(100% - ${HEADER_HEIGHTS.desktop - 1}px)`,
    },
  },
};
