import axios from 'axios';
import { TKeys } from '@/i18n/useTranslate';
import { getError } from '@/store/common/error-handlers';
import { errorToast } from '@/components/Toast';
import { ValidationErrorType } from '@/types/ValidationError';

const showErrorToast = (str: TKeys<'common' | 'validationErrors'>) => {
  errorToast({ text: str });
};
export const showError500OrUnknownToast = (
  e: unknown,
  text?: TKeys<'common'>
) => {
  if (import.meta.env.MODE !== 'production') {
    console.error('ERROR', e, text);
  }

  if (axios.isAxiosError(e) && e.response?.status === 500) {
    showErrorToast(ValidationErrorType.Error_500);
    return;
  }
  if (axios.isAxiosError(e) && e.response?.status === 401) {
    return;
  }
  const err = getError(e);
  showErrorToast(
    err === ValidationErrorType.UNKNOWN_ERROR && text ? text : err
  );
};
