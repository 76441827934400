import { createContext, useContext } from 'react';

export const TableContext = createContext<TableContextType>({
  onSort: () => {},
});

export enum Sort {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface TableContextType {
  onSort?: ((params: { [name: string]: Sort }) => void) | undefined;
  sortState?: Record<string, Sort | undefined>;
}

export const useTableContext = () => {
  return useContext<TableContextType>(TableContext);
};
