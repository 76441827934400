import {
  Breadcrumbs as MuiBreadcrumbs,
  SxProps,
  Theme,
  styled,
  Typography,
  Box,
} from '@mui/material';
import React, { FC, memo, PropsWithChildren, ReactNode } from 'react';
import IconSVGArrowLeft from 'ui-kit/lib/icons/24/Arrow/ChevronRight';

/**
 * @deprecated
 * Awaits replacement from the UI-Kit
 */
export const Breadcrumbs = memo(
  ({
    children,
    className,
    sx,
  }: PropsWithChildren<{ className?: string; sx?: SxProps<Theme> }>) => {
    return (
      <StyledBreadcrumbs
        separator={
          <Box
            component={IconSVGArrowLeft}
            size={16}
            sx={{ color: (t) => t.palette.grey[800] }}
          />
        }
        className={className}
        sx={sx}
      >
        {children}
      </StyledBreadcrumbs>
    );
  }
);
Breadcrumbs.displayName = 'Breadcrumbs';
const StyledBreadcrumbs = styled(MuiBreadcrumbs)`
  a {
    line-height: 24px;
  }
  a,
  a:visited {
    color: ${({ theme }) => theme.palette.grey[800]};
  }
  p {
    color: ${({ theme }) => theme.palette.grey[400]};
  }
  .MuiBreadcrumbs-li {
    line-height: 0;
  }
  span {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }
  .MuiBreadcrumbs-separator {
    margin-left: 9px;
    margin-right: 9px;
  }
  * {
    font-weight: 500;
    font-size: 16px !important;
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const BreadcrumbsText: FC<{ children: ReactNode }> = ({ children }) => (
  <Typography variant={'16_20_500'}>{children}</Typography>
);
