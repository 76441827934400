export const envs = {
  get PASSPORT_BACKEND(): string {
    return (
      window.env?.REACT_APP_PASSPORT_BACKEND ??
      import.meta.env.VITE_PASSPORT_BACKEND ??
      ''
    );
  },
  get PASSPORT_IDENTITY_API_V1(): string {
    return (
      window.env?.REACT_APP_PASSPORT_IDENTITY_API_V1 ??
      import.meta.env.VITE_PASSPORT_IDENTITY_API_V1 ??
      ''
    );
  },
  get GOOGLE_API_KEY(): string {
    return (
      window.env?.GOOGLE_API_KEY ?? import.meta.env.VITE_GOOGLE_API_KEY ?? ''
    );
  },
  get GOOGLE_RECAPTCHA_KEY(): string {
    return (
      window.env?.REACT_APP_GOOGLE_RECAPTCHA_KEY ??
      import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY ??
      ''
    );
  },
  get GOOGLE_TAG_MANAGER_ID(): string {
    return (
      window.env?.GOOGLE_TAG_MANAGER_ID ??
      import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID ??
      ''
    );
  },
  get PATIENT_PORTAL_URL(): string {
    return (
      window.env?.REACT_APP_PATIENT_PORTAL_URL ??
      import.meta.env.VITE_PATIENT_PORTAL_URL ??
      ''
    );
  },
  get HELP_URL(): string {
    return (
      window.env?.REACT_APP_HELP_URL ?? import.meta.env.VITE_HELP_URL ?? ''
    );
  },
  get CONTACT_SUPPORT_URL(): string {
    return (
      window.env?.REACT_APP_CONTACT_SUPPORT_URL ??
      import.meta.env.VITE_CONTACT_SUPPORT_URL ??
      ''
    );
  },
  get PRIVACY_POLICY_BASE_URL(): string {
    return (
      window.env?.REACT_APP_PRIVACY_POLICY_BASE_URL ??
      import.meta.env.VITE_PRIVACY_POLICY_BASE_URL
    );
  },
  get MAX_PASSWORD_SCORE(): number {
    return (
      window.env?.REACT_APP_MAX_PASSWORD_SCORE ??
      import.meta.env.VITE_MAX_PASSWORD_SCORE
    );
  },
  get DISPLAY_DATA_TEST_ID(): string {
    return (
      window.env?.DISPLAY_DATA_TEST_ID ??
      import.meta.env.VITE_DISPLAY_DATA_TEST_ID
    );
  },
};
