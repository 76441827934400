import { useMemo } from 'react';
import { UUID } from '@/types';
import { useAppDispatch, useAppSelector } from '@/store/store';
import { companiesActions, FormNames } from '@/store/companies/slice';
import { Role } from '@/api/__generated__/webApi';

export const useEditRoleForm = () => {
  const state = useAppSelector((state) => state.companies);
  const dispatch = useAppDispatch();
  const form = state.forms[FormNames.EditRole];
  const callbacks = useMemo(() => {
    return {
      resetErrors: () => {
        dispatch(
          companiesActions.resetFormErrors({ formName: FormNames.EditRole })
        );
      },
      showEditRoleForm: ({
        roleId,
        moduleId,
        companyId,
      }: {
        roleId: UUID | undefined;
        moduleId: UUID | undefined;
        companyId: UUID | undefined;
      }) => {
        if (!roleId || !moduleId || !companyId) {
          console.error(
            '[showEditRoleForm] Some of required parameters is undefined',
            { roleId, companyId, moduleId }
          );
          return;
        }
        dispatch(
          companiesActions.setForm({
            forms: {
              [FormNames.EditRole]: {
                roleId,
                state: 'active',
                companyId,
                moduleId,
              },
            },
            merge: false,
          })
        );
      },
      resetEditRoleForm() {
        dispatch(companiesActions.resetForm({ formName: FormNames.EditRole }));
      },

      submitTheRole(role: Role) {
        if (!form?.moduleId || !form?.companyId || !role?.id) {
          console.error(
            '[submitTheRole] Some of the required params are undefined',
            { role, moduleId: form?.moduleId, companyId: form?.companyId }
          );
          return;
        }
        dispatch(
          companiesActions.requestEditRole({
            role,
            moduleId: form.moduleId,
            companyId: form.companyId,
          })
        );
      },
    };
  }, [dispatch, form?.companyId, form?.moduleId]);
  return {
    editRoleFormShown: !!form,
    role: state.roles[form?.moduleId as string]?.byID[form?.roleId as string],
    ...callbacks,
  };
};
