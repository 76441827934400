import { FC } from 'react';
import { AnimatedSkeletonSvg } from './AnimatedSkeletonSvg.tsx';
import EvenSkeleton from './images/skeletonTree.svg?react';
import ArticleSkeleton from './images/skeletonSelected.svg?react';
interface SkeletonProps {
  variant: 'even' | 'article';
}
export const Skeleton: FC<SkeletonProps> = ({ variant }) => {
  let content = <EvenSkeleton />;
  switch (variant) {
    case 'even':
      break;
    case 'article':
      content = <ArticleSkeleton />;
  }
  return <AnimatedSkeletonSvg>{content}</AnimatedSkeletonSvg>;
};
