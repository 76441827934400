import React, { FC, PropsWithChildren } from 'react';
import { useField } from 'formik';
import { RadioGroup } from '@mui/material';
import { INHERITED_DATA_TEST_ID } from '@/utils/testId.ts';

export const RadioGroupF: FC<
  PropsWithChildren<{ name: string; className?: string }>
> = (props) => {
  const [field] = useField(props);
  return (
    <RadioGroup data-test-id={INHERITED_DATA_TEST_ID} {...field} {...props} />
  );
};
