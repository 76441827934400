import { useProfileState } from '@/store/profile/hooks';
import { profileActions } from '@/store/profile/slice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

export const useBackUrl = () => {
  const [searchParams] = useSearchParams();
  const state = useProfileState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (state.redirect) {
      navigate({ pathname: state.redirect });
      dispatch(profileActions.redirect(null));
    }
  }, [dispatch, navigate, state.redirect]);
  useEffect(() => {
    for (const [name, param] of searchParams) {
      if (name.toLowerCase() === 'backurl') {
        dispatch(profileActions.requestCheckBackUrl({ backUrl: param }));
        break;
      }
    }
  }, [dispatch, searchParams]);
};
