/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { RoleName } from '@/types';

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;
// @ts-expect-error for ts types the error is not critical
export type ADFSOpenidSettings = UtilRequiredKeys<
  OpenidSettingsDTO,
  'clientId' | 'clientSecret' | 'discoveryUrl' | 'enable' | 'type'
>;

export enum AccountCompanyStatus {
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export interface AccountDetails {
  avatarUrl?: string;
  contact?: Contact;
  email?: string;
  /** @format uuid */
  id: string;
  /** @format int64 */
  npi?: number;
  phone?: string;
}

export interface AccountSessionDTO {
  current?: boolean;
  id: string;
  ipAddress?: string;
}

export enum AccountStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  INVITE = 'INVITE',
  BLOCKED = 'BLOCKED',
}

export interface AccountTOTP {
  description?: string;
  /** @format uuid */
  id: string;
  qrCode?: string;
}

export interface AccountTOTPCreateRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  description: string;
}

export interface AccountTOTPDescriptionUpdateRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  description: string;
  /** @format uuid */
  id: string;
}

export interface AddressChangeRequest {
  /**
   * @minLength 0
   * @maxLength 55
   */
  address1: string;
  /**
   * @minLength 0
   * @maxLength 55
   */
  address2?: string;
  /**
   * @minLength 0
   * @maxLength 40
   */
  city: string;
  /**
   * @minLength 0
   * @maxLength 40
   */
  cityReplacement?: string;
  /** Location */
  location?: Location;
  postalCode: string;
  /** States of America */
  state: StateType;
}

/** Base contact document */
export type BaseDocument = BaseBaseDocument &
  (
    | BaseBaseDocumentTypeMapping<'BIRTH_CERTIFICATE', USABCDocument>
    | BaseBaseDocumentTypeMapping<'DRIVER_LICENCE_ID_CARD', USADLIDDocument>
    | BaseBaseDocumentTypeMapping<'GREEN_CARD', USAGCDocument>
    | BaseBaseDocumentTypeMapping<'PASSPORT', PassportDocument>
  );

/** Object with error message «Internal Server Error» */
export interface BaseError {
  /** Error message */
  message?: string;
}

/** Base account phone */
export type BasePhone = BaseBasePhone &
  (
    | BaseBasePhoneTypeMapping<'HOME_PHONE', HomePhone>
    | BaseBasePhoneTypeMapping<'MOBILE_PHONE', MobilePhone>
    | BaseBasePhoneTypeMapping<'WORK_PHONE', WorkPhone>
  );

/** Base account phone */
export type BasePhoneCreate = BaseBasePhoneCreate &
  (
    | BaseBasePhoneCreateTypeMapping<'HOME_PHONE', HomePhoneCreate>
    | BaseBasePhoneCreateTypeMapping<'MOBILE_PHONE', MobilePhoneCreate>
    | BaseBasePhoneCreateTypeMapping<'WORK_PHONE', WorkPhoneCreate>
  );

export interface BatchIdRequest {
  /** @uniqueItems true */
  ids: string[];
}

export enum BirthSex {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNKNOWN = 'UNKNOWN',
}

export interface ChangePassword {
  currentPassword: string;
  newPassword: string;
}

export enum CodeType {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  ADD_MOBILE_PHONE = 'ADD_MOBILE_PHONE',
  CONFIRM_MOBILE_PHONE = 'CONFIRM_MOBILE_PHONE',
}

export interface Company {
  description?: string;
  /** @format uuid */
  id: string;
  locked?: boolean;
  /**
   * @deprecated
   * @format uuid
   */
  logo?: string;
  logoUrl?: string;
  modules?: Module[];
  name: string;
  /** @format int64 */
  npi?: number;
  /** @format int64 */
  parentNpi?: number;
}

export interface CompanyAddress {
  /** First address */
  address1: string;
  /** Second address */
  address2: string;
  /** City */
  city: string;
  /** City replacement */
  cityReplacement?: string;
  /** Client bill type */
  clientBillType?: boolean;
  /**
   * End date
   * @format date
   */
  endDate?: string;
  /** Fax number */
  faxNumber?: string;
  /** Google place id */
  googlePlaceId?: string;
  /**
   * Company address id
   * @format uuid
   */
  id: string;
  /** Insurance bill type */
  insuranceBillType?: boolean;
  /** Invoices receiving */
  invoicesReceiving?: boolean;
  /**
   * latitude
   * @format double
   */
  latitude?: number;
  /**
   * longitude
   * @format double
   */
  longitude?: number;
  /** Patient bill type */
  patientBillType?: boolean;
  /** Postal code */
  postalCode: string;
  /** States of America */
  state: StateType;
  /** Telephone number */
  telephoneNumber: string;
}

export interface CompanyAddressCreateRequest {
  /**
   * First address
   * @minLength 0
   * @maxLength 55
   */
  address1: string;
  /**
   * Second address
   * @minLength 0
   * @maxLength 55
   */
  address2: string;
  /**
   * City
   * @minLength 0
   * @maxLength 40
   */
  city: string;
  /**
   * City replacement
   * @minLength 0
   * @maxLength 40
   */
  cityReplacement?: string;
  /** Client bill type */
  clientBillType?: boolean;
  /**
   * End date
   * @format date
   */
  endDate?: string;
  /** Fax number */
  faxNumber?: string;
  /** Insurance bill type */
  insuranceBillType?: boolean;
  /** Invoices receiving */
  invoicesReceiving?: boolean;
  /** Location */
  location?: Location;
  /** Patient bill type */
  patientBillType?: boolean;
  /** Postal Code (Zip Code) */
  postalCode: string;
  /** States of America */
  state: StateType;
  /** Telephone number */
  telephoneNumber?: string;
}

export interface CompanyAddressUpdateRequest {
  /** Second address */
  address2?: NillableString;
  /** Patient bill type */
  clientBillType?: NillableBoolean;
  /**
   * End date
   * @format date
   */
  endDate?: string;
  /** Fax number */
  faxNumber?: string;
  /** Patient bill type */
  insuranceBillType?: NillableBoolean;
  /** Invoices receiving */
  invoicesReceiving?: boolean;
  /** Patient bill type */
  patientBillType?: NillableBoolean;
  /** Telephone number */
  telephoneNumber?: string;
}

export interface CompanyConfirmRequest {
  npi: string;
  parentNpi?: string;
  thDomain: string;
}

/** Base NPI entity */
export type CompanyData = BaseCompanyData & { type: NPIType.NON_NPI };

export interface CompanyInsuranceCarrier {
  /** @format uuid */
  id: string;
  insuranceCarrier?: InsuranceCarrier;
}

export interface CompanyInsuranceCarrierCreateRequest {
  /** @format uuid */
  insuranceCarrierId: string;
}

export interface CompanyPermission {
  /** @format uuid */
  companyId: string;
  /** @uniqueItems true */
  permissions: string[];
  permissionsDigest?: string;
}

export interface CompanyUser {
  accountCompanyStatus?: AccountCompanyStatus;
  accountStatus?: AccountStatus;
  avatarUrl?: string;
  domainEmail?: string;
  emails?: Email[];
  firstName?: string;
  /** @format uuid */
  id: string;
  /** @format date-time */
  inviteExpiredTime?: string;
  lastName?: string;
  moduleRoles?: ModuleRoles[];
  phones?: BasePhone[];
}

export interface CompanyVerifyRequest {
  npi: string;
}

export interface ConfirmCodeRequest {
  code: string;
  recaptcha?: string;
}

export interface Contact {
  /** @format date */
  birthDate?: string;
  birthSex?: BirthSex;
  ethnicity?: ContactEthnicityEnum;
  firstName?: string;
  gender?: ContactGenderEnum;
  lastName?: string;
  middleName?: string;
  race?: ContactRaceEnum;
  sexualOrientation?: ContactSexualOrientationEnum;
  ssnTail?: string;
}

export interface ContactAddress {
  /** Additional address */
  additional?: string;
  /** City */
  city?: string;
  /**
   * End date
   * @format date
   */
  endDate?: string;
  /** Google place id */
  googlePlaceId?: string;
  /**
   * Contact address id
   * @format uuid
   */
  id: string;
  /**
   * latitude
   * @format double
   */
  latitude?: number;
  /**
   * longitude
   * @format double
   */
  longitude?: number;
  /** Main address */
  main?: string;
  /** Postal Code (Zip Code) */
  postalCode?: string;
  /** States of America */
  state?: StateType;

  cityReplacement?: string;
}

export interface ContactEdit {
  /** @format date */
  birthDate?: string;
  birthSex: BirthSex;
  ethnicity?: ContactEditEthnicityEnum;
  /**
   * @minLength 0
   * @maxLength 50
   */
  firstName: string;
  gender?: ContactEditGenderEnum;
  /**
   * @minLength 0
   * @maxLength 50
   */
  lastName: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  middleName?: string;
  race?: ContactEditRaceEnum;
  sexualOrientation?: ContactEditSexualOrientationEnum;
}

export enum CreateModuleType {
  LIS = 'LIS',
  PROFESSIONAL = 'PROFESSIONAL',
}

/** Document type */
export enum DocumentType {
  USA_DRIVER_LICENCE_ID_CARD = 'USA_DRIVER_LICENCE_ID_CARD',
  PASSPORT = 'PASSPORT',
  USA_GREEN_CARD = 'USA_GREEN_CARD',
  USA_BIRTH_CERTIFICATE = 'USA_BIRTH_CERTIFICATE',
}

export enum DoubleOptInStatus {
  NOT_SENT = 'NOT_SENT',
  SENT = 'SENT',
  CONFIRMED = 'CONFIRMED',
}

export interface Email {
  allowResultsDelivery?: boolean;
  doubleOptIn?: DoubleOptInStatus;
  email?: string;
  /** @format uuid */
  id: string;
  main?: boolean;
  ownershipConfirmed?: boolean;
  resendTime?: number;
}

export interface EmailCreateRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  email: string;
}

export interface FieldError {
  error?: string;
  field?: string;
}

export type HomePhone = BaseBasePhone;

export type HomePhoneCreate = UtilRequiredKeys<
  BaseBasePhoneCreate,
  'phone' | 'type'
>;

export interface InsuranceCarrier {
  /** @format uuid */
  id: string;
  /** @format uuid */
  logo?: string;
  name?: string;
}

export interface InvitationResultResponse {
  companyName?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  phone?: string;
}
// @ts-expect-error for ts types the error is not critical
export type KeycloakOpenidSettings = UtilRequiredKeys<
  OpenidSettingsDTO,
  'clientId' | 'clientSecret' | 'discoveryUrl' | 'enable' | 'type'
>;

/** Location */
export interface Location {
  /** Google place id */
  googlePlaceId: string;
  /**
   * Latitude
   * @format double
   */
  latitude: number;
  /**
   * Longitude
   * @format double
   */
  longitude: number;
}

export interface MedicareProviderRequest {
  npi: string;
}

export type MobilePhone = BaseBasePhone & {
  allowResultsDelivery?: boolean;
  main?: boolean;
};

export type MobilePhoneCreate = UtilRequiredKeys<
  BaseBasePhoneCreate,
  'phone' | 'type'
>;

export interface Module {
  /** @format uuid */
  id: string;
  status?: ModuleStatus;
  type: ModuleType;
}

export interface ModuleCreateRequest {
  module: CreateModuleType;
}

export interface ModuleRoles {
  module: Module;
  roles: Role[];
}

export enum ModuleStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  WAITING = 'WAITING',
  RUNNING = 'RUNNING',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
  OFF = 'OFF',
}

export interface ModuleStatusResponse {
  status?: ModuleStatus;
}

export enum ModuleType {
  PASSPORT = 'PASSPORT',
  LIS = 'LIS',
  PROFESSIONAL = 'PROFESSIONAL',
}

export type NPI1Data = BaseCompanyData & {
  addresses?: NPIAddress[];
  birthSex?: BirthSex;
  credential?: string;
  /** @format date */
  deactivationDate?: string;
  deactivationReason?: string;
  /** @format date-time */
  enumerationDate?: string;
  firstName?: string;
  identifiers?: NPIIdentifier[];
  lastName?: string;
  /** @format date-time */
  lastUpdated?: string;
  middleName?: string;
  namePrefix?: string;
  nameSuffix?: string;
  otherNames?: NPI1OtherName[];
  /** @format date */
  reactivationDate?: string;
  /** @format int64 */
  replacementId?: number;
  taxonomies?: NPITaxonomy[];
} & { type: NPIType.NPI1 };

export interface NPI1OtherName {
  credential?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  prefix?: string;
  suffix?: string;
  type?: NPI1OtherNameType;
}

export enum NPI1OtherNameType {
  FORMER_NAME = 'FORMER_NAME',
  PROFESSIONAL_NAME = 'PROFESSIONAL_NAME',
  OTHER_NAME = 'OTHER_NAME',
}

export interface NPI1ShortData {
  birthSex?: BirthSex;
  credential?: string;
  /** @format date-time */
  enumerationDate?: string;
  firstName?: string;
  lastName?: string;
  /** @format date-time */
  lastUpdated?: string;
  locationAddress?: NPIAddress;
  middleName?: string;
  namePrefix?: string;
  nameSuffix?: string;
  practiceAddresses?: CompanyAddress[];
  primaryTaxonomy?: NPITaxonomy;
  /** @format int64 */
  replacementId?: number;
  type?: NPIType;
}

export type NPI2Data = BaseCompanyData & {
  addresses?: NPIAddress[];
  credential?: string;
  /** @format date */
  deactivationDate?: string;
  deactivationReason?: string;
  ein?: string;
  /** @format date-time */
  enumerationDate?: string;
  firstName?: string;
  identifiers?: NPIIdentifier[];
  lastName?: string;
  /** @format date-time */
  lastUpdated?: string;
  middleName?: string;
  namePrefix?: string;
  nameSuffix?: string;
  organizationName?: string;
  organizationalSubpart?: boolean;
  otherNames?: NPI2OtherName[];
  parentOrganizationEin?: string;
  parentOrganizationName?: string;
  /** @format date */
  reactivationDate?: string;
  /** @format int64 */
  replacementId?: number;
  taxonomies?: NPITaxonomy[];
} & { type: NPIType.NPI2 };

export interface NPI2OtherName {
  organizationName?: string;
  type?: NPI2OtherNameType;
}

export enum NPI2OtherNameType {
  DOING_BUSINESS_AS = 'DOING_BUSINESS_AS',
  FORMER_LEGAL_BUSINESS_NAME = 'FORMER_LEGAL_BUSINESS_NAME',
  OTHER_NAME = 'OTHER_NAME',
}

export interface NPI2ShortData {
  credential?: string;
  ein?: string;
  /** @format date-time */
  enumerationDate?: string;
  firstName?: string;
  lastName?: string;
  /** @format date-time */
  lastUpdated?: string;
  locationAddress?: NPIAddress;
  middleName?: string;
  namePrefix?: string;
  nameSuffix?: string;
  organizationName?: string;
  organizationalSubpart?: boolean;
  parentOrganizationEin?: string;
  parentOrganizationName?: string;
  practiceAddresses?: CompanyAddress[];
  /** @format int64 */
  replacementId?: number;
  telephoneNumber?: string;
  titleOrPosition?: string;
  type?: NPIType;
}

export interface NPIAddress {
  city?: string;
  countryCode?: NPICountryCode;
  countryName?: string;
  faxNumber?: string;
  firstLine?: string;
  postalCode?: string;
  purposeType?: NPIAddressPurposeType;
  secondLine?: string;
  stateCode?: string;
  telephoneNumber?: string;
  type?: NPIAddressType;
  /** @format date-time */
  updatedDate?: string;
}

export enum NPIAddressPurposeType {
  LOCATION = 'LOCATION',
  MAILING = 'MAILING',
  PRACTICE = 'PRACTICE',
}

export enum NPIAddressType {
  DOM = 'DOM',
  FGN = 'FGN',
  MIL = 'MIL',
}

export enum NPICountryCode {
  AF = 'AF',
  AL = 'AL',
  DZ = 'DZ',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  IO = 'IO',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  CV = 'CV',
  KY = 'KY',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CX = 'CX',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CD = 'CD',
  CK = 'CK',
  CR = 'CR',
  HR = 'HR',
  CI = 'CI',
  CU = 'CU',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  TF = 'TF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GB = 'GB',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  VA = 'VA',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KP = 'KP',
  KR = 'KR',
  XK = 'XK',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MK = 'MK',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  AN = 'AN',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  KN = 'KN',
  SH = 'SH',
  LC = 'LC',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  CS = 'CS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  GS = 'GS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SZ = 'SZ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TW = 'TW',
  TJ = 'TJ',
  TZ = 'TZ',
  TH = 'TH',
  TL = 'TL',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  US = 'US',
  UY = 'UY',
  UM = 'UM',
  UZ = 'UZ',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  VG = 'VG',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW',
}

export interface NPIIdentifier {
  description?: string;
  identifier?: string;
  issuer?: string;
  /** States of America */
  stateCode?: StateType;
}

export interface NPITaxonomy {
  code?: string;
  description?: string;
  group?: string;
  license?: string;
  primary?: boolean;
  /** States of America */
  stateCode?: StateType;
}

export enum NPIType {
  NON_NPI = 'NON_NPI',
  NPI1 = 'NPI1',
  NPI2 = 'NPI2',
}

/** Patient bill type */
export interface NillableBoolean {
  value?: boolean;
}

/** Second address */
export interface NillableString {
  value?: string;
}
// @ts-expect-error for ts types the error is not critical
export type OpenidSettingsDTO = BaseOpenidSettingsDto &
  (
    | BaseOpenidSettingsDtoTypeMapping<'ADFS', ADFSOpenidSettings>
    | BaseOpenidSettingsDtoTypeMapping<'KEYCLOAK', KeycloakOpenidSettings>
  );

export interface PageCompanyUser {
  hasNext: boolean;
  items: CompanyUser[];
}

export interface PageInsuranceCarrier {
  hasNext: boolean;
  items: InsuranceCarrier[];
}

export interface ParentCompanyVerifyRequest {
  npi: string;
}

/** Passport */
export type PassportDocument = BaseBaseDocument & {
  /** Country code ISO3166-1 alpha-3 */
  countryCode?: string;
  /**
   * Expiration date
   * @format date
   */
  expirationDate?: string;
  /** Given names */
  givenNames?: string;
  /**
   * Issued date
   * @format date
   */
  issueDate?: string;
  /** Passport number */
  number?: string;
  /** Surname */
  surname?: string;
};

export interface Permission {
  action: PermissionActionType;
  description: string;
  /** @format uuid */
  group: string;
  /** @format uuid */
  id: string;
  removable?: boolean;
}

export enum PermissionActionType {
  ACCESS = 'ACCESS',
  ACTION = 'ACTION',
  DELETE = 'DELETE',
  ROLE = 'ROLE',
}

export interface PermissionGroup {
  /** @format uuid */
  id: string;
  name: string;
  /** @format uuid */
  parent?: string;
}

export interface PermissionTree {
  groups?: PermissionGroup[];
  permissions?: Permission[];
}

/** Phone type */
export enum PhoneType {
  HOME_PHONE = 'HOME_PHONE',
  WORK_PHONE = 'WORK_PHONE',
  MOBILE_PHONE = 'MOBILE_PHONE',
}

export interface RecaptchaRequest {
  recaptcha?: string;
}

export interface RemoveAccountSessionRequest {
  /** @uniqueItems true */
  sessionIds: string[];
}

export interface ResendCodeRequest {
  codeType: CodeType;
  recaptcha?: string;
}

export interface ResendTimeEmail {
  allowResultsDelivery?: boolean;
  doubleOptIn?: DoubleOptInStatus;
  email?: string;
  /** @format uuid */
  id: string;
  main?: boolean;
  ownershipConfirmed?: boolean;
  /** @format int64 */
  resendTime?: number;
}

export interface ResendTimeResponse {
  /** @format int64 */
  resendTime: number;
}

export interface Role {
  description: string;
  editable?: boolean;
  /** @format uuid */
  id: string;
  name: string | RoleName;
}

export interface RoleCreateRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  description: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /** @uniqueItems true */
  permissions: string[];
}

export interface RoleUpdateRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  description: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
}

/** States of America */
export enum StateType {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  FM = 'FM',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
  ZZ = 'ZZ',
}
// @ts-expect-error for ts types the error is not critical
export type URLCompanyModule = URLResolvingDTO & {
  /** @format uuid */
  companyId?: string;
  module?: ModuleType;
};
// @ts-expect-error for ts types the error is not critical
export type URLRedirect = URLResolvingDTO;

// @ts-expect-error for ts types the error is not critical
export type URLResolvingDTO = BaseUrlResolvingDto &
  (
    | BaseUrlResolvingDtoTypeMapping<'COMPANY_MODULE', URLCompanyModule>
    | BaseUrlResolvingDtoTypeMapping<'REDIRECT', URLRedirect>
  );

/** USA birth certificate */
export type USABCDocument = BaseBaseDocument & {
  /** Given names */
  givenNames?: string;
  /**
   * issue Date
   * @format date
   */
  issueDate?: string;
  /** Number */
  number?: string;
  /** Surname */
  surname?: string;
};

/** USA Driver licence or id card */
export type USADLIDDocument = BaseBaseDocument & {
  /**
   * Expiration date
   * @format date
   */
  expirationDate?: string;
  /** Given names */
  givenNames?: string;
  /**
   * Issue date
   * @format date
   */
  issueDate?: string;
  /** USA Driver licence number */
  number?: string;
  /** USA driver licences or id card option type */
  optionType?: USADLIDOptionType;
  /** Surname */
  surname?: string;
};

/** USA driver licences or id card option type */
export enum USADLIDOptionType {
  DRL = 'DRL',
  IDC = 'IDC',
}

/** USA Green card */
export type USAGCDocument = BaseBaseDocument & {
  /**
   * Expiration date
   * @format date
   */
  expirationDate?: string;
  /** Given names */
  givenNames?: string;
  /** Number */
  number?: string;
  /**
   * Resident since
   * @format date
   */
  residentSince?: string;
  /** Surname */
  surname?: string;
};

export interface UpdateDescriptionRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  description?: string;
}

export interface UpdateNameRequest {
  /**
   * @minLength 0
   * @maxLength 80
   */
  name: string;
}

export type WorkPhone = BaseBasePhone;

export type WorkPhoneCreate = UtilRequiredKeys<
  BaseBasePhoneCreate,
  'phone' | 'type'
>;

/** Base contact document */
interface BaseBaseDocument {
  /** Is document deleted */
  deleted?: boolean;
  /**
   * Contact document id
   * @format uuid
   */
  id: string;
  /** Document type */
  type?: DocumentType;
}

type BaseBaseDocumentTypeMapping<Key, Type> = {
  type: Key;
} & Type;

/** Base account phone */
interface BaseBasePhone {
  /** @format uuid */
  id: string;
  ownershipConfirmed?: boolean;
  phone?: string;
  /** Phone type */
  type?: PhoneType;
}

type BaseBasePhoneTypeMapping<Key, Type> = {
  type: Key;
} & Type;

/** Base account phone */
interface BaseBasePhoneCreate {
  phone: string;
  /** Phone type */
  type: PhoneType;
}

type BaseBasePhoneCreateTypeMapping<Key, Type> = {
  type: Key;
} & Type;

/** Base NPI entity */
interface BaseCompanyData {
  practiceAddresses?: CompanyAddress[];
  type?: NPIType;
}

export enum ContactEthnicityEnum {
  HISPANIC_OR_LATINO = 'HISPANIC_OR_LATINO',
  NOT_HISPANIC_OR_LATINO = 'NOT_HISPANIC_OR_LATINO',
  OTH = 'OTH',
  UNK = 'UNK',
  ASKU = 'ASKU',
}

export enum ContactGenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  FEMALE_TO_MALE = 'FEMALE_TO_MALE',
  MALE_TO_FEMALE = 'MALE_TO_FEMALE',
  GENDERQUEER = 'GENDERQUEER',
  OTH = 'OTH',
  ASKU = 'ASKU',
}

export enum ContactRaceEnum {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  ASIAN = 'ASIAN',
  BLACK_OR_AFRICAN_AMERICAN = 'BLACK_OR_AFRICAN_AMERICAN',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  WHITE = 'WHITE',
  OTHER = 'OTHER',
  ASKU = 'ASKU',
  UNKNOWN = 'UNKNOWN',
}

export enum ContactSexualOrientationEnum {
  LESBIAN_GAY_HOMOSEXUAL = 'LESBIAN_GAY_HOMOSEXUAL',
  STRAIGHT_HETEROSEXUAL = 'STRAIGHT_HETEROSEXUAL',
  BISEXUAL = 'BISEXUAL',
  OTH = 'OTH',
  UNK = 'UNK',
  ASKU = 'ASKU',
}

export enum ContactEditEthnicityEnum {
  HISPANIC_OR_LATINO = 'HISPANIC_OR_LATINO',
  NOT_HISPANIC_OR_LATINO = 'NOT_HISPANIC_OR_LATINO',
  OTH = 'OTH',
  UNK = 'UNK',
  ASKU = 'ASKU',
}

export enum ContactEditGenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  FEMALE_TO_MALE = 'FEMALE_TO_MALE',
  MALE_TO_FEMALE = 'MALE_TO_FEMALE',
  GENDERQUEER = 'GENDERQUEER',
  OTH = 'OTH',
  ASKU = 'ASKU',
}

export enum ContactEditRaceEnum {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  ASIAN = 'ASIAN',
  BLACK_OR_AFRICAN_AMERICAN = 'BLACK_OR_AFRICAN_AMERICAN',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  WHITE = 'WHITE',
  OTHER = 'OTHER',
  ASKU = 'ASKU',
  UNKNOWN = 'UNKNOWN',
}

export enum ContactEditSexualOrientationEnum {
  LESBIAN_GAY_HOMOSEXUAL = 'LESBIAN_GAY_HOMOSEXUAL',
  STRAIGHT_HETEROSEXUAL = 'STRAIGHT_HETEROSEXUAL',
  BISEXUAL = 'BISEXUAL',
  OTH = 'OTH',
  UNK = 'UNK',
  ASKU = 'ASKU',
}

export enum TypeEnum {
  ADFS = 'ADFS',
  KEYCLOAK = 'KEYCLOAK',
}

interface BaseOpenidSettingsDto {
  clientId: string;
  clientSecret: string;
  discoveryUrl: string;
  enable: boolean;
  type: BaseOpenidSettingsDtoTypeEnum;
}

type BaseOpenidSettingsDtoTypeMapping<Key, Type> = {
  type: Key;
} & Type;

export enum TypeEnum1 {
  COMPANY_MODULE = 'COMPANY_MODULE',
  REDIRECT = 'REDIRECT',
}

interface BaseUrlResolvingDto {
  type?: BaseUrlResolvingDtoTypeEnum;
  url?: string;
}

type BaseUrlResolvingDtoTypeMapping<Key, Type> = {
  type: Key;
} & Type;

export interface UploadAccountAvatarPayload {
  /** @format binary */
  file: File;
}

export interface ResolveAccountUrlParams {
  url: string;
}

export interface GetCompanyModuleRoleUsersParams {
  filter?: string;
  /**
   * @format int32
   * @min 0
   * @default 0
   */
  startPage?: number;
  /**
   * @format int32
   * @min 1
   * @max 30
   */
  perPage: number;
  /** @format uuid */
  companyId: string;
  /** @format uuid */
  companyModuleId: string;
  /** @format uuid */
  roleId: string;
}

export interface UpdateCompanyLogoPayload {
  /** @format binary */
  file: File;
}

export interface GetCompanyUsersParams {
  /** @format uuid */
  includeRoleId?: string;
  /** @format uuid */
  excludeRoleId?: string;
  includeRoles?: boolean;
  accountCompanyStatus?: AccountCompanyStatus;
  /** @uniqueItems true */
  statuses?: AccountCompanyStatus[];
  filter?: string;
  /**
   * @format int32
   * @min 0
   * @default 0
   */
  startPage?: number;
  /**
   * @format int32
   * @min 1
   * @max 30
   */
  perPage: number;
  /** @format uuid */
  companyId: string;
}

export interface SearchInsuranceCarriersParams {
  filter?: string;
  /**
   * @format int32
   * @min 0
   * @default 0
   */
  startPage?: number;
  /**
   * @format int32
   * @min 1
   * @max 30
   */
  perPage: number;
}

export enum BaseOpenidSettingsDtoTypeEnum {
  ADFS = 'ADFS',
  KEYCLOAK = 'KEYCLOAK',
}

export enum BaseUrlResolvingDtoTypeEnum {
  COMPANY_MODULE = 'COMPANY_MODULE',
  REDIRECT = 'REDIRECT',
}
