export const styles = {
  text: {
    mb: 0,
    mt: 0,
    fontSize: '14px',
    weight: 500,
    color: 'grey.500',
    fontStyle: 'italic',
  },
};
