import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyUUID, PartialRecord, UUID } from '@/types';
import { ValidationErrorType } from '@/types/ValidationError';

interface Form {
  data: string | null;
  validationError: ValidationErrorType | null;
  active: boolean | null;
}
export interface CompanySettingsState {
  nameForm: PartialRecord<CompanyUUID, Form>;
  descriptionForm: PartialRecord<CompanyUUID, Form>;
}
const initialState: CompanySettingsState = {
  nameForm: {},
  descriptionForm: {},
};
const slice = createSlice({
  name: 'companiesSettings',
  initialState,
  reducers: {
    toggleEditDescription(
      state,
      { payload }: PayloadAction<{ companyId: UUID }>
    ) {
      (state.descriptionForm[payload.companyId] = state.descriptionForm[
        payload.companyId
      ] ?? { data: null, validationError: null, active: false }).active =
        !state.descriptionForm[payload.companyId]?.active;
    },
    setNameForm(
      state,
      { payload }: PayloadAction<{ value: string | null; companyId: UUID }>
    ) {
      (state.nameForm[payload.companyId] = state.nameForm[
        payload.companyId
      ] ?? {
        data: null,
        validationError: null,
        active: true,
      }).data = payload.value;

      state.nameForm[payload.companyId]!.validationError = null;
    },
    setDescriptionForm(
      state,
      { payload }: PayloadAction<{ value: string | null; companyId: UUID }>
    ) {
      (state.descriptionForm[payload.companyId] = state.descriptionForm[
        payload.companyId
      ] ?? {
        data: null,
        validationError: null,
        active: true,
      }).data = payload.value;

      state.descriptionForm[payload.companyId]!.validationError = null;
    },
    setValidationError(
      state,
      {
        payload,
      }: PayloadAction<{
        name: 'nameForm' | 'descriptionForm';
        error: ValidationErrorType;
        companyId: UUID;
      }>
    ) {
      (state[payload.name][payload.companyId] = state[payload.name][
        payload.companyId
      ] ?? {
        data: null,
        validationError: null,
        active: true,
      }).validationError = payload.error;
    },
    submitDescription(_state, _payload: PayloadAction<{ companyId: UUID }>) {},
    submitName(_state, _payload: PayloadAction<{ companyId: UUID }>) {},
    resetState() {
      return initialState;
    },
  },
});

export const companiesSettingsStateName = slice.name;
export const companiesSettingsStateReducer = slice.reducer;
export const companiesSettingsActions = slice.actions;
