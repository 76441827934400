import { FieldSize } from '@/components/common/FieldSize';
import { TypographyTypeMap } from '@mui/material/Typography/Typography';
import { FC, ReactNode } from 'react';
import { SxProps, Theme, Typography } from '@mui/material';
import { spreadSx } from '@/utils/spreadSx';

const variants: Record<FieldSize, TypographyTypeMap['props']['variant']> = {
  sm: '14_18_700',
  md: '14_18_700',
  lg: '16_20_700',
} as const;
export const Label: FC<{
  children: ReactNode;
  htmlFor?: string;
  size?: FieldSize;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}> = ({ disabled, children, htmlFor, size = 'md', sx, ...props }) => {
  return (
    <Typography
      variant={variants[size]}
      component={'label'}
      htmlFor={htmlFor}
      sx={[
        {
          lineHeight: '20px',
          minWidth: 10,
          color: disabled ? 'grey.400' : 'text.primary',
        },
        ...spreadSx(sx),
      ]}
      {...props}
    >
      {children}
    </Typography>
  );
};
