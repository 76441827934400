import React, { FC } from 'react';
import { Stack, StackProps, Theme } from '@mui/material';
import { spreadSx } from '@/utils/spreadSx';
export type BarProps = {
  position: 'left' | 'right';
  active?: boolean;
} & Omit<StackProps, 'position'>;
export const HorizontalResizerBar: FC<BarProps> = ({
  position,
  sx,
  active,
  ...props
}) => {
  return (
    <Stack
      className={active ? RESIZER_ACTIVE_CLASSNAME : undefined}
      sx={[
        styles.container,
        position === 'left' ? { left: -3 } : { right: -3 },
        ...spreadSx(sx),
      ]}
      {...props}
    ></Stack>
  );
};
export const RESIZER_ACTIVE_CLASSNAME = 'resizer-active';
const styles = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: 3,
    height: '100%',
    backgroundColor: (t: Theme) => t.palette.secondary.main,
    top: 0,
    bottom: 0,
    cursor: 'col-resize',
    opacity: 0,
    transition: 'opacity 0.2s',
    transitionDelay: '.5s',
    zIndex: 1,
    '&:hover': {
      transitionDelay: '.2s',
      transitionDuration: '.1s',
      opacity: 1,
    },
    [`&.${RESIZER_ACTIVE_CLASSNAME}`]: {
      backgroundColor: (t: Theme) => t.palette.secondary[800],
      opacity: 1,
    },
  },
};
