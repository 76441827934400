import { Company, Module, ModuleType } from '@/api/__generated__/webApi';
import { TKeys, useTranslate } from '@/i18n/useTranslate';
import { useCompaniesState } from '@/store/companies/hooks';
import { useCallback, useMemo } from 'react';
import { CompanyPermissionName, PartialRecord } from '@/types';
import { NormalizedState } from '@/store/common/normalized';
import { SideMenuItem } from '@/features/sidebar/SidebarContainer';
import { LOCALLY_CREATED_LIS_STUB_ID } from '@/store/companies/saga/saga';
import {
  AppRoutes,
  generatePath,
  ModuleTypeToUrlsMap,
  ParamNames,
} from '@/routes/appRoutes';
import IconSVGBuilding from 'ui-kit/lib/icons/24/Menu/Buildings';
import IconSVGFlask from 'ui-kit/lib/icons/24/Menu/TestFlask';
import IconSVGPro from 'ui-kit/lib/icons/24/Menu/Stethoscope';
import IconSVGList from 'ui-kit/lib/icons/24/Menu/BoardText';
import IconSVGUserAdd from 'ui-kit/lib/icons/24/Action/UserPlus';
import { useCompanyPermissionsValidator } from '@/store/permissions/useCompanyPermissionsValidator.tsx';
import { CompanyDetailsTabId } from '@/features/company-details/CompanyDetailsTabId';
import { matchPath, useLocation } from 'react-router-dom';
import { QueuedTaskType, toSearchParams } from '@/utils/toSearchParams.tsx';

const moduleIconsMap: Record<ModuleType, JSX.Element> = {
  [ModuleType.LIS]: <IconSVGFlask />,
  [ModuleType.PROFESSIONAL]: <IconSVGPro />,
  [ModuleType.PASSPORT]: <IconSVGList />,
};
const enterModuleTitleMap: Record<ModuleType, TKeys<'profile'>> = {
  [ModuleType.LIS]: 'ENTER_TO_MODULE_LIS',
  [ModuleType.PROFESSIONAL]: 'ENTER_TO_MODULE_PROFESSIONAL',
  [ModuleType.PASSPORT]: 'ENTER_TO_MODULE_PASSPORT',
};
export const useCompaniesMenuItems = () => {
  const { t } = useTranslate('profile');
  const { companies, modules } = useCompaniesState();
  const hasPermission = useCompanyPermissionsValidator();
  const location = useLocation();
  const createCompanyMenuItems = useCallback(
    (
      company: Company,
      modules: PartialRecord<string, NormalizedState<Module, string>>,
      hasPermission: (
        permission: CompanyPermissionName,
        companyId?: string
      ) => boolean,
      collapsible: boolean,
      pathname: string
    ) => {
      const npid = company?.npi;
      const companyId = company.id;

      const adAuthEnabledButNotAuthorized = company?.locked ?? false;
      const mainItem: SideMenuItem = {
        title: company?.name ?? '',
        subtitle: npid ? t('NPI_N', { npi: npid }) : undefined,
        icon: <IconSVGBuilding />,
        link: {
          pathname: generatePath(AppRoutes.COMPANY_DETAILS, {
            [ParamNames.CompanyId]: companyId,
            [ParamNames.CompanyDetailsSection]: CompanyDetailsTabId.CompanyName,
          }),
          exact: true,
        },
        selected:
          matchPath(AppRoutes.COMPANY_DETAILS, pathname)?.params.companyId ===
          companyId,
        items: [],
        collapsible,
        id: companyId,
        buttons: [],
      };

      if (hasPermission(CompanyPermissionName.CompanyViewAllUsers, companyId)) {
        mainItem.items?.push({
          link: {
            pathname: generatePath(AppRoutes.COMPANY_USERS_CONTROL, {
              [ParamNames.CompanyId]: companyId,
            }),
          },
          title: t('USERS_CONTROL'),
          disabled: adAuthEnabledButNotAuthorized,
          icon: <IconSVGUserAdd />,
          iconStripeOnly: true,
        });
      }

      const moduleSortWeights: Record<ModuleType, number> = {
        [ModuleType.PASSPORT]: 0,
        [ModuleType.LIS]: 1,
        [ModuleType.PROFESSIONAL]: 2,
      };
      const sortedIds = [...(modules[companyId]?.allIDs ?? [])].sort(
        (aId, bId) => {
          const companyModules = modules[companyId];
          const modTypeA = companyModules?.byID[aId]?.type as ModuleType;
          const modTypeB = companyModules?.byID[bId]?.type as ModuleType;
          return moduleSortWeights[modTypeA] - moduleSortWeights[modTypeB];
        }
      );
      for (const moduleId of sortedIds) {
        const module = modules[companyId]?.byID[moduleId];
        if (!module) {
          // it is 100% - module should be there. If it is somehow missed - bad news
          console.error(
            '[useMenuItems] one of the Normalized states (company modules) is broken'
          );
          continue;
        }
        if (module.id === LOCALLY_CREATED_LIS_STUB_ID) {
          continue;
        }

        const hasAnyRoleUserAccessRightsForTheModule =
          hasPermission(CompanyPermissionName.CompanyViewAllRoles, companyId) &&
          (hasPermission(CompanyPermissionName.CompanyAssignRole, companyId) ||
            hasPermission(CompanyPermissionName.CompanyRevokeRole, companyId) ||
            hasPermission(
              CompanyPermissionName.CompanyViewAllUsers,
              companyId
            ) ||
            hasPermission(
              CompanyPermissionName.ModuleCreateCustomRole,
              companyId
            ));

        if (hasAnyRoleUserAccessRightsForTheModule) {
          // add link to roles page for the module where we can manage, roles, permissions and users
          mainItem.items?.push({
            link: {
              pathname: generatePath(AppRoutes.COMPANY_MODULES, {
                [ParamNames.CompanyId]: companyId,
                [ParamNames.Module]: ModuleTypeToUrlsMap[module.type],
                [ParamNames.ModuleId]: module.id,
              }),
            },
            title: t(module.type),
            disabled: adAuthEnabledButNotAuthorized,
            iconStripeOnly: true,
            icon: moduleIconsMap[module.type],
          });
        }

        if (module.type === ModuleType.PASSPORT) {
          continue;
        }

        // button for navigation to the module external site, like professionals portal, etc.
        mainItem.buttons.push({
          title: t(enterModuleTitleMap[module.type]),
          link: {
            pathname: '',
            search: `?${toSearchParams({ companyId, moduleType: module?.type ?? '', queuedTask: QueuedTaskType.ModuleRedirect })}`,
          },
          data: { module, companyId },
        });
      }

      return mainItem;
    },
    [t]
  );

  const companyItems = useMemo(
    () =>
      companies.allIDs.map((companyId, _i, a) => {
        const company = companies?.byID[companyId] as Company;
        return createCompanyMenuItems(
          company,
          modules,
          hasPermission,
          a.length > 1,
          location.pathname
        );
      }),
    [
      companies.allIDs,
      companies?.byID,
      createCompanyMenuItems,
      hasPermission,
      location.pathname,
      modules,
    ]
  );

  return companyItems;
};
