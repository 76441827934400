/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { CompanyPermissionName } from '@/types';
import {
  Box,
  css as _,
  Stack,
  styled,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { Button } from '@/components/Button';
import { useTranslate } from '@/i18n/useTranslate';
import IconSVGKey from 'ui-kit/lib/icons/24/Action/Key';
import IconSVGMenuPlaceholder from 'ui-kit/lib/icons/24/Text/ListBullets';
import { PermissionsKeeper } from '@/store/permissions/PermissionsKeeper';
import { useNavigate } from 'react-router-dom';
import IconPencil from 'ui-kit/lib/icons/24/Action/Edit';
import { IconButton } from '@/components/IconButton';
import cn from 'classnames';
import { UrlToModuleTypeMap, useParams } from '@/routes/appRoutes';
import { ModuleType, Role } from '@/api/__generated__/webApi';
import { ButtonProps } from 'ui-kit';
import { inheritDataTestId, INHERITED_DATA_TEST_ID } from '@/utils/testId.ts';

export const RoleItem: FC<{
  role?: Role;
  className?: string;
  sx?: SxProps<Theme>;
  onEdit?: () => void;
  disableEdit?: boolean;
}> = ({ disableEdit, onEdit, role, className, sx, ...props }) => {
  const { t } = useTranslate('companies');
  const navigate = useNavigate();
  const params = useParams();
  const moduleType = params.module
    ? UrlToModuleTypeMap[params.module]
    : undefined;
  if (!role) {
    return null;
  }

  return (
    <Box component={'section'} sx={sx} className={cn(className, 'role-item')}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        mb={{ xs: 12, sm: 12, lg: 12 }}
        gap={6}
      >
        <Typography
          variant={'16_24_700'}
          maxWidth={{ xs: 'initial', lg: 760 }}
          sx={{ wordBreak: 'break-word' }}
        >
          {role.name}
        </Typography>
        {onEdit && (
          <IconButton
            data-test-id={inheritDataTestId(props, '_button-on-edit')}
            color={'quaternary'}
            onClick={onEdit}
            disabled={disableEdit}
            size={'xs'}
          >
            <IconPencil size={18} />
          </IconButton>
        )}
      </Stack>
      <Typography
        variant={'12_18_500'}
        mb={{ xs: 24, sm: 24, lg: 18 }}
        maxWidth={{ xs: 'initial', lg: 760 }}
        display={'block'}
        sx={{ wordBreak: 'break-word' }}
      >
        {role.description}
      </Typography>
      <nav
        css={(t) => _`
          display: inline-flex;
          align-items: center; 
          gap: 44px; 
          ${t.breakpoints.only('mobile')} {
            justify-content: space-between; 
            display: flex;
            width: 100%
          }
        `}
      >
        <TextButton
          data-test-id={inheritDataTestId(props, '_button-permissions')}
          onClick={() => {
            let pathname =
              moduleType === ModuleType.LIS
                ? `./permissions/${role.id}/lims`
                : `./permissions/${role.id}`;
            if (role.editable) pathname = pathname + '/editable';
            navigate({ pathname, search: window.location.search });
          }}
          startIcon={<IconSVGKey size={18} />}
        >
          {t('PERMISSIONS')}
        </TextButton>
        <PermissionsKeeper
          show={(has) =>
            has(CompanyPermissionName.CompanyAssignRole) ||
            has(CompanyPermissionName.CompanyRevokeRole) ||
            has(CompanyPermissionName.CompanyViewAllUsers)
          }
        >
          <TextButton
            data-test-id={inheritDataTestId(props, '_button-users-list')}
            onClick={() => {
              navigate({
                pathname: `./users/${role.id}`,
                search: window.location.search,
              });
            }}
            startIcon={<IconSVGMenuPlaceholder size={18} />}
          >
            {t('USERS_LIST')}
          </TextButton>
        </PermissionsKeeper>
      </nav>
    </Box>
  );
};

const TextButton = styled((props: ButtonProps) => (
  <Button
    variant={'text'}
    color={'secondary'}
    data-test-id={INHERITED_DATA_TEST_ID}
    sx={{ paddingX: 10, marginX: -10 }}
    {...props}
  />
))({});
