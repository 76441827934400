import React, { FC } from 'react';
import { User } from '@/types';
import { useTranslate } from '@/i18n/useTranslate';
import { BoxBody, BoxHeader, Nothing, Nowrap, WhiteBox } from '@/components';
import { Box, IconButton, Stack } from '@mui/material';
import { Table, TBody, Td, Th, THead, Tr } from '@/components/Table';
import { UserName } from '@/components/UserName';
import { getName } from '@/utils/getName';
import IconSVGCheckmark from 'ui-kit/lib/icons/24/Warning/Success';
import { DotAccountStatus } from '@/features/fragments/DotAccountStatus';
import { CompanyAccountStatus } from '@/features/fragments/CompanyAccountStatus';
import { tableFullWidthPaddingsStyle } from '@/components/Table/tableFullWidthPaddingsStyle';
import { SearchControl } from 'ui-kit';
import { PhonesWithTooltip } from '@/components/PhonesDisplayWithTooltip/PhonesWithTooltip';
import { UsersListProps } from '@/features/module/users-list/fragments/TabletList';
import { testId } from '@/utils/testId.ts';
import DATA_TEST_IDS from './dataTestIds';
const styles = {
  container: { display: 'flex', gap: 24, height: '100%' },
  wBox: { minWidth: 0 },
  search: { marginBottom: 24 },
  boxMarginsX: {
    xs: -24,
    lg: -36,
  },
  table: [
    tableFullWidthPaddingsStyle,
    {
      tableLayout: 'fixed',
      '*': { fontSize: 14 },
    },
  ],
  accountNameAndStatus: {
    flexDirection: 'row',
    gap: 12,
    alignItems: 'center',
  },
};
export const DesktopList: FC<
  UsersListProps & { onSearchChange: React.ChangeEventHandler }
> = ({
  moduleUsers,
  companyUsers,
  addUser,
  removeUser,
  loadMoreCompanyUsers,
  loadMoreModuleUsers,
  onSearchChange,
  roleUsersListLabel,
  showRevokeButton,
  showAddButton,
}) => {
  const { t } = useTranslate('companies');

  const tableHead = (
    <THead>
      <Tr
        data-test-id={testId(
          DATA_TEST_IDS.fragmentsDesktopList + '_table-header-row'
        )}
      >
        <Th
          width={'40%'}
          columnId={'name'}
          data-test-id={testId(
            DATA_TEST_IDS.fragmentsDesktopList + '_table-header-name'
          )}
        >
          {t('NAME')}
        </Th>
        <Th
          data-test-id={testId(
            DATA_TEST_IDS.fragmentsDesktopList + '_table-header-phone'
          )}
        >
          {t('PHONE')}
        </Th>
        <Th
          colSpan={2}
          data-test-id={testId(
            DATA_TEST_IDS.fragmentsDesktopList + '_table-header-status'
          )}
        >
          <Nowrap>{t('COMPANY_STATUS')}</Nowrap>
        </Th>
      </Tr>
    </THead>
  );

  const renderUserNameAndStatus = (user?: User) => {
    return (
      <Stack sx={styles.accountNameAndStatus}>
        <DotAccountStatus status={user?.accountStatus} />
        <UserName
          avatarSrc={user?.avatarUrl}
          data-test-id={testId(
            DATA_TEST_IDS.fragmentsDesktopList + '_user-name'
          )}
        >
          {getName(user)}
        </UserName>
      </Stack>
    );
  };

  return (
    <Box sx={styles.container}>
      <WhiteBox sx={styles.wBox}>
        <BoxHeader mb={24}>{roleUsersListLabel ?? '...'}</BoxHeader>
        <BoxBody>
          <SearchControl
            placeholder={t('SEARCH_USERS')}
            sx={styles.search}
            name={'module'}
            onChange={onSearchChange}
            data-test-id={testId(
              DATA_TEST_IDS.fragmentsDesktopList + '_search-control'
            )}
          />
          {moduleUsers?.allIDs?.length ? (
            <Box marginX={styles.boxMarginsX}>
              <Table
                sx={styles.table}
                onLoad={loadMoreModuleUsers}
                data-test-id={testId(
                  DATA_TEST_IDS.fragmentsDesktopList + '_table1'
                )}
              >
                {tableHead}
                <TBody>
                  {moduleUsers?.allIDs?.map((id, index) => {
                    const user = moduleUsers.byID[id];
                    return (
                      <Tr
                        key={id}
                        data-test-id={testId(
                          DATA_TEST_IDS.fragmentsDesktopList +
                            '_table-row' +
                            index
                        )}
                      >
                        <Td
                          data-test-id={testId(
                            DATA_TEST_IDS.fragmentsDesktopList +
                              '_table-cell-status' +
                              index
                          )}
                        >
                          {renderUserNameAndStatus(user)}
                        </Td>
                        <Td
                          data-test-id={testId(
                            DATA_TEST_IDS.fragmentsDesktopList +
                              '_table-phones-cell' +
                              index
                          )}
                        >
                          <PhonesWithTooltip phones={user?.phones} />
                        </Td>
                        <Td
                          data-test-id={testId(
                            DATA_TEST_IDS.fragmentsDesktopList +
                              '_table-company-status-cell' +
                              index
                          )}
                        >
                          <CompanyAccountStatus
                            data-test-id={testId(
                              DATA_TEST_IDS.fragmentsDesktopList +
                                '_company-acc-status2'
                            )}
                            status={user?.accountCompanyStatus}
                            inviteExpiredTime={user?.inviteExpiredTime}
                          />
                        </Td>
                        <Td
                          sx={{ textAlign: 'right' }}
                          data-test-id={testId(
                            DATA_TEST_IDS.fragmentsDesktopList +
                              '_table-cell-actions' +
                              index
                          )}
                        >
                          {showRevokeButton(user) && (
                            <IconButton
                              onClick={() => removeUser(id)}
                              data-test-id={testId(
                                DATA_TEST_IDS.fragmentsDesktopList +
                                  '_table-button-revoke' +
                                  index
                              )}
                            >
                              <Box
                                component={IconSVGCheckmark}
                                sx={{
                                  color: (t) => t.palette.alert.success,
                                }}
                              />
                            </IconButton>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </TBody>
              </Table>
            </Box>
          ) : (
            <Nothing lh={38}>{t('NO_USERS')}</Nothing>
          )}
        </BoxBody>
      </WhiteBox>
      <WhiteBox sx={styles.wBox}>
        <BoxHeader mb={24}>{t('ALL_AVAILABLE_USERS')}</BoxHeader>
        <BoxBody>
          <SearchControl
            placeholder={t('SEARCH_USERS')}
            sx={styles.search}
            name={'company'}
            onChange={onSearchChange}
            data-test-id={testId(
              DATA_TEST_IDS.fragmentsDesktopList + '_search-control2'
            )}
          />
          {companyUsers?.allIDs?.length ? (
            <Box marginX={styles.boxMarginsX}>
              <Table
                sx={styles.table}
                onLoad={loadMoreCompanyUsers}
                data-test-id={testId(
                  DATA_TEST_IDS.fragmentsDesktopList + '_table2'
                )}
              >
                {tableHead}
                <TBody>
                  {companyUsers?.allIDs?.map((id, index) => {
                    const user = companyUsers.byID[id];
                    return (
                      <Tr
                        key={id}
                        data-test-id={testId(
                          DATA_TEST_IDS.fragmentsDesktopList +
                            '_table-row-available-items' +
                            index
                        )}
                      >
                        <Td
                          data-test-id={testId(
                            DATA_TEST_IDS.fragmentsDesktopList +
                              '_table-cell-status-available' +
                              index
                          )}
                        >
                          {renderUserNameAndStatus(user)}
                        </Td>
                        <Td
                          data-test-id={testId(
                            DATA_TEST_IDS.fragmentsDesktopList +
                              '_table-cell-phone-available' +
                              index
                          )}
                        >
                          <PhonesWithTooltip phones={user?.phones} />
                        </Td>
                        <Td
                          data-test-id={testId(
                            DATA_TEST_IDS.fragmentsDesktopList +
                              '_table-cell-company-status-available' +
                              index
                          )}
                        >
                          <CompanyAccountStatus
                            data-test-id={testId(
                              DATA_TEST_IDS.fragmentsDesktopList +
                                '_company-acc-status3'
                            )}
                            status={user?.accountCompanyStatus}
                            inviteExpiredTime={user?.inviteExpiredTime}
                          />
                        </Td>
                        <Td
                          sx={{ textAlign: 'right' }}
                          data-test-id={testId(
                            DATA_TEST_IDS.fragmentsDesktopList +
                              '_table-cell-actions-available' +
                              index
                          )}
                        >
                          {showAddButton(user) && (
                            <IconButton
                              onClick={() => addUser(id)}
                              data-test-id={testId(
                                DATA_TEST_IDS.fragmentsDesktopList +
                                  '_table-button-add' +
                                  index
                              )}
                            >
                              <Box
                                component={IconSVGCheckmark}
                                sx={{
                                  color: (t) => t.palette.grey[400],
                                }}
                              />
                            </IconButton>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </TBody>
              </Table>
            </Box>
          ) : (
            <Nothing lh={38}>{t('NO_USERS')}</Nothing>
          )}
        </BoxBody>
      </WhiteBox>
    </Box>
  );
};
