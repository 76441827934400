import { useCompaniesState } from './useCompaniesState';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { companiesActions as actions, FormNames } from '../slice';
import { useParams } from '@/routes/appRoutes';
import { AddAddressesForm } from '@/features/company-addresses/AddAddressDialog';
import { StateType } from '@/api/__generated__/webApi';
import { useTranslatedErrors } from '@/hooks/useConnectServerValidationErrors';
import { ValidationErrorType } from '@/types/ValidationError.ts';
export const useCreateAddressForm = () => {
  const { forms } = useCompaniesState();

  const params = useParams();
  const theForm = forms[FormNames.CreateAddress];
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      resetErrors() {
        dispatch(actions.resetErrors());
        dispatch(
          actions.resetFormErrors({ formName: FormNames.CreateAddress })
        );
      },
      openDialog() {
        if (theForm?.state === 'done') {
          dispatch(actions.resetForm({ formName: FormNames.CreateAddress }));
        }
        dispatch(
          actions.setFormState({
            formName: FormNames.CreateAddress,
            formState: 'active',
          })
        );
      },
      closeDialog() {
        dispatch(
          actions.setFormState({
            formName: FormNames.CreateAddress,
            formState: null,
          })
        );
        dispatch(
          actions.resetFormErrors({ formName: FormNames.CreateAddress })
        );
      },
      submit(form: AddAddressesForm) {
        if (!params.companyId) {
          return;
        }
        dispatch(
          actions.requestCreateAddress({
            companyId: params.companyId,
            address: {
              address1: form.address1,
              address2: form.address2,
              state: form.state as StateType,
              city: form.city,
              cityReplacement: form.cityReplacement,
              postalCode: form.postalCode,
              telephoneNumber: form.telephoneNumber,
              faxNumber: form.faxNumber,
              endDate: undefined,
              invoicesReceiving: form.invoicesReceiving,
              patientBillType: form.patientBillType,
              clientBillType: form.clientBillType,
              insuranceBillType: form.insuranceBillType,
              location: {
                latitude: form.latitude as number,
                longitude: form.longitude as number,
                googlePlaceId: form.googlePlaceId as string,
              },
            },
          })
        );
      },
    };
  }, [dispatch, params.companyId, theForm?.state]);

  const serverTranslatedErrors = useTranslatedErrors<
    Record<string, ValidationErrorType>
  >(theForm?.errors);

  return {
    dialogOpened: theForm?.state && theForm.state !== 'done',
    fetching: theForm?.state === 'sending',
    errors: serverTranslatedErrors,
    ...callbacks,
  };
};
