import React, { ReactNode, useRef, useState } from 'react';
import { useBoxBreakpoints } from 'ui-kit';
import { CONTENT_BOX_ID } from '@/features/layouts/MainLayout/MainLayout.tsx';
import { WhiteBox } from '@/components';
import { Stack, Theme } from '@mui/material';
import { HorizontalResizer } from '@/features/module/LIMS/Resizer';

export interface LayoutProps {
  leftColumnContent: ReactNode;
  rightColumnContent: ReactNode;
}

export const WhiteBoxLayout = ({
  leftColumnContent,
  rightColumnContent,
}: LayoutProps) => {
  const leftColRef = useRef<HTMLDivElement>(null);
  const rightColRef = useRef<HTMLDivElement>(null);
  const containerBreakpoints = useBoxBreakpoints(CONTENT_BOX_ID, ['md']);

  const [widthLeftCol, setWidthForLeftCol] = useState<undefined | number>(
    undefined
  );
  const handleResize = (w: number) => {
    setWidthForLeftCol(w);
  };
  return (
    <WhiteBox sx={styles.container}>
      <Stack
        sx={[
          styles.leftColumn,
          {
            width: containerBreakpoints.md ? widthLeftCol : '100%!important',
          },
        ]}
        ref={leftColRef}
      >
        {leftColumnContent}
        {containerBreakpoints.md && (
          <HorizontalResizer
            parentRef={leftColRef}
            adjacentRef={rightColRef}
            setWidth={handleResize}
            min={480}
            minAdjacent={600}
          />
        )}
      </Stack>
      {containerBreakpoints.md && (
        <Stack sx={styles.rightColumn} ref={rightColRef}>
          {rightColumnContent}
        </Stack>
      )}
    </WhiteBox>
  );
};
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%!important',
    padding: '0!important',
    minHeight: '300px',
    '*': { userSelect: 'none' },
  },
  leftColumn: (t: Theme) => ({
    width: 480,
    flexShrink: 0,
    borderRight: `1px solid ${t.palette.secondary[300] ?? ''}`,
    position: 'relative',
  }),
  rightColumn: () => ({
    width: '100%',
    minWidth: '200px',
  }),
};
