import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Fade, Stack } from '@mui/material';

export const AnimatedSkeletonSvg: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [shown, setShown] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShown(true);
    }, 0);
  }, []);

  return (
    <Fade in={shown} appear={true} easing={'ease-in'} timeout={200}>
      <Stack sx={styles.animatedSkeleton}>{children}</Stack>
    </Fade>
  );
};
const styles = {
  animatedSkeleton: {
    width: '100%',
    svg: { width: '100%' },
    rect: {
      fill: 'currentColor',
    },
    animationName: 'glow',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    '@keyframes glow': {
      from: {
        color: 'secondary.200',
      },
      to: {
        color: 'secondary.200',
      },
    },
  },
};
