import React, { FC, PropsWithChildren } from 'react';
import { Stack } from '@mui/material';

export const TabPanel: FC<
  PropsWithChildren<{
    value: number | string;
    selected: number | string;
    keepMounted?: boolean;
  }>
> = ({ value, selected, children, keepMounted }) => {
  const shown = value === selected;
  if (!shown && !keepMounted) {
    return null;
  }
  if (keepMounted) {
    return <Stack sx={{ display: shown ? 'flex' : 'none' }}>{children}</Stack>;
  }
  return <>{children}</>;
};
