import { Button } from '@/components/Button';
import { FormikProps } from 'formik';
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import * as yup from 'yup';
import { passwordValidator } from '@/features/authentication/common/validators';
import { useInit } from '@/hooks/useInit';
import { useChangePasswordRequest } from '@/store/profile/apiHooks';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@/routes/appRoutes';
import { RecaptchaNote } from '@/components/RecaptchaNote';
import { Stack, Typography } from '@mui/material';
import { Notification } from '@/components/Notification';
import { ButtonsLayout } from '@/features/profile/phones-and-emails/components/ButtonsLayout';
import { useBreakpoints } from 'ui-kit';
import { FormikRedux } from '@/components';
import { InputControlF } from '@/components/FormikRedux';
import { ValidationErrorType } from '@/types/ValidationError';

import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

const useValidationSchema = () => {
  const { t } = useTranslate('common');
  const { t: tr } = useTranslate('password');
  return useMemo(() => {
    return yup.object().shape({
      currentPassword: yup.string().required(t('FIELD_IS_REQUIRED')),
      newPassword: passwordValidator(tr),
      confirmPassword: yup
        .string()
        .required(t('PLEASE_CONFIRM_PASSWORD'))
        .oneOf([yup.ref('newPassword')], t('PASSWORDS_DO_NOT_MATCH')),
    });
  }, [t, tr]);
};

interface PasswordFormState {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
const initialState = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};
export const PasswordForm: FC<{ next: () => void }> = ({ next }) => {
  const { t } = useTranslate('profile');
  const schema = useValidationSchema();
  const { sendData, validationErrors, fetching, responseData, reset } =
    useChangePasswordRequest();
  const formikRef = useRef<FormikProps<PasswordFormState> | null>(null);
  const { xsOnly, smOnly } = useBreakpoints();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: PasswordFormState) => {
      sendData(values);
    },
    [sendData]
  );

  useInit(() => {
    reset();
  });

  // set formik fetching false if fetching state changed from true to false
  useEffect(() => {
    const f = fetching;
    return () => {
      if (f) formikRef.current?.setSubmitting(false);
    };
  }, [fetching]);

  useEffect(() => {
    if (responseData?.resendTime) {
      next();
    }
  }, [responseData?.resendTime, next, reset]);

  const serverErrors = useMemo(() => {
    if (
      validationErrors?.error === ValidationErrorType.CURRENT_PASSWORD_INCORRECT
    ) {
      return {
        currentPassword: validationErrors?.error,
        ...validationErrors.fieldErrors,
      } as Record<keyof PasswordFormState, ValidationErrorType>;
    }
    return validationErrors?.fieldErrors ?? {};
  }, [validationErrors?.error, validationErrors?.fieldErrors]);

  return (
    <>
      <FormikRedux<PasswordFormState>
        innerRef={(f) => (formikRef.current = f)}
        initialValues={initialState}
        onSubmit={handleSubmit}
        validationSchema={schema}
        serverErrors={serverErrors}
        resetServerErrors={() => {}}
        noForm
      >
        {(f) => {
          return (
            <>
              <Stack sx={{ flex: '1 1 auto' }}>
                <Stack>
                  <Typography
                    mb={48}
                    sx={{
                      fontSize: { sm: 14 },
                      lineHeight: { sm: '20px' },
                      mb: { xs: 48, lg: 60 },
                      typography: {
                        xs: '12_18_500',
                        sm: '14_20_500',
                        lg: '16_24_500',
                      },
                    }}
                  >
                    {t(
                      'YOU_CAN_CHANGE_YOUR_PASSWORD_TO_DO_THIS_ENTER_YOUR_CURRENT_PASSWORD_AND_YOUR_NEW_PASSWORD'
                    )}
                    <br />
                    {t('AFTER_CHANGING_THE_PASSWORD_THE_ACCOUNT_WILL_BE_')}
                  </Typography>

                  <Stack
                    sx={{
                      maxWidth: { xs: 'auto', sm: 480, lg: 480 },
                      gap: { xs: 48 },
                    }}
                    component={'form'}
                    id={'password_change_form'}
                    onSubmit={f.handleSubmit}
                  >
                    <InputControlF
                      data-test-id={testId(
                        DATA_TEST_IDS.fragmentsPasswordForm +
                          '_input-control-password'
                      )}
                      label={t('CURRENT_PASSWORD')}
                      type="password"
                      placeholder={t('ENTER_YOUR_OLD_PASSWORD')}
                      name="currentPassword"
                      ignoreLabelHeight
                    />
                    <InputControlF
                      data-test-id={testId(
                        DATA_TEST_IDS.fragmentsPasswordForm +
                          '_input-control-new-password'
                      )}
                      label={t('NEW_PASSWORD')}
                      type="password"
                      placeholder={t('ENTER_YOUR_NEW_PASSWORD')}
                      name="newPassword"
                      ignoreLabelHeight
                    />
                    <InputControlF
                      data-test-id={testId(
                        DATA_TEST_IDS.fragmentsPasswordForm +
                          '_input-control-password-confirm'
                      )}
                      label={t('NEW_PASSWORD_CONFIRMATION')}
                      type="password"
                      placeholder={t('RETYPE_YOUR_NEW_PASSWORD')}
                      name="confirmPassword"
                      ignoreLabelHeight
                    />
                  </Stack>
                  <Notification
                    type={'warning'}
                    sx={{
                      mt: { xs: 36, sm: 48, lg: 48 },
                      maxWidth: { xs: 'auto', sm: 480, lg: 480 },
                    }}
                  >
                    {t('PLEASE_CREATE_A_STRONG_PASSWORD_')}
                  </Notification>
                </Stack>
                <ButtonsLayout>
                  {smOnly && (
                    <Button
                      data-test-id={testId(
                        DATA_TEST_IDS.fragmentsPasswordForm + '_button-cancel'
                      )}
                      color={'primary'}
                      variant={'outlined'}
                      onClick={() => navigate(AppRoutes.PROFILE)}
                    >
                      {t('CANCEL')}
                    </Button>
                  )}
                  <Button
                    data-test-id={testId(
                      DATA_TEST_IDS.fragmentsPasswordForm + '_button-send'
                    )}
                    type="submit"
                    color="primary"
                    disabled={f.isSubmitting}
                    form="password_change_form"
                    fullWidth={xsOnly}
                  >
                    {t('SEND')}
                  </Button>
                </ButtonsLayout>
                <RecaptchaNote
                  mt={{ xs: 27, sm: 36, lg: 24 }}
                  mb={{ xs: 0, sm: 20 }}
                />
              </Stack>
            </>
          );
        }}
      </FormikRedux>
    </>
  );
};
