import React, { forwardRef, memo, useCallback, useRef } from 'react';
import { LinkPath } from '@/features/module/LIMS/LinkPath';
import { Tag, useBreakpoints } from 'ui-kit';
import { Stack } from '@mui/material';
import { UUID } from '@/types';
import { TreePath } from '@/features/module/LIMS/utils/getPath';
import { inheritDataTestId } from '@/utils/testId.ts';
interface BranchProps {
  links: TreePath[];
  tags: TreePath[];
  onClick?: (uuid: UUID) => void;
}
const PermissionsBranch_ = forwardRef<HTMLDivElement, BranchProps>(
  ({ links, tags, onClick, ...props }, ref) => {
    const b = useBreakpoints();
    const clickRef = useRef(onClick);
    clickRef.current = onClick;
    const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
      clickRef.current?.(e.currentTarget.id);
    }, []);
    const renderedTags = tags.map((d, i) => {
      return (
        <Tag
          data-test-id={inheritDataTestId(props, `tag-chain_${i}`)}
          label={d.label}
          size={'sm'}
          key={d.label}
          color={'grey'}
          id={d.id}
          onClick={handleClick}
        />
      );
    });
    const tabletLandscape = b.mdOnly || b.lgOnly;

    return (
      <Stack direction={'row'} sx={styles.container} ref={ref}>
        <LinkPath
          data-test-id={inheritDataTestId(props, '_link-path')}
          links={links}
          onClick={onClick}
        />
        {tabletLandscape ? (
          <Stack
            sx={{
              minWidth: 0,
              gap: { xs: 6, md: 9 },
              width: '100%',
              flexWrap: 'wrap',
            }}
            direction={'row'}
          >
            {renderedTags}
          </Stack>
        ) : (
          renderedTags
        )}
      </Stack>
    );
  }
);
export const PermissionsBranch = memo(PermissionsBranch_);

const styles = {
  container: {
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: { xs: 6, md: 9 },
  },
};
