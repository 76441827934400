const DATA_TEST_IDS = {
  companyAddressesAddAddressDialog: 'company-addresses__add-address-dialog',
  companyAddressesBillTypesControls: 'company-addresses__bill-types-controls',
  companyAddressesEditAddressDialog: 'company-addresses__edit-address-dialog',
  companyAddressesNpiPracticeAddresses:
    'company-addresses__npi-practice-addresses',
  companyAddressesAddressLookupFormField:
    'company-addresses__address-lookup-form-field',
} as const;

export default DATA_TEST_IDS;
