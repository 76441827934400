import React, { FC } from 'react';
import { UUID } from '@/types';
import { Stack } from '@mui/material';
import { PermissionsBranch } from '@/features/module/LIMS/fragments/PermissionsBranch';
import { TreePath } from '@/features/module/LIMS/utils/getPath';
import { Nothing } from '@/components';

import { useTranslate } from '@/i18n/useTranslate';

export const TabContent: FC<{
  onClick?: (uuid: UUID) => void;
  data: Array<{ permissions: TreePath[]; groups: TreePath[] }>;
}> = ({ data, onClick }) => {
  const { t } = useTranslate('module');
  return (
    <Stack gap={18}>
      {data.length ? (
        data.map((d, i) => {
          return (
            <PermissionsBranch
              key={i}
              links={d.groups}
              tags={d.permissions}
              onClick={onClick}
            />
          );
        })
      ) : (
        <Nothing>{t('NO_PERMISSIONS_WERE_SELECTED')}</Nothing>
      )}
    </Stack>
  );
};
