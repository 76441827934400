import { UrlParams } from '@/routes/appRoutes.ts';
import { useAppSelector } from '@/store/store.ts';
import { ModuleState } from '@/store/module/slice.ts';

const dummy: string[] = [];
const dummyObj = {};
export const usePermissionsSelector = ({
  companyId,
  moduleId,
  roleId,
}: Partial<UrlParams>) => {
  const {
    limsPermissionsTree,
    selected,
    preSelected,
    permissions,
    initializedFor,
    groups,
  } = useAppSelector((r) => r.module);

  const initialized =
    companyId === initializedFor?.companyId &&
    moduleId === initializedFor?.moduleId &&
    roleId === initializedFor?.roleId &&
    !!preSelected;

  return {
    limsPermissionsTree,
    initLoading: !initialized,
    selected: initialized ? selected : dummy,
    groups: initialized ? groups : (dummyObj as ModuleState['groups']),
    preSelected: initialized ? preSelected : undefined,
    permissions: initialized ? permissions : dummyObj,
  };
};
