import React, { FC } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { Button, useBreakpoints } from 'ui-kit';
import { useUpdateAddress } from '@/features/profile-address/store/hooks';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@/components';
import { Stack } from '@mui/material';
import { Form } from 'formik';
import { AddressLookup } from '@/components/Google/AddressLookup';
import { InputControlF } from '@/components/FormikRedux';
import { FormLogic } from '@/features/profile-address/fragments/UpdateAddressDialog/FormLogic';

import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

export const UpdateAddressDialog: FC = () => {
  const { t } = useTranslate('profile');
  const b = useBreakpoints();
  const d = useUpdateAddress();
  return (
    <Dialog
      data-test-id={testId(
        DATA_TEST_IDS.updateAddressDialogUpdateAddressDialog +
          '_dialog-update-address'
      )}
      open={d.isOpen}
      keepMounted={false}
      size={b.xsOnly ? 'sm' : b.lg ? 'xxl' : 'xl'}
      onClose={d.dismiss}
    >
      <FormLogic>
        {(formikProps, fullAddressError, faHandleChange) => {
          const { initialValues, isSubmitting } = formikProps;
          return (
            <>
              <DialogTitle
                data-test-id={testId(
                  DATA_TEST_IDS.updateAddressDialogUpdateAddressDialog +
                    '_dialog-title-update-address'
                )}
                onClose={d.dismiss}
              >
                {d.edit ? t('EDIT_ADDRESS') : t('ADD_ADDRESS')}
              </DialogTitle>
              <DialogContent>
                <Stack component={Form} gap={24} id={'add_address_form'}>
                  <AddressLookup
                    data-test-id={testId(
                      DATA_TEST_IDS.updateAddressDialogUpdateAddressDialog +
                        '_address-lookup'
                    )}
                    error={fullAddressError}
                    placeholder={t('ENTER_YOUR_PERSONAL_ADDRESS')}
                    initialValue={{
                      googlePlaceId:
                        initialValues.fullAddress?.googlePlaceId ?? undefined,
                      addressString: initialValues.fullAddress?.googlePlaceId
                        ? undefined
                        : initialValues.fullAddress?.formattedAddress,
                    }}
                    onChange={faHandleChange}
                  />
                  <InputControlF
                    data-test-id={testId(
                      DATA_TEST_IDS.updateAddressDialogUpdateAddressDialog +
                        '_input-control-address2'
                    )}
                    optional
                    name={'address2'}
                    placeholder={t('ENTER_APARTMENT_SUITE_OR_UNIT')}
                    max={55}
                  />
                </Stack>
              </DialogContent>
              <DialogActions spaceBetween={b.sm}>
                <Button
                  data-test-id={testId(
                    DATA_TEST_IDS.updateAddressDialogUpdateAddressDialog +
                      '_button-cancel'
                  )}
                  color={'secondary'}
                  variant={'outlined'}
                  onClick={d.dismiss}
                  disabled={isSubmitting}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  data-test-id={testId(
                    DATA_TEST_IDS.updateAddressDialogUpdateAddressDialog +
                      '_button-save'
                  )}
                  color={'secondary'}
                  type={'submit'}
                  form={'add_address_form'}
                  disabled={isSubmitting}
                >
                  {t('SAVE')}
                </Button>
              </DialogActions>
            </>
          );
        }}
      </FormLogic>
    </Dialog>
  );
};
