import React, { FC } from 'react';
import { Link } from 'ui-kit';
import LinkPathArrow from 'ui-kit/lib/icons/24/Arrow/ChevronRight';
import { UUID } from '@/types';
import { inheritDataTestId } from '@/utils/testId.ts';
interface LinkPathProps {
  links: Array<{ label: string; disabled?: boolean; id: UUID }>;
  size?: Parameters<typeof Link>[0]['size'];
  onClick?: (uuid: UUID) => void;
}

export const LinkPath: FC<LinkPathProps> = ({
  links,
  size,
  onClick,
  ...props
}) => {
  return (
    <>
      {links.map((d, i) => {
        return (
          <Link
            data-test-id={inheritDataTestId(props, '_link-path-chain' + i)}
            size={size}
            color={'secondary'}
            key={d.label}
            sx={{ whiteSpace: 'nowrap' }}
            disabled={d.disabled}
            to={''}
            onClick={() => onClick?.(d.id)}
            iconRight={d.disabled ? undefined : <LinkPathArrow />}
          >
            {d.label}
          </Link>
        );
      })}
    </>
  );
};
