import React, { FC } from 'react';
import { ContactAddress } from '@/api/__generated__/webApi';
import { useTranslate } from '@/i18n/useTranslate';
import { Button, useBreakpoints } from 'ui-kit';
import { BoxHeader, WhiteBox } from '@/components';
import { Stack } from '@mui/material';
import { styles } from './styles';
import { Dl } from '@/components/Dl';
import { EMPTY } from '@/constants';
import { AlertText } from '../AlertText';
import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

export const NotGeoValidatedAddressView: FC<{
  address: ContactAddress;
  onDelete: () => void;
  onChange: () => void;
}> = ({ address, onDelete, onChange }) => {
  const { t } = useTranslate('profile');
  const b = useBreakpoints();
  return (
    <WhiteBox>
      {b.sm ? (
        <>
          <BoxHeader>{t('CONTACT_ADDRESS')}</BoxHeader>
          <Stack sx={[styles.noLocationContainer, { mb: 36 }]}>
            <Stack sx={styles.col}>
              <Dl>
                <dt>{t('ADDRESS_1')}</dt>
                <dd>{address?.main || EMPTY}</dd>
              </Dl>
              <Dl>
                <dt>{t('ADDRESS_2')}</dt>
                <dd>{address?.additional || EMPTY}</dd>
              </Dl>
            </Stack>
            <Stack sx={styles.col}>
              <Dl>
                <dt>{t('CITY')}</dt>
                <dd>{address?.city || EMPTY}</dd>
              </Dl>
              <Dl>
                <dt>{t('STATE')}</dt>
                <dd>{address?.state || EMPTY}</dd>
              </Dl>
            </Stack>
            <Stack sx={styles.col}>
              <Dl>
                <dt>{t('POSTAL_CODE')}</dt>
                <dd>{address?.postalCode || EMPTY}</dd>
              </Dl>
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack sx={[styles.locationContainer]}>
          <Stack sx={{ flex: '1 1 auto' }}>
            <BoxHeader>{t('CONTACT_ADDRESS')}</BoxHeader>
            <Dl>
              <dt>{t('ADDRESS_1')}</dt>
              <dd>{address?.main || EMPTY}</dd>
            </Dl>
            <Dl>
              <dt>{t('ADDRESS_2')}</dt>
              <dd>{address?.additional || EMPTY}</dd>
            </Dl>
            <Dl>
              <dt>{t('CITY')}</dt>
              <dd>{address?.city || EMPTY}</dd>
            </Dl>
            <Dl>
              <dt>{t('STATE')}</dt>
              <dd>{address?.state || EMPTY}</dd>
            </Dl>
            <Dl>
              <dt>{t('POSTAL_CODE')}</dt>
              <dd>{address?.postalCode || EMPTY}</dd>
            </Dl>
          </Stack>
        </Stack>
      )}
      <Stack
        gap={24}
        direction={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <Stack
          sx={{
            flexGrow: 1,
            justifyContent: 'end',
          }}
        >
          <AlertText>{t('YOU_CAN_HAVE_ONLY_ONE_ADDRESS')}</AlertText>
        </Stack>
        <Stack direction={'row'} gap={12}>
          <Button
            data-test-id={testId(
              DATA_TEST_IDS.notGeoValidatedAddressView + '_button-edit'
            )}
            fullWidth
            onClick={onChange}
          >
            {t('EDIT')}
          </Button>
          <Button
            data-test-id={testId(
              DATA_TEST_IDS.notGeoValidatedAddressView + '_button-delete'
            )}
            fullWidth
            variant={'outlined'}
            onClick={onDelete}
          >
            {t('DELETE')}
          </Button>
        </Stack>
      </Stack>
    </WhiteBox>
  );
};
