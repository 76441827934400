import { all, call, put, select, takeLeading } from 'redux-saga/effects';
import { accountApi as api } from '@/api';
import { getErrors } from '@/store/common/error-handlers';
import { emailsStateActions as actions, EmailsState, Fetching } from './slice';
import { SagaPayload, SagaReturn } from '@/store/common/types';
import { RootState } from '@/store/store';
import { AccountDetails } from '@/types';
import { selectProfileData } from '@/store/phones/sagas';
import { produce } from 'immer';
import { getRecaptchaToken } from '@/hooks/useRecaptcha';
import { profileActions } from '@/store/profile/slice';
import { Email } from '@/api/__generated__/webApi';
import { showError500OrUnknownToast } from '@/store/common/showError500OrUnknownToast';
import { ValidationErrorType } from '@/types/ValidationError';
import { client } from '@/api/client/ApiClient';
export function* emailsSaga() {
  yield all([
    takeLeading(actions.requestAllEmails, requestAllEmails),
    takeLeading(actions.requestCreateEmail, requestCreateEmail),
    takeLeading(actions.requestDeleteEmail, requestDeleteEmail),
    takeLeading(
      actions.requestAllowResultsDelivery,
      requestAllowResultsDelivery
    ),
    takeLeading(actions.requestMakeAsMain, requestMakeAsMain),
    takeLeading(
      actions.requestResendConfirmationEmail,
      resendConfirmationEmail
    ),
  ]);
}
type Action<T extends keyof typeof actions> = SagaPayload<(typeof actions)[T]>;

function* resendConfirmationEmail({
  payload,
}: Action<'requestResendConfirmationEmail'>): SagaReturn {
  const state: EmailsState = yield select((s: RootState) => s.emails);
  try {
    const recaptcha: Awaited<ReturnType<typeof getRecaptchaToken>> =
      yield call(getRecaptchaToken);
    const {
      data: { resendTime },
    }: Awaited<ReturnType<typeof client.sendConfirmationAccountContactEmail>> =
      yield call(client.sendConfirmationAccountContactEmail, payload.emailId, {
        recaptcha,
      });
    yield put(
      actions.setResendEmailTime({ emailId: payload.emailId, time: resendTime })
    );
    yield put(
      actions.setFormState({
        email: state.emails?.byID[payload.emailId]?.email,
        state: 'success',
      })
    );
  } catch (e) {
    showError500OrUnknownToast(e);
  }
}
function* requestAllEmails(): SagaReturn {
  try {
    yield put(actions.setIsFetching({ fetching: true }));
    const emails: Awaited<ReturnType<typeof api.getAllEmails>> = yield call(
      api.getAllEmails
    );
    yield put(actions.setEmails({ emails, replace: true }));
  } catch (e) {
    const errors = getErrors(e);
    yield put(actions.setErrors(errors));
  } finally {
    yield put(actions.setIsFetching({ fetching: false }));
  }
}

function* requestCreateEmail({
  payload,
}: Action<'requestCreateEmail'>): SagaReturn {
  try {
    yield put(
      actions.setIsFetching({ fetching: true, type: Fetching.CreateEmail })
    );
    const result: Awaited<ReturnType<typeof api.createEmail>> = yield call(
      api.createEmail,
      payload.email
    );
    yield put(actions.setEmails({ emails: [result] }));
    yield put(actions.setFormState({ email: payload.email, state: 'success' }));
  } catch (e) {
    const errors = getErrors(e);
    if (errors.common) {
      errors.email = errors.common;
      delete errors.common;
    }
    if (errors.email === ValidationErrorType.EMAIL_EXISTS) {
      errors.email =
        'THIS_EMAIL_IS_ALREADY_REGISTERED_IN_THE_SYSTEM' as ValidationErrorType;
    }
    yield put(actions.setErrors(errors));
  } finally {
    yield put(
      actions.setIsFetching({ fetching: false, type: Fetching.CreateEmail })
    );
  }
}

function* requestDeleteEmail({
  payload,
}: Action<'requestDeleteEmail'>): SagaReturn {
  const { emails }: EmailsState = yield select(
    (root: RootState) => root.emails
  );
  try {
    yield put(actions.setIsFetching({ fetching: true }));
    yield put(actions.deleteEmails({ emailIds: [payload.emailId] }));
    yield call(api.deleteEmail, payload.emailId);
  } catch (e) {
    yield put(actions.restoreEmails(emails));
    const errors = getErrors(e);
    yield put(actions.setErrors(errors));
  } finally {
    yield put(actions.setIsFetching({ fetching: false }));
  }
}

function* requestAllowResultsDelivery({
  payload,
}: Action<'requestAllowResultsDelivery'>): SagaReturn {
  const { emails }: EmailsState = yield select(
    (root: RootState) => root.emails
  );
  const email = emails.byID[payload.emailId] as NonNullable<Email>;
  const newEmail = { ...email };
  newEmail.allowResultsDelivery = payload.allow;
  yield put(actions.setEmails({ emails: [newEmail] }));
  try {
    yield put(actions.setIsFetching({ fetching: true }));
    yield call(api.allowResultsDeliveryEmail, payload.emailId, payload.allow);
  } catch (e) {
    yield put(actions.restoreEmails(emails));
    const errors = getErrors(e);
    yield put(actions.setErrors(errors));
  } finally {
    yield put(actions.setIsFetching({ fetching: false }));
  }
}

function* requestMakeAsMain({
  payload,
}: Action<'requestMakeAsMain'>): SagaReturn {
  const { emails }: EmailsState = yield select(
    (root: RootState) => root.emails
  );
  const email = emails.byID[payload.emailId] as NonNullable<Email>;
  const newEmail = { ...email };
  newEmail.main = true;
  const emailsToUpdate = [newEmail];
  const oldMainId = emails.allIDs.find((id) => emails.byID[id]?.main);
  if (oldMainId) {
    const newOldEmail = { ...emails.byID[oldMainId] } as NonNullable<Email>;
    newOldEmail.main = false;
    emailsToUpdate.push(newOldEmail);
  }
  if (oldMainId === payload.emailId) {
    return;
  }
  yield put(actions.setEmails({ emails: emailsToUpdate }));

  try {
    yield put(actions.setIsFetching({ fetching: true }));
    const result: Awaited<ReturnType<typeof api.makeEmailAsMain>> = yield call(
      api.makeEmailAsMain,
      payload.emailId
    );
    /// replace in profile if successful
    const profile: AccountDetails = yield select(selectProfileData);
    if (profile) {
      const data = produce(profile, (draft) => {
        draft.email = result.email;
      });
      yield put(profileActions.setAccountDetails(data));
    }
  } catch (e) {
    yield put(actions.restoreEmails(emails));
    const errors = getErrors(e);
    yield put(actions.setErrors(errors));
  } finally {
    yield put(actions.setIsFetching({ fetching: false }));
  }
}
