import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import AlertIcon from 'ui-kit/lib/icons/24/Warning/Info';
import { styles } from './styles';
export const AlertText: FC<{ children: string | undefined }> = ({
  children,
}) => {
  return (
    <Stack direction={'row'} gap={10} alignItems={'center'} color={'grey.500'}>
      <AlertIcon size={18} />
      <Typography sx={styles.text} color={'inherit'}>
        {children}
      </Typography>
    </Stack>
  );
};
