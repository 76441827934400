import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  Theme,
} from '@mui/material';
import React, { memo } from 'react';
import { spreadSx } from '@/utils/spreadSx';
import { inheritDataTestId, INHERITED_DATA_TEST_ID } from '@/utils/testId.ts';

const styles = {
  checkbox: (t: Theme) => ({
    padding: '5px',
    marginRight: '5px',
    '&.Mui-disabled': {
      rect: {
        stroke: `${t.palette.field.border.disabled}!important`,
      },
      '&.Mui-checked': {
        rect: {
          fill: t.palette.grey[400],
        },
      },
    },
    '.Mui-error &': {
      rect: {
        stroke: t.palette.error.main,
      },
    },
  }),
  formControl: (t: Theme) => ({
    marginLeft: -5,
    '&.Mui-disabled .MuiFormControlLabel-label': {
      color: `${t.palette.grey[400]} !important`,
    },
  }),
};

export interface CheckboxControlProps
  extends Omit<FormControlLabelProps, 'control' | 'onChange'> {
  value?: boolean | undefined | null;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}
export const CheckboxControl = memo(
  ({ value, onChange, sx, ...restProps }: CheckboxControlProps) => {
    return (
      <FormControlLabel
        data-test-id={INHERITED_DATA_TEST_ID}
        sx={[styles.formControl, ...spreadSx(sx)]}
        {...restProps}
        control={
          <Checkbox
            color="secondary"
            sx={styles.checkbox}
            checked={value ?? undefined}
            onChange={onChange}
            data-test-id={inheritDataTestId(restProps, 'checkbox')}
          />
        }
      />
    );
  }
);

CheckboxControl.displayName = 'CheckboxControl';
