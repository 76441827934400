import { useInit } from '@/hooks/useInit';
import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import {
  QueryData,
  QueryState,
  Tag,
  _clearData,
  _mutationQuery,
  _query,
} from './slice';
import { RequiredAny } from 'ui-kit/lib/types';
interface QueryParams {
  path: string;
  tag?: string;
}

/**
 * @deprecated
 * use separate reducers instead
 */
export const useQuery = (
  { path, tag }: QueryParams,
  active = false
): QueryData | undefined => {
  const queryState = useSelector<RootState, QueryState>(
    (state) => state.queryState
  );
  const data = queryState?.requests?.[path];
  const dispatch = useDispatch();
  useInit(() => {
    if (data && !active) {
      return;
    }
    dispatch(_query({ path, method: 'GET', tag }));
  });
  return queryState?.requests?.[path];
};
export interface MutationQueryParams {
  path: string;
  method: 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  tag?: Tag;
  invalidateTags?: Tag[];
  // Explanation of usage of RequiredAny: Old legacy should be replaced with Toolkit thunks / RTK
  // TODO #1 [tech-debt] Replace it with the rtk query or just async thunks
  replace?: {
    in: Tag;
    replacer?: (oldData: RequiredAny, newData: RequiredAny) => RequiredAny;
  };
}
/**
 * @deprecated
 * use separate reducers instead
 */
export const useMutationQuery = <T, D = RequiredAny>(
  params: MutationQueryParams
) => {
  const queryState = useSelector<RootState, QueryState<D>>(
    (state) => state.queryState
  );
  const data = queryState.requests?.[params.path];
  const dispatch = useDispatch();
  const paramRef = useRef(params);
  paramRef.current = params;

  const sendData = useCallback(
    (requestData?: T) => {
      if (!requestData) {
        if (params.tag && queryState.tags[params.tag]) {
          const p = queryState.tags[params.tag];
          if ('requestData' in p) {
            requestData = p.requestData;
          }
        }
      }

      const { path, method, invalidateTags, replace, tag } = paramRef.current;

      dispatch(
        _mutationQuery({
          path,
          method,
          invalidateTags,
          requestData,
          replace,
          tag,
        })
      );
    },
    [dispatch, params.tag, queryState.tags]
  );

  const reset = useCallback(() => {
    dispatch(_clearData({ path: paramRef.current.path }));
  }, [dispatch]);

  return {
    sendData,
    fetching: data?.fetching,
    success: data?.success,
    validationErrors: data?.validationErrors,
    responseData: data?.responseData,
    reset,
  };
};
