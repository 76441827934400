import { useTranslate } from '@/i18n/useTranslate';
import React, { FC, ReactNode, useMemo } from 'react';
import * as yup from 'yup';
import { FormikProps } from 'formik';
import { UpdateAddressForm } from '@/features/profile-address/types';
import { useUpdateAddress } from '@/features/profile-address/store/hooks';
import { FormikRedux } from '@/components';
import { getErrorForDisplay } from '@/components/FormikRedux/useFormikError';
import { cloneDeep } from 'lodash-es';
import { AddressInfo } from 'ui-kit/lib/address';
import { YupShape } from '@/features/company-addresses/EditAddressDialog.tsx';

const useValidationSchema = () => {
  const { t } = useTranslate('profile');
  return useMemo(() => {
    return yup.object().shape<YupShape<UpdateAddressForm>>({
      fullAddress: yup
        .object()
        .nullable()
        .test('required', t('FIELD_IS_REQUIRED'), (addr) => {
          return !!addr;
        })
        .test('is-valid', t('PLEASE_CLARIFY_THE_ADDRESS'), (addr: unknown) => {
          const fullAddr = addr as AddressInfo;
          if (fullAddr?.isValid) {
            return true;
          } else {
            console.info('Missing parts of address: ', fullAddr?.whyNotValid);
            return false;
          }
        }),
    });
  }, [t]);
};

export const FormLogic: FC<{
  children: (
    formikProps: FormikProps<UpdateAddressForm>,
    fullAddressError: string | undefined,
    onChangeFullAddress: (addr: AddressInfo | null) => void
  ) => ReactNode;
}> = ({ children }) => {
  const d = useUpdateAddress();
  const schema = useValidationSchema();

  return (
    <FormikRedux<UpdateAddressForm>
      noForm
      initialValues={d.values}
      onSubmit={d.submit}
      serverErrors={d.errors}
      resetServerErrors={d.resetServerErrors}
      validationSchema={schema}
      validateOnChange
    >
      {(f) => {
        const fullAddressError = getErrorForDisplay({
          error: f.errors.fullAddress,
          value: f.values.fullAddress?.streetAddress,
          touched: f.touched.fullAddress ?? false,
          submitCount: f.submitCount,
        });
        const cb = (addr: AddressInfo | null) => {
          void f.setValues({
            fullAddress: cloneDeep(addr),
            address2: f.values.address2,
          });
        };
        return children(f, fullAddressError, cb);
      }}
    </FormikRedux>
  );
};
