import { FC } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { StyledAppLogo } from '@/features/layouts/MainLayout/styles';
import { Link } from 'react-router-dom';
import { AppRoutes } from '@/routes/appRoutes';
import { styles } from './styles';
import { spreadSx } from '@/utils/spreadSx';
import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

export const HeaderStripeView: FC<{
  leftContent?: JSX.Element;
  rightContent?: JSX.Element;
  onClick?: React.MouseEventHandler;
  sx?: SxProps<Theme>;
}> = ({ sx, leftContent, rightContent, onClick }) => {
  return (
    <Box
      data-test-id={testId(DATA_TEST_IDS.headerStripe + '_container')}
      component={'header'}
      sx={[styles.header, ...spreadSx(sx)]}
      onClick={onClick}
    >
      <Box sx={styles.leftLayout}>
        {leftContent}
        <Box
          component={Link}
          data-test-id={testId(DATA_TEST_IDS.headerStripe + '_app-logo')}
          to={{
            pathname: AppRoutes.PROFILE,
            search: window.location.search,
          }}
          sx={{ lineHeight: 0 }}
        >
          <StyledAppLogo />
        </Box>
      </Box>
      {rightContent}
    </Box>
  );
};
