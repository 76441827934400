import { styled } from '@mui/material';

import { shouldNotForwardProps } from '@/utils/shouldNotForwardProps';

export const PinInputContainer = styled(
  'div',
  shouldNotForwardProps(['error'])
)(() => ({
  display: 'flex',
  gap: 6,
  height: 42,
  flexShrink: 0,
}));
export const PinCell = styled('input')(({ theme }) => ({
  fontSize: '22px',
  lineHeight: '24px',
  fontWeight: 400,
  textAlign: 'center',
  boxSizing: 'border-box',
  padding: 0,
  margin: 0,
  borderRadius: 0,
  outline: 'none',
  border: `1px solid ${theme.palette.field.border.default}`,
  maxWidth: '100%',
  width: '100%',
  height: '100%',
  background: 'transparent',
  position: 'absolute',
  top: 0,
  left: 0,
  userSelect: 'none',
  '&:hover': {
    borderWidth: '2px',
    borderColor: `${theme.palette.field.border.hover}`,
  },
  '&:focus, &.selected': {
    borderWidth: '2px',
    borderColor: `${theme.palette.field.border.active}`,
  },
  '&.invalid': {
    borderWidth: '2px',
    borderColor: `${theme.palette.field.border.error} !important`,
  },
  '&.success': {
    borderWidth: '2px',
    borderColor: `${theme.palette.success.main} !important`,
  },
  '&:disabled': {
    borderWidth: '1px',
    borderColor: theme.palette.field.border.disabled,
    backgroundColor: theme.palette.field.background.disabled,
  },
}));
