import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@/api/client/ApiClient';
import { showError500OrUnknownToast } from '@/store/common/showError500OrUnknownToast';
import { TreeListItem, UUID } from '@/types';
import { ThunkApiType } from '@/store/common/types/ThunkApiType';
import { makeTree } from '@/store/module/makeTree';
import { Permission, PermissionGroup } from '@/api/__generated__/webApi';
import { NEW_ROLE_ID_STUB } from '@/features/module/constants.ts';
import {
  companiesActions as actions,
  FormNames,
} from '@/store/companies/slice.ts';

export const getLimsTreeItems = createAsyncThunk<
  {
    tree: Array<TreeListItem<Permission | PermissionGroup>>;
    permissions: Record<UUID, Permission>;
    groups: Record<UUID, PermissionGroup>;
  },
  { companyId: UUID; moduleId: UUID },
  ThunkApiType<Error>
>('module/get-tree', async ({ companyId, moduleId }, thunkAPI) => {
  try {
    const {
      data: { groups, permissions },
    } = await client.getCompanyModuleAvailablePermissionsTree(
      companyId,
      moduleId
    );

    const {
      treeBranches: tree,
      permissions: permRecord,
      groups: groupsRecord,
    } = makeTree(groups ?? [], permissions ?? []);
    return thunkAPI.fulfillWithValue({
      tree,
      permissions: permRecord,
      groups: groupsRecord,
    });
  } catch (e: unknown) {
    showError500OrUnknownToast(e);
    return thunkAPI.rejectWithValue(
      e instanceof Error ? e : new Error('Unknown error')
    );
  }
});

export const getRolePermissions = createAsyncThunk<
  { roleId: UUID; uuids: UUID[] },
  { companyId: UUID; moduleId: UUID; roleId: UUID },
  ThunkApiType<Error>
>(
  'module/get-permissions',
  async ({ companyId, moduleId, roleId }, thunkAPI) => {
    try {
      const { data: permissions } =
        await client.getCompanyModuleRolePermissions(
          companyId,
          moduleId,
          roleId
        );
      return thunkAPI.fulfillWithValue({
        roleId,
        uuids: permissions.map((p) => p.id),
      });
    } catch (e: unknown) {
      showError500OrUnknownToast(e);
      return thunkAPI.rejectWithValue(
        e instanceof Error ? e : new Error('Unknown error object')
      );
    }
  }
);

export const savePermissions = createAsyncThunk<
  undefined,
  { companyId: UUID; moduleId: UUID; roleId: UUID },
  ThunkApiType<Error>
>(
  'module/save-permissions',
  async ({ companyId, moduleId, roleId }, thunkAPI) => {
    const { selected, preSelected } = thunkAPI.getState().module;
    const selectedSet = new Set(selected);
    const preSelectedSet = new Set(preSelected);
    const toAdd = Array.from(selectedSet.difference(preSelectedSet)); // new ones
    const toDelete = Array.from(preSelectedSet.difference(selectedSet));
    try {
      if (roleId === NEW_ROLE_ID_STUB) {
        const roleData =
          thunkAPI.getState().companies.roles[moduleId]?.byID[NEW_ROLE_ID_STUB];
        if (!roleData) {
          // practically impossible but for just in case
          console.error('New role data is missing');
          return;
        }

        await client.createCustomRole(companyId, moduleId, {
          description: roleData.description,
          name: roleData.name,
          permissions: toAdd,
        });
        thunkAPI.dispatch(
          actions.deleteRoles({
            ids: [NEW_ROLE_ID_STUB],
            moduleId,
          })
        );
        thunkAPI.dispatch(actions.resetForm({ formName: FormNames.NewRole }));
      } else {
        if (toDelete.length) {
          await client.removeCompanyModuleRolePermissions(
            companyId,
            moduleId,
            roleId,
            { ids: toDelete }
          );
        }
        if (toAdd.length) {
          await client.addCompanyModuleRolePermissions(
            companyId,
            moduleId,
            roleId,
            { ids: toAdd }
          );
        }
      }
    } catch (e: unknown) {
      showError500OrUnknownToast(e);
      return thunkAPI.rejectWithValue(
        e instanceof Error ? e : new Error('Unknown error object')
      );
    }
  }
);
