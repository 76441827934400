import { all, call, put, takeLeading } from 'redux-saga/effects';
import { SagaPayload, SagaReturn } from '@/store/common/types';
import { profileActions as actions } from './slice';
import { accountApi as api } from '@/api';
import { getErrors } from '@/store/common/error-handlers';
import { companiesActions } from '@/store/companies/slice';
import { getError } from '../common/error-handlers';
import { getRecaptchaToken } from '@/hooks/useRecaptcha';
import { client } from '@/api/client/ApiClient';
import { showError500OrUnknownToast } from '@/store/common/showError500OrUnknownToast';

export function* profileSaga() {
  yield all([
    takeLeading(actions.requestAddressess, getAddresses),
    takeLeading(actions.requestCheckBackUrl, requestCheckBackUrl),
    takeLeading(actions.requestConfirmInvitation, requestConfirmInvitation),
    takeLeading(actions.requestSendSupportMessage, requestSendSupportMessage),
    takeLeading(actions.requestUploadAvatar, requestUploadAvatar),
    takeLeading(actions.requestAccountDetails, requestAccountDetails),
    takeLeading(actions.requestSaveAccountDetails, requestSaveAccountDetails),
  ]);
}

type Action<T extends keyof typeof actions> = SagaPayload<(typeof actions)[T]>;

function* requestSaveAccountDetails({
  payload,
}: Action<'requestSaveAccountDetails'>): SagaReturn {
  yield put(
    actions.setFormState({ formName: 'editProfile', formState: 'sending' })
  );
  try {
    const contact: Awaited<ReturnType<typeof api.saveAccountContactDetails>> =
      yield call(api.saveAccountContactDetails, payload.contact);
    yield put(actions.setContact(contact));
    yield put(
      actions.setFormState({ formName: 'editProfile', formState: 'done' })
    );
  } catch (e) {
    const errors = getErrors(e);
    if (errors.common) {
      showError500OrUnknownToast(e);
    }
    yield put(
      actions.setForm({
        forms: { editProfile: { errors, state: 'failed' } },
        merge: true,
      })
    );
  }
}

function* requestAccountDetails(): SagaReturn {
  try {
    const accountDetails: Awaited<ReturnType<typeof api.getAccountDetails>> =
      yield call(api.getAccountDetails);
    yield put(actions.setAccountDetails(accountDetails));
  } catch (e) {
    showError500OrUnknownToast(e);
  }
}
function* requestUploadAvatar({
  payload,
}: Action<'requestUploadAvatar'>): SagaReturn {
  try {
    yield call(client.uploadAccountAvatar, payload.data);
    yield call(requestAccountDetails);
    yield put(actions.updateAvatarUrl());
  } catch (e) {
    showError500OrUnknownToast(e);
  }
}
function* requestSendSupportMessage({
  payload,
}: Action<'requestSendSupportMessage'>): SagaReturn {
  try {
    yield put(
      actions.setFormState({ formName: 'supportForm', formState: 'sending' })
    );
    const recaptcha: Awaited<ReturnType<typeof getRecaptchaToken>> =
      yield call(getRecaptchaToken);
    yield call(api.sendSupportMessage, { recaptcha, ...payload });
    yield put(
      actions.setFormState({ formName: 'supportForm', formState: 'done' })
    );
  } catch (e) {
    const errors = getErrors(e);
    if (errors.common) {
      yield put(actions.setErrors({ common: errors.common }));
    }
    yield put(actions.setFormErrors({ form: 'supportForm', errors }));
    yield put(
      actions.setFormState({ formName: 'supportForm', formState: 'failed' })
    );
  }
}

function* getAddresses(): SagaReturn {
  try {
    yield put(actions.setIsFetching({ fetching: true }));
    const {
      data: addresses,
    }: Awaited<ReturnType<typeof client.getAccountContactAddresses>> =
      yield call(client.getAccountContactAddresses);
    yield put(actions.setAddresses({ items: addresses }));
  } catch (e) {
    yield put(actions.setErrors(getErrors(e)));
  } finally {
    yield put(actions.setIsFetching({ fetching: true }));
  }
}
function* requestConfirmInvitation({
  payload,
}: Action<'requestConfirmInvitation'>): SagaReturn {
  try {
    const result: Awaited<ReturnType<typeof api.confirmInvitationToModule>> =
      yield call(api.confirmInvitationToModule, payload);
    yield put(companiesActions.requestCompanies());
    yield put(
      actions.setNotification({ type: 'INVITE_SUCCESSFUL', params: result })
    );
  } catch (e) {
    const err = getError(e);
    yield put(actions.setErrors({ common: err }));
  }
}
function* requestCheckBackUrl({
  payload,
}: Action<'requestCheckBackUrl'>): SagaReturn {
  try {
    const data: Awaited<ReturnType<typeof api.checkBackUrl>> = yield call(
      api.checkBackUrl,
      { url: payload.backUrl }
    );
    if (data.type === 'COMPANY_MODULE') {
      yield put(
        companiesActions.requestEnterModule({
          moduleType: data.module,
          companyId: data.companyId,
          redirectUrl: payload.backUrl,
        })
      );
    } else {
      if (data.url) {
        window.open(data.url, '_self');
      }
    }
  } catch (e: unknown) {
    showError500OrUnknownToast(e);
  } finally {
    // purpose - just to wipe out search part of the url without hard reloading of the page
    yield put(actions.redirect(window.location.pathname));
  }
}
