import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TreeListItem, UUID } from '@/types';
import { getLimsTreeItems, getRolePermissions } from '@/store/module/api';
import { Permission, PermissionGroup } from '@/api/__generated__/webApi';
type PermissionUUID = UUID;
type GroupUUID = UUID;
export interface ModuleState {
  limsPermissionsTree: Array<TreeListItem<PermissionGroup | Permission>>;
  permissions: Record<PermissionUUID, Permission>;
  groups: Record<GroupUUID, PermissionGroup>;
  selected: UUID[];
  initializedFor?: { companyId: UUID; moduleId: UUID; roleId: UUID };
  preSelected: PermissionUUID[] | undefined;
}
const initialState: ModuleState = {
  limsPermissionsTree: [],
  permissions: {},
  groups: {},
  selected: [],
  preSelected: undefined,
};
export const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    setSelected(state, { payload }: PayloadAction<UUID[]>) {
      if (payload.length === 0 && state.selected.length === 0) {
        return;
      }
      state.selected = payload;
    },
    setPreSelected(state, { payload }: PayloadAction<UUID[]>) {
      if (payload.length === 0 && state.preSelected?.length === 0) {
        return;
      }
      state.preSelected = payload;
    },
    setInitLoadingIsDone(
      state,
      {
        payload,
      }: PayloadAction<{ companyId: UUID; moduleId: UUID; roleId: UUID }>
    ) {
      if (
        payload.companyId !== state.initializedFor?.companyId ||
        payload.roleId !== state.initializedFor?.roleId ||
        payload.moduleId !== state.initializedFor?.moduleId
      )
        state.initializedFor = payload;
    },
    resetSelected(state) {
      state.preSelected = undefined;
      state.selected = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLimsTreeItems.fulfilled, (state, data) => {
      state.limsPermissionsTree = data.payload.tree;
      state.groups = data.payload.groups;
      state.permissions = data.payload.permissions;
    });
    builder.addCase(
      getRolePermissions.fulfilled,
      (state, { payload: { uuids } }) => {
        state.preSelected = uuids;
      }
    );
  },
});

export const moduleActions = moduleSlice.actions;
