import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import {
  companiesActions as actions,
  CompaniesState,
  FormNames,
} from '../slice';
import { SagaPayload, SagaReturn } from '@/store/common/types';
import { RootState } from '@/store/store';
import { getError, getErrors } from '@/store/common/error-handlers';
import {
  removeUserFromModule,
  requestAddUserToModule,
  requestCompanyUsers,
  requestModuleUsers,
  requestUsersRouter,
} from './moduleUsersTableSagas';
import { AppRoutes } from '@/routes/appRoutes';
import { client, identityClient } from '@/api/client/ApiClient';
import {
  CreateModuleType,
  ModuleType,
  Permission,
  Role,
} from '@/api/__generated__/webApi';
import { showError500OrUnknownToast } from '@/store/common/showError500OrUnknownToast';
import { NEW_ROLE_ID_STUB } from '@/features/module/constants.ts';
import { withTimestamp } from '@/utils/withTimestamp.ts';

export function* companiesSaga() {
  yield all([
    takeEvery(actions.requestModuleStatus, requestModuleStatus),
    takeLeading(actions.requestCompanies, _requestCompanies),
    takeLeading(actions.requestCreateModule, requestCreateModule),
    takeLeading(actions.requestCompanyDetails, _requestCompanyDetails),
    takeLeading(actions.requestRolePermissions, _requestPermissions),
    takeLeading(actions.requestModulePermissions, _requestModulePermissions),
    takeLeading(actions.requestEditRole, requestEditRole),
    takeLeading(
      actions.requestRemoveRolePermissions,
      _requestRemoveRolePermissions
    ),
    takeLeading(actions.requestAddRolePermissions, _requestAddRolePermissions),
    takeLeading(actions.requestRoles, _requestRoles),
    // module users table handlers
    takeEvery(actions.requestRoleUsers, requestUsersRouter),
    takeLeading(
      `${actions.requestRoleUsers.toString()}_company`,
      // no way to resolve it better, just weird behavior - can be left as is because it is legacy code
      // eslint-disable-next-line
      requestCompanyUsers as any
    ),
    takeLeading(
      `${actions.requestRoleUsers.toString()}_module`,
      // no way to resolve it better, just weird behavior
      // eslint-disable-next-line
      requestModuleUsers as any
    ),
    takeLeading(actions.requestRemoveUserFromRole, removeUserFromModule),
    takeLeading(actions.requestAddUserToModuleRole, requestAddUserToModule),
    takeLeading(actions.requestEnterModule, _requestEnterModule),
    takeLeading(actions.requestCreateAddress, requestCreateAddress),
    takeLeading(actions.requestEditAddress, requestEditAddress),
    takeLeading(actions.requestCreateADConnection, requestCreateAdConnection),
    takeLeading(actions.requestAuthorizeWithAd, requestAuthorizationWithAd),
    takeLeading(actions.requestOpenIdSettings, requestOpenIdSettings),
    takeLeading(actions.requestUploadCompanyLogo, requestUploadAvatar),
  ]);
}
type Action<T extends keyof typeof actions> = SagaPayload<(typeof actions)[T]>;

function* requestModuleStatus({
  payload,
}: Action<'requestModuleStatus'>): SagaReturn {
  const modules = (yield select(
    (s: RootState) => s.companies.modules
  )) as CompaniesState['modules'];
  const module = modules[payload.companyId]?.byID[payload.moduleId];
  if (!module) {
    console.error(
      '[companies saga -> requestModuleStatus] Error. Module not found'
    );
    return;
  }
  try {
    const { data }: Awaited<ReturnType<typeof client.getCompanyModuleStatus>> =
      yield call(
        client.getCompanyModuleStatus,
        payload.companyId,
        payload.moduleId
      );
    if (data.status !== module.status) {
      const updatedModule = { ...module, status: data.status };
      yield put(actions.setModules({ [payload.companyId]: [updatedModule] }));
    }
  } catch (e) {
    showError500OrUnknownToast(e);
  }
}

function* requestUploadAvatar({
  payload,
}: Action<'requestUploadCompanyLogo'>): SagaReturn {
  try {
    yield call(client.updateCompanyLogo, payload.companyId, payload.data);
    yield call(_requestCompanies);
    yield put(actions.refreshCompanyLogoUrl({ companyId: payload.companyId }));
  } catch (e) {
    showError500OrUnknownToast(e);
  }
}
function* requestEditRole({
  payload,
}: SagaPayload<typeof actions.requestEditRole>): SagaReturn {
  try {
    yield call(
      client.updateCompanyModuleRole,
      payload.companyId,
      payload.moduleId,
      payload.role.id,
      {
        description: payload.role.description ?? '',
        name: payload.role.name ?? '',
      }
    );
    yield put(
      actions.setRoles({ moduleId: payload.moduleId, roles: [payload.role] })
    );
    yield put(actions.resetForm({ formName: FormNames.EditRole }));
  } catch (e) {
    const errors = getErrors(e);
    if (errors.common) {
      yield put(
        actions.setError({
          common: errors.common,
        })
      );
    } else {
      yield put(
        actions.setFormErrors({ formName: FormNames.EditRole, errors })
      );
    }
  }
}
function* requestOpenIdSettings({
  payload,
}: SagaPayload<typeof actions.requestOpenIdSettings>): SagaReturn {
  try {
    const {
      data: settings,
    }: Awaited<ReturnType<typeof client.getCompanyOpenid>> = yield call(
      client.getCompanyOpenid,
      payload.companyId
    );
    yield put(
      actions.setOpenIdSettings({ companyId: payload.companyId, settings })
    );
  } catch (e) {
    showError500OrUnknownToast(e);
    const err = getError(e);
    yield put(actions.setError({ common: err }));
  }
}
function* requestAuthorizationWithAd({
  payload,
}: SagaPayload<typeof actions.requestAuthorizeWithAd>): SagaReturn {
  try {
    const {
      data: { redirectUrl },
    }: Awaited<ReturnType<typeof identityClient.openidSignIn>> = yield call(
      identityClient.openidSignIn,
      {
        companyId: payload.companyId,
        webUrl: payload.currentUrl,
      }
    );
    window.open(redirectUrl);
  } catch (e) {
    showError500OrUnknownToast(e);
    const err = getError(e);
    yield put(actions.setError({ common: err }));
  }
}
function* requestCreateAdConnection({
  payload,
}: SagaPayload<typeof actions.requestCreateADConnection>): SagaReturn {
  try {
    yield put(
      actions.setFormState({
        formName: FormNames.AdConnection,
        formState: 'sending',
      })
    );
    yield call(
      client.updateCompanyOpenid,
      payload.companyId,
      payload.openIdData
    );
    yield put(
      actions.setCompanyLock({
        companyId: payload.companyId,
        locked: payload.openIdData.enable,
      })
    );
    yield put(
      actions.setOpenIdSettings({
        companyId: payload.companyId,
        settings: payload.openIdData,
      })
    );
    yield put(
      actions.setFormState({
        formName: FormNames.AdConnection,
        formState: 'done',
      })
    );
  } catch (e) {
    yield put(
      actions.setFormState({
        formName: FormNames.AdConnection,
        formState: 'failed',
      })
    );
    const { common, ...restErrors } = getErrors(e);
    if (common) {
      showError500OrUnknownToast(e);
      yield put(actions.setError({ common }));
    }
    if (Object.keys(restErrors).length) {
      yield put(
        actions.setFormErrors({
          formName: FormNames.AdConnection,
          errors: restErrors,
        })
      );
    }
  }
}
function* requestCreateAddress({
  payload,
}: SagaPayload<typeof actions.requestCreateAddress>): SagaReturn {
  try {
    yield put(
      actions.setFormState({
        formName: FormNames.CreateAddress,
        formState: 'sending',
      })
    );
    const response: Awaited<ReturnType<typeof client.createCompanyAddress>> =
      yield call(
        client.createCompanyAddress,
        payload.companyId,
        payload.address
      );
    yield put(
      actions.updateCompanyAddress({
        companyId: payload.companyId,
        address: response.data,
      })
    );
    yield put(
      actions.setFormState({
        formName: FormNames.CreateAddress,
        formState: 'done',
      })
    );
    yield put(actions.requestCompanyDetails({ companyId: payload.companyId }));
  } catch (e) {
    yield put(
      actions.setFormState({
        formName: FormNames.CreateAddress,
        formState: 'failed',
      })
    );
    const { common, ...restErrors } = getErrors(e);
    if (common) {
      showError500OrUnknownToast(e);
      yield put(actions.setError({ common }));
    }
    if (Object.keys(restErrors).length) {
      yield put(
        actions.setFormErrors({
          formName: FormNames.CreateAddress,
          errors: restErrors,
        })
      );
    }
  }
}
function* requestEditAddress({
  payload,
}: SagaPayload<typeof actions.requestEditAddress>): SagaReturn {
  try {
    yield put(
      actions.setFormState({
        formName: FormNames.EditAddress,
        formState: 'sending',
      })
    );
    const address: Awaited<ReturnType<typeof client.updateCompanyAddress>> =
      yield call(
        client.updateCompanyAddress,
        payload.companyId,
        payload.addressId,
        payload.partialAddress
      );
    yield put(
      actions.updateCompanyAddress({
        companyId: payload.companyId,
        address: address.data,
      })
    );
    yield put(
      actions.setFormState({
        formName: FormNames.EditAddress,
        formState: 'done',
      })
    );
    yield put(actions.requestCompanyDetails({ companyId: payload.companyId }));
  } catch (e) {
    yield put(
      actions.setFormState({
        formName: FormNames.EditAddress,
        formState: 'failed',
      })
    );
    const { common, ...restErrors } = getErrors(e);
    const formErrors = { ...restErrors };
    if (common) {
      if (common === 'AT_LEAST_ONE_BILL_TYPE_MUST_BE_TRUE') {
        formErrors.insuranceBillType = common;
      } else {
        showError500OrUnknownToast(e);
        yield put(actions.setError({ common }));
      }
    }
    if (Object.keys(formErrors).length) {
      yield put(
        actions.setFormErrors({
          formName: FormNames.EditAddress,
          errors: formErrors,
        })
      );
    }
  }
}

function* _requestEnterModule({
  payload,
}: SagaPayload<typeof actions.requestEnterModule>): SagaReturn {
  try {
    yield put(actions.setIsFetching(true));
    const {
      data: { redirectUrl },
    }: Awaited<ReturnType<typeof identityClient.moduleSignIn>> = yield call(
      identityClient.moduleSignIn,
      payload.companyId,
      payload.moduleType
    );
    const targetWindow = payload.openInNewTab ? '_blank' : '_self';
    if (payload.redirectUrl) {
      window.open(payload.redirectUrl, targetWindow);
    } else if (redirectUrl) {
      window.open(redirectUrl, targetWindow);
    } else {
      throw new Error('Unknown module type');
    }
  } catch (e) {
    const error = getError(e);
    yield put(actions.setErrors({ common: { enterModule: error } }));
    showError500OrUnknownToast(e);
  } finally {
    yield put(actions.setIsFetching(false));
  }
}
function* _requestAddRolePermissions({
  payload,
}: SagaPayload<typeof actions.requestAddRolePermissions>): SagaReturn {
  const companyState: CompaniesState = yield select(
    (rootState: RootState) => rootState.companies
  );
  const permissions = payload.ids.reduce<Permission[]>((arr, id) => {
    const p = companyState.modulePermissions[payload.moduleId]?.byID[id];
    if (p) {
      arr.push(p);
    }
    return arr;
  }, []);
  yield put(
    actions.setRolePermissions({
      roleId: payload.roleId,
      moduleId: payload.moduleId,
      permissions,
    })
  );
  if (payload.roleId === NEW_ROLE_ID_STUB) {
    try {
      const role = companyState.roles[payload.moduleId]?.byID[
        NEW_ROLE_ID_STUB
      ] as Required<Role>;
      const {
        data: result,
      }: Awaited<ReturnType<typeof client.createCustomRole>> = yield call(
        client.createCustomRole,
        payload.companyId,
        payload.moduleId,
        {
          description: role.description,
          name: role.name,
          permissions: payload.ids,
        }
      );
      yield put(
        actions.setRoles({ moduleId: payload.moduleId, roles: [result] })
      );
      yield put(
        actions.setRolePermissions({
          roleId: result.id,
          moduleId: payload.moduleId,
          permissions,
        })
      );
      yield put(
        actions.setRedirect({
          pattern: AppRoutes.COMPANY_MODULES_PERMISSIONS_EDIT,
          params: { roleId: result.id },
        })
      );
      yield fork(function* () {
        yield delay(300);
        yield put(
          actions.deleteRoles({
            ids: [NEW_ROLE_ID_STUB],
            moduleId: payload.moduleId,
          })
        );
      });
      yield put(actions.resetForm({ formName: FormNames.NewRole }));
    } catch (e) {
      yield put(
        actions.deletePermissions({
          moduleId: payload.moduleId,
          roleId: payload.roleId,
          ids: payload.ids,
        })
      );
      const errors = getErrors(e);
      yield put(
        actions.setForm({
          forms: { [FormNames.NewRole]: { errors, state: 'failed' } },
          merge: true,
        })
      );
    }
  } else {
    try {
      yield call(
        client.addCompanyModuleRolePermissions,
        payload.companyId,
        payload.moduleId,
        payload.roleId,
        {
          ids: payload.ids,
        }
      );
    } catch (e) {
      yield put(
        actions.deletePermissions({
          moduleId: payload.moduleId,
          roleId: payload.roleId,
          ids: payload.ids,
        })
      );
      const error = getError(e);
      yield put(actions.setErrors({ common: { allPermissions: error } }));
    }
  }
}
function* _requestRemoveRolePermissions({
  payload,
}: SagaPayload<typeof actions.requestRemoveRolePermissions>): SagaReturn {
  const { companyId, moduleId, roleId } = payload;
  const snapshot1: CompaniesState = yield select(
    (rootState: RootState) => rootState.companies
  );
  const permissionsToDelete = payload.ids.reduce<Permission[]>((arr, id) => {
    const p =
      snapshot1.rolePermissions?.[payload.moduleId]?.[payload.roleId]?.byID[id];
    if (p) {
      arr.push(p);
    }
    return arr;
  }, []);
  yield put(
    actions.deletePermissions({
      roleId: payload.roleId,
      moduleId: payload.moduleId,
      ids: payload.ids,
    })
  );
  const snapshot2: CompaniesState = yield select(
    (rootState: RootState) => rootState.companies
  );
  const isLast =
    !snapshot2.rolePermissions?.[payload.moduleId]?.[payload.roleId]?.allIDs
      .length;

  try {
    yield call(
      client.removeCompanyModuleRolePermissions,
      companyId,
      moduleId,
      roleId,
      { ids: payload.ids }
    );
    if (isLast) {
      yield put(
        actions.transformRoleToNew({
          moduleId: payload.moduleId,
          roleId: payload.roleId,
        })
      );
      yield put(
        actions.setRedirect({
          pattern: AppRoutes.COMPANY_MODULES_PERMISSIONS_EDIT,
          params: { roleId: NEW_ROLE_ID_STUB },
        })
      );
    }
  } catch (e) {
    yield put(
      actions.setRolePermissions({
        roleId: payload.roleId,
        moduleId: payload.moduleId,
        permissions: permissionsToDelete,
      })
    );
    const error = getError(e);
    yield put(actions.setErrors({ common: { permissions: error } }));
  }
}
function* _requestRoles({
  payload,
}: SagaPayload<typeof actions.requestRoles>): SagaReturn {
  try {
    yield put(actions.setIsFetching(true));
    const {
      data: roles,
    }: Awaited<ReturnType<typeof client.getCompanyModuleRoles>> = yield call(
      client.getCompanyModuleRoles,
      payload.companyId,
      payload.moduleId
    );
    yield put(
      actions.setRoles({
        moduleId: payload.moduleId,
        roles,
        replace: payload.replace,
      })
    );
  } catch (e) {
    showError500OrUnknownToast(e);
    const error = getError(e);
    yield put(actions.setError({ common: error }));
  } finally {
    yield put(actions.setIsFetching(false));
  }
}
function* _requestPermissions({
  payload,
}: SagaPayload<typeof actions.requestRolePermissions>): SagaReturn {
  const { companyId, moduleId, roleId } = payload;
  try {
    const {
      data: permissions,
    }: Awaited<ReturnType<typeof client.getCompanyModuleRolePermissions>> =
      yield call(
        client.getCompanyModuleRolePermissions,
        companyId,
        moduleId,
        roleId
      );
    yield put(
      actions.setRolePermissions({
        permissions,
        moduleId: payload.moduleId,
        roleId: payload.roleId,
      })
    );
  } catch (e) {
    showError500OrUnknownToast(e);
    const error = getError(e);
    yield put(actions.setError({ common: error }));
  }
}
function* _requestModulePermissions({
  payload,
}: SagaPayload<typeof actions.requestModulePermissions>): SagaReturn {
  try {
    const {
      data: permissions,
    }: Awaited<ReturnType<typeof client.getCompanyModuleAvailablePermissions>> =
      yield call(
        client.getCompanyModuleAvailablePermissions,
        payload.companyId,
        payload.moduleId
      );
    yield put(
      actions.setModulePermissions({ permissions, moduleId: payload.moduleId })
    );
  } catch (e) {
    showError500OrUnknownToast(e);
    const error = getError(e);
    yield put(actions.setError({ common: error }));
  }
}
function* _requestCompanies(): SagaReturn {
  try {
    yield put(actions.setIsFetching(true));
    const {
      data: companies,
    }: Awaited<ReturnType<typeof client.getAccountCompanies>> = yield call(
      client.getAccountCompanies
    );
    // required to prevent loading of old cached images
    companies.forEach((c) => (c.logoUrl = withTimestamp(c.logoUrl)));
    yield put(actions.setCompanies({ companies, replace: true }));
    for (const { id, modules } of companies) {
      yield put(actions.setModules({ [id]: modules ?? [] }));
    }
  } catch (e) {
    showError500OrUnknownToast(e);
    const error = getError(e);
    yield put(actions.setError({ common: error }));
  } finally {
    yield put(actions.setIsFetching(false));
  }
}
export const LOCALLY_CREATED_LIS_STUB_ID = 'LOCALLY_CREATED_LIS_STUB_ID';
function* requestCreateModule({
  payload,
}: SagaPayload<typeof actions.requestCreateModule>): SagaReturn {
  try {
    yield put(actions.dialogCreateModuleSetFetching({ fetching: true }));
    const {
      data: module,
    }: Awaited<ReturnType<typeof client.createCompanyModule>> = yield call(
      client.createCompanyModule,
      payload.companyId,
      {
        module: payload.moduleType as unknown as CreateModuleType,
      }
    );
    const moduleToUpdate = module || {
      id: LOCALLY_CREATED_LIS_STUB_ID,
      type: ModuleType.LIS,
    };
    yield put(actions.setModules({ [payload.companyId]: [moduleToUpdate] }));
    yield put(
      actions.completeCreateModule({ moduleType: moduleToUpdate.type })
    );
  } catch (e) {
    showError500OrUnknownToast(e);
    const error = getError(e);
    yield put(actions.setError({ common: error }));
  } finally {
    yield put(actions.dialogCreateModuleSetFetching({ fetching: false }));
  }
}
function* _requestCompanyDetails({
  payload,
}: SagaPayload<typeof actions.requestCompanyDetails>): SagaReturn {
  const companyState: CompaniesState = yield select(
    (rootState: RootState) => rootState.companies
  );
  const now = Date.now();
  const detailsData = companyState.companyDetails?.[payload.companyId];
  const whenStale = companyState?.staleTime[payload.companyId] ?? 0;
  const dataIsNotStale = now < whenStale;
  const requestIsNotMandatory = !payload.forced;
  const theDataExists = !!detailsData;
  if (dataIsNotStale && requestIsNotMandatory && theDataExists) {
    return;
  }

  try {
    if (!payload.quiet) {
      yield put(actions.setIsFetching(true));
    }
    const response: Awaited<ReturnType<typeof client.getNpi>> = yield call(
      client.getNpi,
      payload.companyId
    );
    yield put(
      actions.setCompanyDetails({
        companyId: payload.companyId,
        details: response.data,
      })
    );
    yield put(
      actions.setStaleTime({
        uuid: payload.companyId,
        time: Date.now() + 60 * 60 * 1000,
      })
    );
  } catch (e) {
    showError500OrUnknownToast(e);
    const error = getError(e);
    yield put(actions.setError({ common: error }));
  } finally {
    yield put(actions.setIsFetching(false));
  }
}
