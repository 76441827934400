import React, { FC, PropsWithChildren } from 'react';
import { useFileOperations } from './useFileOperations';
import { FileChangeHandler } from './DnDFileInput';
import { FileContext } from './FileContext.tsx';

export const FileContextWrapper: FC<
  PropsWithChildren<{
    onChange?: FileChangeHandler;
    multiple?: boolean;
    name?: string;
  }>
> = ({ onChange, multiple, name, children }) => {
  const { files, handleDelete, handleAddFiles, handleChange } =
    useFileOperations({
      onChange,
      name,
      multiple,
    });
  return (
    <FileContext.Provider
      value={{
        handleChange,
        handleDelete,
        files,
        handleAddFiles,
      }}
    >
      {children}
    </FileContext.Provider>
  );
};
