import React, { createContext } from 'react';

export interface ContextActions {
  handleChange: React.ChangeEventHandler<Element>;
  handleDelete: (filename: string) => void;
  files: File[];
  handleAddFiles: (files: FileList | null | undefined) => void;
}

export const FileContext = createContext<ContextActions>({
  files: [],
  handleDelete: () => {},
  handleChange: () => {},
  handleAddFiles: () => {},
});
