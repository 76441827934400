import { ADForm } from '@/features/company-details/ADSection';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCompaniesState } from './useCompaniesState';
import { useCompanyData } from './useCompany';
import { companiesActions as actions, FormNames } from '../slice';
import { useDispatch } from 'react-redux';
import { OpenIdSettings, UUID } from '@/types';
import {
  AppRoutes,
  generatePath,
  ParamNames,
  useParams,
} from '@/routes/appRoutes';
import { CompanyDetailsTabId } from '@/features/company-details/CompanyDetailsTabId.tsx';
import { useNavigate } from 'react-router-dom';

export type ADWidgetState =
  | 'form'
  | 'sent'
  | 'authorization'
  | 'authorized'
  | 'error'
  | 'init';
export const useADConnect = (companyId?: UUID) => {
  const dispatch = useDispatch();
  const state = useCompaniesState();
  const params = useParams();
  companyId = companyId ?? params[ParamNames.CompanyId];
  const { company } = useCompanyData(companyId);
  const [widgetState, setWidgetState] = useState<ADWidgetState>('init');
  const [draftOpenIdSettings, setDraft] = useState<OpenIdSettings | null>(null);
  const authorize = useADAuthorize({ companyId });
  const navigate = useNavigate();
  const getOpenIdSettings = (companyId?: UUID) => {
    if (!companyId) {
      return;
    }
    if (state.openIdSettings.byID[companyId] != null) {
      return state.openIdSettings.byID[companyId];
    } else {
      dispatch(actions.requestOpenIdSettings({ companyId }));
    }
  };
  const companyOpenIdSettings = getOpenIdSettings(companyId);
  const formData = state.forms[FormNames.AdConnection];

  useEffect(() => {
    if (!company?.locked) {
      setWidgetState('authorized');
      return;
    }
    if (formData?.state === 'failed' && state.error) {
      setWidgetState('error');
      return;
    }
    if (formData?.state === 'done') {
      if (companyOpenIdSettings?.enable) {
        setWidgetState('authorization');
      } else {
        setWidgetState('sent');
      }
    }
  }, [
    company?.locked,
    companyOpenIdSettings?.enable,
    formData?.state,
    state.error,
    state.forms,
  ]);

  const callbacks = useMemo(() => {
    return {
      resetErrors: () => {
        dispatch(actions.resetErrors());
        dispatch(actions.resetFormErrors({ formName: FormNames.EditAddress }));
      },
      submitForm: (values: ADForm, adEnabled: boolean) => {
        const newVal = { ...values, enable: adEnabled };
        setDraft(newVal);
        dispatch(
          actions.requestCreateADConnection({
            companyId: companyId as UUID,
            openIdData: newVal,
          })
        );
      },
      authorizeLater: () => {
        if (companyId)
          dispatch(
            actions.requestCompanyDetails({
              companyId,
              quiet: false,
              forced: true,
            })
          );
        navigate({
          pathname: generatePath(AppRoutes.COMPANY_DETAILS, {
            companyId,
            [ParamNames.CompanyDetailsSection]: CompanyDetailsTabId.CompanyName,
          }),
          search: window.location.search,
        });
      },
    };
  }, [companyId, dispatch, navigate]);

  return {
    companyOpenIdSettings: draftOpenIdSettings || companyOpenIdSettings,
    serverFormErrors: state.forms[FormNames.AdConnection]?.errors ?? {},
    widgetState,
    fetching: state.forms[FormNames.AdConnection]?.state === 'sending',
    companyLocked: company?.locked,
    ...callbacks,
    authorize,
  };
};

export const useADAuthorize = (p?: { companyId?: UUID; url?: string }) => {
  const { companyId, url } = p ?? {};
  const dispatch = useDispatch();
  return useCallback(
    (param?: { companyId?: UUID; url?: string }) => {
      if (!param?.companyId && !companyId) {
        console.error('[useADAuthorize] CompanyId is not provided');
        return;
      }
      dispatch(
        actions.requestAuthorizeWithAd({
          companyId: (param?.companyId ?? companyId) as UUID,
          currentUrl: param?.url ?? url ?? window.location.href,
        })
      );
    },
    [companyId, dispatch, url]
  );
};
