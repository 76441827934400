import React, { useMemo } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { BackButton } from '@/features/company-details/BackButton';
import { Stack } from '@mui/material';
import { useBreakpoints } from 'ui-kit';
import { Password } from './Password';
import { Authentication } from './Authentication';
import { AddNewQrDialog } from '@/features/profile/Security/AddNewQrDialog';
import { EditQrDialog } from '@/features/profile/Security/EditQrDialog';
import { Tab, Tabs, useTabs, TabPanel } from '@/components/TabPanel';
import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

enum TabId {
  Password = 'password',
  Authentication = 'authentication',
}
const useTabsContent = () => {
  const { t } = useTranslate('profile');
  return useMemo(() => {
    return [
      { title: t('PASSWORD'), id: TabId.Password, Component: Password },
      {
        title: t('AUTHENTICATION'),
        id: TabId.Authentication,
        Component: Authentication,
      },
    ];
  }, [t]);
};
export const SecurityPage = () => {
  const d = useTabs('');
  const tabs = useTabsContent();
  const { md } = useBreakpoints();
  return (
    <>
      <Tabs
        value={d.index}
        onChange={d.handleChange}
        enableMobileView
        variant={'standard'}
        defaultTab={TabId.Password}
        sx={{ button: { minWidth: { md: 250 } } }}
      >
        {tabs.map((tab, index) => {
          return (
            <Tab
              data-test-id={testId(
                DATA_TEST_IDS.securitySecurityPage + '_tab' + index
              )}
              label={tab.title}
              value={tab.id}
              key={tab.id}
            />
          );
        })}
      </Tabs>
      <Stack sx={{ flexGrow: 1, minHeight: 100 }}>
        {!md ? (
          <>
            {tabs.map(({ Component, id }, i) => {
              return (
                <TabPanel value={id} selected={d.index} key={id}>
                  <BackButton
                    data-test-id={testId(
                      DATA_TEST_IDS.securitySecurityPage + '_back-button' + i
                    )}
                    onClick={() => d.handleChange(null, '')}
                  />
                  <Component />
                </TabPanel>
              );
            })}
          </>
        ) : (
          <>
            {tabs.map(({ Component, id }) => {
              return (
                <TabPanel value={id} selected={d.index} key={id}>
                  <Component />
                </TabPanel>
              );
            })}
          </>
        )}
      </Stack>
      <Modals />
    </>
  );
};

const Modals = () => {
  return (
    <>
      <AddNewQrDialog />
      <EditQrDialog />
    </>
  );
};
