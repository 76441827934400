import { BoxHeaderDeprecated, WhiteBox } from '@/components/WhiteBox';
import { Breadcrumbs, BreadcrumbsText } from '@/components/Breadcrumbs';
import { H3, Link } from '@/components/Text';
import { AppRoutes, generatePath, useParams } from '@/routes/appRoutes';
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useCompaniesState,
  useFailedRoleCreationRedirect,
  usePermissions,
  useRoles,
} from '@/store/companies/hooks';
import { Grid } from '@/components';
import { Tag } from '@/components/Tag';
import { useDispatch } from 'react-redux';
import { companiesActions } from '@/store/companies/slice';
import { Box, styled } from '@mui/material';
import { CompanyHeader } from '@/features/company/fragments/CompanyHeader';
import { CompanyPermissionName } from '@/types';
import { useCompanyPermissionsValidator } from '@/store/permissions/useCompanyPermissionsValidator.tsx';
import { NEW_ROLE_ID_STUB } from '@/features/module/constants.ts';
import { useModuleName } from '@/features/module/ModulePage/fragments/useModuleName.tsx';

import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

const useWatchRedirect = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { redirect } = useCompaniesState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (redirect) {
      const p = { ...params, ...redirect.params };
      const path = generatePath(redirect.pattern, p);
      navigate({ pathname: path, search: window.location.search });
      dispatch(companiesActions.setRedirect(null));
    }
  }, [dispatch, navigate, params, redirect]);
};
export const EditablePermissionsPage: FC = () => {
  const { companyId, moduleId, module, roleId } = useParams();
  const { t, ready } = useTranslate('companies');
  const navigate = useNavigate();
  const { role } = useRoles();
  const { permissions, modulePermissions, addPermission, removePermission } =
    usePermissions();
  const { name } = useModuleName();
  const hasPermission = useCompanyPermissionsValidator(true);

  useEffect(() => {
    if ((roleId === NEW_ROLE_ID_STUB && !role) || !role?.editable) {
      navigate({
        pathname: generatePath(AppRoutes.COMPANY_MODULES, {
          companyId,
          moduleId,
          module,
        }),
        search: window.location.search,
      });
    }
  }, [companyId, module, moduleId, navigate, role, roleId]);
  useWatchRedirect();
  useFailedRoleCreationRedirect();
  if (!ready) {
    return <>...</>;
  }

  return (
    <>
      <CompanyHeader />
      <Breadcrumbs>
        <Link
          data-test-id={testId(
            DATA_TEST_IDS.permissionsEditablePermissionsPage + '_link-bc-module'
          )}
          to={{
            pathname: generatePath(AppRoutes.COMPANY_MODULES, {
              companyId,
              moduleId,
              module,
            }),
            search: window.location.search,
          }}
        >
          {name}
        </Link>
        <BreadcrumbsText>
          {t('PERMISSIONS_FOR', { roleName: role?.name ?? '...' })}
        </BreadcrumbsText>
      </Breadcrumbs>
      <WhiteBox>
        <BoxHeaderDeprecated
          mb={36}
          sx={{ display: { xs: 'block', sm: 'none' } }}
        >
          {t('PERMISSIONS_FOR', { roleName: role?.name ?? '...' })}
        </BoxHeaderDeprecated>
        <Box sx={{ height: '100%' }}>
          <Grid
            container
            columnSpacing={24}
            rowSpacing={24}
            sx={{ height: '100%' }}
          >
            <Grid
              xs={12}
              sm={6}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <H3
                mb={30}
                fz={24}
                lh={28}
                weight={400}
                sx={{ display: { xs: 'none', sm: 'block' } }}
              >
                {t('ASSIGNED_PERMISSIONS')}
              </H3>
              <Container>
                {permissions?.allIDs.map((id) => {
                  return (
                    <Tag
                      data-test-id={testId(
                        DATA_TEST_IDS.permissionsEditablePermissionsPage +
                          '_tag-permission'
                      )}
                      key={id}
                      disableRipple={false}
                      onClick={
                        hasPermission(
                          CompanyPermissionName.ModuleEditCustomRole
                        )
                          ? () => removePermission(id)
                          : undefined
                      }
                      type={'active'}
                    >
                      {permissions.byID?.[id]?.description}
                    </Tag>
                  );
                })}
              </Container>
            </Grid>
            <Grid
              xs={12}
              sm={6}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <H3
                mb={30}
                fz={24}
                lh={28}
                weight={400}
                sx={{ display: { xs: 'none', sm: 'block' } }}
              >
                {t('ALL_AVAILABLE_PERMISSIONS')}
              </H3>
              <Container>
                {modulePermissions?.allIDs
                  .filter((id) => !(permissions?.allIDs || []).includes?.(id))
                  .map((id, i) => {
                    return (
                      <Tag
                        data-test-id={testId(
                          DATA_TEST_IDS.permissionsEditablePermissionsPage +
                            '_tag' +
                            i
                        )}
                        disableRipple={false}
                        key={id}
                        onClick={
                          hasPermission(
                            CompanyPermissionName.ModuleEditCustomRole
                          )
                            ? () => addPermission(id)
                            : undefined
                        }
                      >
                        {modulePermissions.byID?.[id]?.description}
                      </Tag>
                    );
                  })}
              </Container>
            </Grid>
          </Grid>
        </Box>
      </WhiteBox>
    </>
  );
};

const Container = styled('div')(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; 
    flex: 1 1 auto;
    gap: 10px;
    border: 1px solid ${theme.colors.all.grey[300]};
    padding: 24px 12px;
    box-sizing: border-box;
    .mobile-layout & {
        border: none;
        padding: 0;
    }
`
);
