import { useCompaniesState } from '@/store/companies/hooks/useCompaniesState';
import { useParams } from '@/routes/appRoutes';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import {
  companiesActions as actions,
  FormNames,
} from '@/store/companies/slice';
import { NEW_ROLE_ID_STUB } from '@/features/module/constants.ts';

export const useNewRoleForm = () => {
  const state = useCompaniesState();
  const { moduleId } = useParams();
  const dispatch = useDispatch();

  const callbacks = useMemo(() => {
    return {
      resetRoleErrors: () => {
        dispatch(
          actions.setForm({
            forms: {
              [FormNames.NewRole]: {
                errors: null,
                state: 'active',
              },
            },
            merge: true,
          })
        );
      },
      createNewRole() {
        dispatch(
          actions.setForm({
            forms: {
              [FormNames.NewRole]: {
                errors: null,
                state: 'active',
              },
            },
            merge: false,
          })
        );
      },
      rollbackCreateNewRoleFormData: () => {
        dispatch(actions.resetForm({ formName: FormNames.NewRole }));
        if (moduleId) {
          dispatch(
            actions.deleteRoles({
              moduleId,
              ids: [NEW_ROLE_ID_STUB],
            })
          );
        }
      },
    };
  }, [dispatch, moduleId]);

  const form = state.forms?.[FormNames.NewRole];

  return {
    roleCreationErrors: state.forms?.[FormNames.NewRole]?.errors,
    createNewRoleFormIsShown:
      form?.state === 'active' || form?.state === 'failed',
    newRoleData: moduleId ? state.roles?.[moduleId]?.byID?.new : null,
    ...callbacks,
  };
};
