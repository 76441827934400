import { TabType } from '@/components/TabPanel/useRouteTabs';
import { CompanyDetailsTabId } from '@/features/company-details/CompanyDetailsTabId';
import { FC, useMemo } from 'react';
import { useTranslate } from '@/i18n';
import {
  AppRoutes,
  generatePath,
  ParamNames,
  useParams,
} from '@/routes/appRoutes';
import { useCompanyPermissionsValidator } from '@/store/permissions/useCompanyPermissionsValidator';
import { DescriptionSection } from '@/features/company-details/DescriptionSection';
import { NPIPracticeAddresses } from '@/features/company-addresses/NPIPracticeAddresses';
import { ADSection } from '@/features/company-details/ADSection';
import { Insurance } from '@/features/company-details/insurance';
import { CompanyNpi } from '@/features/company-npi';
import { CompanyPermissionName } from '@/types';

export interface TabsContent extends TabType {
  label: string;
  id: CompanyDetailsTabId;
  Component: FC;
}

export const useTabsContent = (params: { npi?: number }) => {
  const { t } = useTranslate('companies');
  const { companyId } = useParams();
  const has = useCompanyPermissionsValidator(true);

  const allTabs: Record<
    CompanyDetailsTabId,
    { id: CompanyDetailsTabId; Component: FC; path: string; label: string }
  > = useMemo(
    () => ({
      [CompanyDetailsTabId.MobileRoot]: {
        label: 'MOBILE_ROOT',
        id: CompanyDetailsTabId.MobileRoot,
        Component: () => null,
        path: generatePath(AppRoutes.COMPANY_DETAILS, {
          [ParamNames.CompanyDetailsSection]: CompanyDetailsTabId.MobileRoot,
          [ParamNames.CompanyId]: companyId,
        }),
      },
      [CompanyDetailsTabId.CompanyName]: {
        label: t('COMPANY_NAME'),
        id: CompanyDetailsTabId.CompanyName,
        Component: DescriptionSection,
        path: generatePath(AppRoutes.COMPANY_DETAILS, {
          [ParamNames.CompanyDetailsSection]: CompanyDetailsTabId.CompanyName,
          [ParamNames.CompanyId]: companyId,
        }),
      },
      [CompanyDetailsTabId.PracticeAddresses]: {
        label: t('PRACTICE_ADDRESSES'),
        id: CompanyDetailsTabId.PracticeAddresses,
        Component: NPIPracticeAddresses,
        path: generatePath(AppRoutes.COMPANY_DETAILS, {
          [ParamNames.CompanyDetailsSection]:
            CompanyDetailsTabId.PracticeAddresses,
          [ParamNames.CompanyId]: companyId,
        }),
      },
      [CompanyDetailsTabId.ExternalAuth]: {
        label: t('EXTERNAL_AUTHENTICATION'),
        id: CompanyDetailsTabId.ExternalAuth,
        Component: ADSection,
        path: generatePath(AppRoutes.COMPANY_DETAILS, {
          [ParamNames.CompanyDetailsSection]: CompanyDetailsTabId.ExternalAuth,
          [ParamNames.CompanyId]: companyId,
        }),
      },
      [CompanyDetailsTabId.InsuranceCatalog]: {
        label: t('INSURANCE_CATALOG'),
        id: CompanyDetailsTabId.InsuranceCatalog,
        Component: Insurance,
        path: generatePath(AppRoutes.COMPANY_DETAILS, {
          [ParamNames.CompanyDetailsSection]:
            CompanyDetailsTabId.InsuranceCatalog,
          [ParamNames.CompanyId]: companyId,
        }),
      },
      [CompanyDetailsTabId.NPI]: {
        label: t('NPI'),
        id: CompanyDetailsTabId.NPI,
        Component: CompanyNpi,
        path: generatePath(AppRoutes.COMPANY_DETAILS, {
          [ParamNames.CompanyDetailsSection]: CompanyDetailsTabId.NPI,
          [ParamNames.CompanyId]: companyId,
        }),
      },
    }),
    [companyId, t]
  );

  return useMemo(() => {
    const tabsContent: TabsContent[] = [];

    tabsContent.push(allTabs[CompanyDetailsTabId.CompanyName]);
    tabsContent.push(allTabs[CompanyDetailsTabId.PracticeAddresses]);
    if (has(CompanyPermissionName.ExternalAuthenticationConfigure)) {
      tabsContent.push(allTabs[CompanyDetailsTabId.ExternalAuth]);
    }
    if (has(CompanyPermissionName.CompanyManageInsuranceCarrier)) {
      tabsContent.push(allTabs[CompanyDetailsTabId.InsuranceCatalog]);
    }

    if (params.npi) tabsContent.push(allTabs[CompanyDetailsTabId.NPI]);

    return {
      tabsContent,
      allTabs,
    };
  }, [allTabs, has, params.npi]);
};
