/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { Box, BoxProps, ButtonProps, IconButton, styled } from '@mui/material';
import { PanTool, RotateRight } from '@mui/icons-material';
import { inheritDataTestId } from '@/utils/testId.ts';

export const CornerHandle: FC<
  { rotate?: number; className?: string } & BoxProps
> = ({ rotate = 0, className, ...restProps }) => {
  const handleSize = 30;
  const thickness = 8;
  return (
    <Box
      className={className}
      sx={{
        transform: `rotate(${rotate}deg)`,
        width: handleSize,
        height: handleSize,
        margin: `-${thickness / 2}px`,
        position: 'absolute',
      }}
      {...restProps}
    >
      <Box
        width={'100%'}
        height={thickness}
        sx={{
          background: 'white',
          position: 'absolute',
        }}
      />
      <Box
        height={'100%'}
        width={thickness}
        sx={{
          background: 'white',
          position: 'absolute',
        }}
      />
    </Box>
  );
};

export const EdgeHandle = styled(Box)`
  position: absolute;
`;

EdgeHandle.defaultProps = {
  width: 8,
};

export const ToolsHandle: FC<ButtonProps> = ({
  onTouchStart,
  onMouseDown,
  ...props
}) => {
  return (
    <Box
      width={'100%'}
      height={'100%'}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '12%',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          id={'rotate'}
          sx={{
            pointerEvents: 'auto',
            backgroundColor: '#0000002b',
            width: 45,
            height: 45,
          }}
          onTouchStart={onTouchStart}
          onMouseDown={onMouseDown}
          data-test-id={inheritDataTestId(props, 'rotate-button')}
        >
          <RotateRight
            sx={{
              width: 23,
              height: 'auto',
              minHeight: '20px',
              fill: 'white',
            }}
          />
        </IconButton>
        <IconButton
          id={'pan'}
          sx={{
            pointerEvents: 'auto',
            backgroundColor: '#0000002b',
            width: 45,
            height: 45,
          }}
          onTouchStart={onTouchStart}
          onMouseDown={onMouseDown}
          data-test-id={inheritDataTestId(props, 'pan-button')}
        >
          <PanTool
            sx={{
              width: 18,
              marginLeft: -3,
              height: 'auto',
              minHeight: '20px',
              fill: 'white',
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};
