import React, { FC } from 'react';
import { Field } from 'formik';
import { useFormikError } from '@/components/FormikRedux/useFormikError';
import {
  CheckboxControl,
  CheckboxControlProps,
} from '@/components/CheckboxControl';

export const CheckboxControlF: FC<
  Omit<CheckboxControlProps, 'name'> & {
    name: string;
    submitCount: number;
    error?: boolean;
  }
> = ({ submitCount, ...restProps }) => {
  const error = useFormikError(restProps.name, submitCount);
  return <Field as={CheckboxControl} {...restProps} error={error} />;
};
