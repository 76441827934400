import React, { FC } from 'react';
import { GlobalStyles, Stack, Typography, Box, Theme } from '@mui/material';
import { useInitVivicaLoader } from '@/hooks/useVivicaLoader';
import { alpha } from '@/theme/utils';
import Image404 from './404.svg?react';
import { useTranslate } from '@/i18n/useTranslate';
import { Trans } from 'react-i18next';
import { Button } from '@/components';
import { globalStyles } from './styles';
import { useMQuery } from '@/hooks/useMQuery';
import { AppRoutes } from '@/routes/appRoutes';
import { Link } from 'react-router-dom';
import { HeaderStripeView } from '@/features/layouts/MainLayout/fragments/HeaderStripe';
import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

const styles = {
  container: { height: '100vh' },
  header: {
    position: 'relative',
    '.tablet-layout &, .mobile-layout &': { padding: 20 },
  },
  body: (t: Theme) => ({
    flex: '1 1 auto',
    backgroundColor: alpha(t.palette.secondary.main, 7),
  }),
  whiteBox: {
    flex: '1 1 auto',
    margin: { xs: 24, sm: 36 },
    backgroundColor: '#fff',
    padding: 12,
    paddingTop: { xs: '16vh' },
  },
  content: {
    alignItems: 'center',
  },
  lineWithButton: {
    gap: 12,
    alignItems: 'center',
  },
  textContent: {
    alignItems: 'center',
    textAlign: 'center',
  },
};

export const NotFound: FC = () => {
  useInitVivicaLoader(true);
  const { mobile, tablet, desktop } = useMQuery();
  const { t, ready } = useTranslate('common');
  const textVariant = desktop
    ? '22_26_500'
    : tablet
      ? '18_24_500'
      : '14_18_500';
  return (
    <>
      <GlobalStyles styles={globalStyles} />
      <Stack sx={styles.container}>
        <HeaderStripeView sx={styles.header} />
        <Stack sx={styles.body}>
          <Stack sx={styles.whiteBox}>
            <Stack sx={styles.content} gap={48}>
              <Stack height={{ xs: 113, sm: 164 }}>
                <Box component={Image404} height={'100%'} />
              </Stack>
              {ready && (
                <Stack gap={12} sx={styles.textContent}>
                  <Typography variant={mobile ? '24_34_700' : '36_38_700'}>
                    {t('PAGE_NOT_FOUND')}
                  </Typography>
                  <Typography variant={textVariant}>
                    {t('SORRY_THE_PAGE_YOU_ARE_LOOKING_')}
                  </Typography>
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    sx={styles.lineWithButton}
                  >
                    <Trans>
                      <Typography variant={textVariant}>
                        TRY_TO_RETURN_TO_THE_PREVIOUS_PAGE_OR_GO_TO_
                      </Typography>
                      <Button
                        data-test-id={testId(
                          DATA_TEST_IDS.page404 + '_button-vivica-passport'
                        )}
                        size={'sm'}
                        component={Link}
                        to={{
                          pathname: AppRoutes.PROFILE,
                          search: window.location.search,
                        }}
                      >
                        VIVICA_PASSPORT
                      </Button>
                    </Trans>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
