/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { BoxBody, BoxHeaderDeprecated, WhiteBox } from '@/components';
import { DocsTable } from './styles';
import { css as _, Theme, Typography } from '@mui/material';
import { TranslationKeys } from '@/i18n/TranslationKeys';
import { Dl } from '@/components/Dl';
import { tableFullWidthPaddingsStyle } from '../../components/Table/tableFullWidthPaddingsStyle';
import { useDocuments } from '@/store/profile/apiHooks';
import { useMQuery } from '@/hooks/useMQuery';
import { EMPTY } from '@/constants';

import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

export const Documents: FC = () => {
  const { t } = useTranslate('profile');
  const { responseData: documents } = useDocuments();
  const { desktop } = useMQuery();
  if (!documents?.length) {
    return null;
  }

  return (
    <WhiteBox>
      <BoxHeaderDeprecated>{t('PERSONAL_DOCUMENTS')}</BoxHeaderDeprecated>
      <BoxBody
        marginX={{ xs: 0, lg: -36 }}
        css={_`margin-bottom: 0!important;`}
      >
        <DocsTable
          data-test-id={testId(
            DATA_TEST_IDS.profileDocuments + '_personal-docs-table'
          )}
          css={[
            dlAdditionalStyles,
            desktop ? tableFullWidthPaddingsStyle : undefined,
          ]}
        >
          <tbody>
            {documents?.map((doc, i) => {
              return (
                <tr
                  data-test-id={testId(
                    DATA_TEST_IDS.profileDocuments + '_row-docs' + i
                  )}
                  key={doc.id}
                >
                  <td
                    data-test-id={testId(
                      DATA_TEST_IDS.profileDocuments + '_cell-doc-type' + i
                    )}
                  >
                    <Typography
                      fontSize={{ xs: '18px', lg: '16px' }}
                      lineHeight={'24px'}
                      fontWeight={700}
                      component={'h3'}
                      marginBottom={{ sm: 18, lg: 0 }}
                    >
                      {t(doc.type as keyof TranslationKeys['common'])}
                    </Typography>
                  </td>
                  <td
                    data-test-id={testId(
                      DATA_TEST_IDS.profileDocuments + '_cell-doc-number' + i
                    )}
                  >
                    <Dl css={_`flex-wrap: nowrap`}>
                      <dt
                        css={(t) =>
                          _`${t.breakpoints.up(
                            'lg'
                          )} {&:after {content: ''!important;}}`
                        }
                      >
                        #
                      </dt>
                      <dd>{doc.number || '\u2015'}</dd>
                    </Dl>
                    {'countryCode' in doc ? (
                      <Dl>
                        <dt>{t('COUNTRY_CODE')}</dt>
                        <dd>{doc?.countryCode || EMPTY}</dd>
                      </Dl>
                    ) : null}
                  </td>
                  <td
                    data-test-id={testId(
                      DATA_TEST_IDS.profileDocuments + '_cell-doc-date' + i
                    )}
                  >
                    {'issueDate' in doc ? (
                      <Dl>
                        <dt>{t('ISSUED_DATE')}</dt>
                        <dd>{doc?.issueDate || EMPTY}</dd>
                      </Dl>
                    ) : null}
                    {'expirationDate' in doc ? (
                      <Dl>
                        <dt>{t('EXPIRATION_DATE')}</dt>
                        <dd>{doc?.expirationDate || EMPTY}</dd>
                      </Dl>
                    ) : null}
                    {'residentSince' in doc ? (
                      <Dl>
                        <dt>{t('RESIDENT_SINCE')}</dt>
                        <dd>{doc.residentSince || EMPTY}</dd>
                      </Dl>
                    ) : null}
                  </td>
                  <td
                    data-test-id={testId(
                      DATA_TEST_IDS.profileDocuments + '_cell-doc-surname' + i
                    )}
                  >
                    {'surname' in doc ? (
                      <Dl>
                        <dt>{t('SURNAME')}</dt>
                        <dd>{doc.surname || EMPTY}</dd>
                      </Dl>
                    ) : null}
                  </td>
                  <td
                    data-test-id={testId(
                      DATA_TEST_IDS.profileDocuments +
                        '_cell-doc-given-names' +
                        i
                    )}
                  >
                    {'givenNames' in doc ? (
                      <Dl>
                        <dt>{t('GIVEN_NAMES')}</dt>
                        <dd>{doc.givenNames || EMPTY}</dd>
                      </Dl>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </DocsTable>
      </BoxBody>
    </WhiteBox>
  );
};

const dlAdditionalStyles = (t: Theme) => _`
  dl {
    margin-bottom: 6px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  dd, dt {
    font-size: 16px;
    line-height: 24px;
  }
  ${t.breakpoints.up('desktop')} {
    dt {
      font-weight: 400;
      &:after {
        content: ':';

      }
    }
  }
`;
