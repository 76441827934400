const DATA_TEST_IDS = {
  profileDocuments: 'profile__documents',
  profileProfileAvatar: 'profile__profile-avatar',
  profileProfileInfo: 'profile__profile-info',
  profileUploadAvatarDialog: 'profile__upload-avatar-dialog',
  profileMainProfilePage: 'profile__main-profile-page',
  profileHeader: 'profile__profile-header',
} as const;

export default DATA_TEST_IDS;
