import { AnyAction } from 'redux';
import { Statuses } from '@/api/client/types';
import { _setBaseError, _setProgress, _setValidationErrors } from './slice';
import { RequiredAny } from 'ui-kit/lib/types';

// TODO [tech-debt] applied RequiredAny just because all saga code is now legacy - we need to refactor it
export const skipErrorHandler = (
  _error: RequiredAny,
  action?: RequiredAny
): AnyAction[] => (action ? [action] : []);

export const baseErrorHandler = (
  error: RequiredAny,
  action: RequiredAny
): AnyAction[] => {
  const progress = _setProgress({
    path: action.payload.path,
    success: false,
    fetching: false,
  });
  switch (error?.response?.status) {
    case Statuses.BAD_REQUEST: {
      if (error?.response?.data?.fieldErrors || error?.response?.data?.error) {
        return [
          _setValidationErrors({
            errors: error.response.data,
            path: action.payload.path,
          }),
          progress,
        ];
      }
      break;
    }
    case Statuses.UNAUTHORIZED:
      // redirect to login page will be made by means of global api client interceptor
      return skipErrorHandler(error);
    default: {
      if (error?.response?.status && error?.response?.statusText) {
        return [
          _setBaseError({
            status: error.response.status,
            statusText: error.response.statusText,
          }),
          progress,
        ];
      }
      return [
        _setBaseError({ status: 0, statusText: 'Unknown Error' }),
        progress,
      ];
    }
  }
  console.error('[BaseErrorHandler] Error', error);
  return skipErrorHandler(error, progress);
};
