/** @jsxImportSource @emotion/react */
import React, { memo, PropsWithChildren } from 'react';
import IconSVGTimes from 'ui-kit/lib/icons/24/Action/Close';
import IconCheckmark from 'ui-kit/lib/icons/24/System/Check';
import { Tooltip } from 'ui-kit';
import { ButtonBase, ButtonBaseProps, css, Theme } from '@mui/material';
import { alpha } from '@/theme/utils';
import classNames from 'classnames';
import { inheritDataTestId, INHERITED_DATA_TEST_ID } from '@/utils/testId.ts';

const tagStyles = (type?: 'active') => (theme: Theme) => css`
  display: inline-flex;
  height: 32px;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;
  max-width: 360px;
  width: fit-content;
  font-weight: 500;
  font-size: 14px;
  background-color: ${type === 'active'
    ? alpha(theme.palette.secondary.main, 7)
    : theme.palette.grey[200]};
  color: ${type === 'active'
    ? theme.palette.secondary.main
    : theme.palette.grey[400]};
  position: relative;
  svg,
  path {
    fill: ${type === 'active'
      ? theme.palette.secondary.main
      : theme.palette.grey[400]};
  }
  &:hover {
    background-color: ${type === 'active'
      ? alpha(theme.palette.secondary.main, 14)
      : theme.palette.grey[200]};
    color: ${type === 'active'
      ? theme.palette.secondary.main
      : theme.palette.grey[700]};
    svg,
    path {
      fill: ${type === 'active'
        ? theme.palette.secondary.main
        : theme.palette.grey[700]};
    }
  }
  &:active,
  &.pressed {
    background-color: ${type === 'active'
      ? alpha(theme.palette.secondary.main, 21)
      : theme.palette.grey[300]};
    color: ${type === 'active'
      ? theme.palette.secondary.dark
      : theme.palette.grey[700]};
    svg,
    path {
      fill: ${type === 'active'
        ? theme.palette.secondary.dark
        : theme.palette.grey[700]};
    }
  }
  .mobile-layout &,
  .tablet-layout & {
    max-width: 100%;
  }
`;
const btStyles = css`
  cursor: pointer;
  border: none;
  background: none;
  width: 32px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
`;

export interface TagProps extends Omit<ButtonBaseProps, 'type'> {
  onClick?: () => void;
  title?: string;
  type?: 'active' | undefined;
  icon?: JSX.Element;
  selected?: boolean;
  className?: string;
  hideIcon?: boolean;
}
export const Tag = memo(
  ({
    children,
    onClick,
    title,
    icon,
    className,
    type,
    selected,
    hideIcon,
    disableRipple = true,
    ...props
  }: PropsWithChildren<TagProps>) => {
    const component = (
      <ButtonBase
        data-test-id={INHERITED_DATA_TEST_ID}
        className={classNames(className, selected ? 'pressed' : undefined)}
        css={tagStyles(type)}
        onClick={onClick}
        disableRipple={disableRipple}
        {...props}
      >
        <div
          css={css`
            padding: 0 ${onClick && !hideIcon ? 38 : 10}px 0 10px;
            box-sizing: border-box;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
          `}
        >
          {children}
        </div>
        {onClick && !hideIcon && (
          <div css={btStyles}>
            {(icon ?? type !== 'active') ? (
              <IconCheckmark size={18} />
            ) : (
              <IconSVGTimes size={18} />
            )}
          </div>
        )}
      </ButtonBase>
    );

    if (title) {
      return (
        <Tooltip
          title={title}
          placement={'top'}
          data-test-id={inheritDataTestId(props, 'tooltip')}
        >
          {component}
        </Tooltip>
      );
    }
    return component;
  }
);
Tag.displayName = 'Tag';
