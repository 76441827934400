import { identityApiClient } from '@/api/client/ApiClient';
import {
  RecoveryConfirmParams,
  RegistrationParams,
  SendCodeParams,
} from '@/features/authentication/common/types';
import { ResendTime } from '@/types';

export const register = (
  params: RegistrationParams
): Promise<{ resendTime: number }> => {
  return identityApiClient
    .post<ResendTime>('/api/v1/registration', params)
    .then((response) => {
      return response?.data;
    });
};
export const sendRegistrationCode = async (params: SendCodeParams) => {
  await identityApiClient
    .post<undefined>('/api/v1/registration/confirm', params)
    .then((response) => response?.data);
};
export const sendLoginCode = async (params: SendCodeParams) => {
  await identityApiClient
    .post<undefined>('/api/v1/auth/2fa', params)
    .then((response) => response?.data);
};
export const sendRecoveryCode = async (params: RecoveryConfirmParams) => {
  await identityApiClient
    .post<undefined>('/api/v1/recovery/phone/confirm', params)
    .then((response) => response?.data);
};
export const sendRecoveryCodeEmail = async (params: {
  code: string;
  email: string;
}) => {
  await identityApiClient
    .post<undefined>('/api/v1/recovery/email/confirm', params)
    .then((response) => response?.data);
};
export const checkAccountExists = async (params: {
  recaptcha: string;
  phone: string;
}): Promise<void> => {
  await identityApiClient.post<ResendTime>(
    '/api/v1/recovery/phone/check',
    params
  );
};
export const sendNewPassword = (params: {
  phone: string;
  password: string;
  recaptcha: string;
}): Promise<ResendTime> => {
  return identityApiClient
    .post<ResendTime>('/api/v1/recovery/phone', params)
    .then((response) => response?.data);
};

export const checkAccountExistsEmail = async (params: {
  email: string;
  recaptcha: string;
}) => {
  await identityApiClient.post<ResendTime>(
    '/api/v1/recovery/email/check',
    params
  );
};
export const sendNewPasswordEmail = (params: {
  email: string;
  password: string;
  recaptcha: string;
}) => {
  return identityApiClient
    .post<ResendTime>('/api/v1/recovery/email', params)
    .then((response) => response?.data);
};

export const logout = () => {
  return identityApiClient.post('/api/v1/auth/logout');
};

export * as authApi from './';
