import React, { FC, useState } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { InfiniteScrollContainer, Nothing } from '@/components';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { UserName } from '@/components/UserName';
import { getName } from '@/utils/getName';
import IconSVGCheckmark from 'ui-kit/lib/icons/24/Warning/Success';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@/components/Accordion';
import { DotAccountStatus } from '@/features/fragments/DotAccountStatus';
import { CompanyAccountStatus } from '@/features/fragments/CompanyAccountStatus';
import { Dl } from '@/components/Dl';
import { PillButton, PillsContainer } from '@/components/Pills';
import { PhonesWithTooltip } from '@/components/PhonesDisplayWithTooltip';
import { UsersListProps } from '@/features/module/users-list/fragments/TabletList';

import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

export const MobileList: FC<UsersListProps> = ({
  moduleUsers,
  companyUsers,
  addUser,
  removeUser,
  loadMoreCompanyUsers,
  loadMoreModuleUsers,
  roleUsersListLabel,
  showRevokeButton,
  showAddButton,
}) => {
  const { t } = useTranslate('companies');
  const [type, setType] = useState<'company' | 'module'>('company');
  const users = type === 'company' ? companyUsers : moduleUsers;
  const loadMore =
    type === 'company' ? loadMoreCompanyUsers : loadMoreModuleUsers;
  // todo: [tech-debt] buttons 'selected' 'unselected' have weird conditions for selected props

  return (
    <Box>
      <Typography variant={'18_24_500'} mb={36}>
        {roleUsersListLabel ?? '...'}
      </Typography>
      <Stack
        direction={'row'}
        sx={{
          width: '100%',
          marginBottom: { xs: 24 },
        }}
      >
        <PillsContainer>
          <PillButton
            data-test-id={testId(
              DATA_TEST_IDS.fragmentsMobileList + '_pill-button-selected'
            )}
            selected={type !== 'company'}
            onClick={() => setType('module')}
          >
            {t('SELECTED')}
          </PillButton>
          <PillButton
            data-test-id={testId(
              DATA_TEST_IDS.fragmentsMobileList + '_pill-button-unselected'
            )}
            selected={type !== 'module'}
            onClick={() => setType('company')}
          >
            {t('UNSELECTED')}
          </PillButton>
        </PillsContainer>
      </Stack>
      {users?.allIDs?.length ? (
        <Box marginX={-18}>
          <InfiniteScrollContainer onLoad={loadMore}>
            {users?.allIDs?.map((id, index) => {
              const user = users.byID[id];
              return (
                <Accordion
                  data-test-id={testId(
                    DATA_TEST_IDS.fragmentsMobileList + '_accordion-row' + index
                  )}
                  key={id}
                  sx={styles.accordion}
                >
                  <AccordionSummary
                    data-test-id={testId(
                      DATA_TEST_IDS.fragmentsMobileList +
                        '_accordion-summary' +
                        index
                    )}
                    sx={styles.accSummary}
                  >
                    <Stack direction={'row'} sx={styles.nameAndStatus}>
                      <DotAccountStatus
                        sx={{ margin: 0 }}
                        status={user?.accountStatus}
                      />
                      <UserName
                        data-test-id={testId(
                          DATA_TEST_IDS.fragmentsMobileList +
                            '_user-name' +
                            index
                        )}
                        avatarSrc={user?.avatarUrl}
                        lines={2}
                      >
                        {getName(user)}
                      </UserName>
                    </Stack>
                    <>
                      {type === 'company' ? (
                        showAddButton(user) && (
                          <IconButton
                            data-test-id={testId(
                              DATA_TEST_IDS.fragmentsMobileList +
                                '_button-add' +
                                index
                            )}
                            onClick={() => addUser(id)}
                          >
                            <Box
                              component={IconSVGCheckmark}
                              sx={{
                                color: (t) => t.palette.grey[400],
                              }}
                            />
                          </IconButton>
                        )
                      ) : (
                        <>
                          {showRevokeButton(user) && (
                            <IconButton
                              data-test-id={testId(
                                DATA_TEST_IDS.fragmentsMobileList +
                                  '_button-revoke' +
                                  index
                              )}
                              onClick={() => removeUser(id)}
                            >
                              <Box
                                component={IconSVGCheckmark}
                                sx={{
                                  color: (t) => t.palette.alert.success,
                                }}
                              />
                            </IconButton>
                          )}
                        </>
                      )}
                    </>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack sx={styles.accDetails}>
                      <Dl>
                        <dt>{t('PHONE')}</dt>
                        <dd>
                          <PhonesWithTooltip phones={user?.phones} />
                        </dd>
                      </Dl>
                      <Dl>
                        <dt>{t('COMPANY_STATUS')}</dt>
                        <dd>
                          <CompanyAccountStatus
                            data-test-id={testId(
                              DATA_TEST_IDS.fragmentsMobileList +
                                '_company-acc-status' +
                                index
                            )}
                            status={user?.accountCompanyStatus}
                            inviteExpiredTime={user?.inviteExpiredTime}
                          />
                        </dd>
                      </Dl>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </InfiniteScrollContainer>
        </Box>
      ) : (
        <Nothing lh={38}>{t('NO_USERS')}</Nothing>
      )}
    </Box>
  );
};

const styles = {
  accordion: {
    '.MuiAccordionSummary-expandIconWrapper button': {
      margin: '0!important',
      padding: 9,
    },
    '.MuiAccordionSummary-root': { gap: 6 },
  },
  accSummary: {
    '.MuiAccordionSummary-content': {
      justifyContent: 'space-between',
    },
  },
  accDetails: { gap: 24, pb: 10, dl: { margin: 0 } },
  nameAndStatus: {
    gap: 10,
    alignItems: 'center',
    flex: '1 1 auto',
    minWidth: 0,
  },
};
