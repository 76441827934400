import { getError, getErrors } from '@/store/common/error-handlers';
import { SagaPayload, SagaReturn } from '@/store/common/types';
import { AccountDetails, CodeType, Phone, PhoneType } from '@/types';
import { getRecaptchaToken } from '@/hooks/useRecaptcha';
import { produce } from 'immer';
import { all, call, put, select, takeLeading } from 'redux-saga/effects';
import { tryCatchSaga } from '@/store/sagas';
import { RootState } from '@/store/store';
import { accountApi as api, codeApi } from '@/api';
import { PayloadActions } from '@/features/profile/phones-and-emails/types';
import { baseErrorHandler } from './error-handlers';
import { phoneEmailsActions as actions, PhonesState } from './slice';
import { profileActions } from '@/store/profile/slice';
import { showError500OrUnknownToast } from '@/store/common/showError500OrUnknownToast';
import { ValidationErrorType } from '@/types/ValidationError';
import { isAxiosError } from 'axios';

export const selectProfileData = (
  state: RootState
): AccountDetails | undefined => state.profile.accountDetails;
const tryCatchSagaOptions = {
  withProgress: true,
  updateProgressAction: actions.setIsFetching,
  handleError: baseErrorHandler,
};
export function* phonesSaga() {
  yield all([
    takeLeading(actions.requestGetPhones, getPhones),
    takeLeading(actions.requestGetNPIPhones, getNPIPhones),
    takeLeading(actions.requestDeletePhone, deleteMobilePhone),
    takeLeading(actions.requestSetAllowDelivery, allowResultsDelivery),
    takeLeading(actions.requestSetPhoneAsMain, makePhoneAsMain),
    takeLeading(
      actions.requestSavePhone,
      tryCatchSaga(requestSavePhone, tryCatchSagaOptions)
    ),
    takeLeading(
      actions.requestConfirmMobilePhone,
      tryCatchSaga(requestConfirmMobilePhone, tryCatchSagaOptions)
    ),
    takeLeading(
      actions.requestResendPinCode,
      tryCatchSaga(resendPinCode, tryCatchSagaOptions)
    ),
    takeLeading(
      actions.requestPhoneConfirmationPinCode,
      requestPhoneConfirmationPinCode
    ),
    takeLeading(
      actions.requestConfirmPhoneOwnership,
      requestConfirmPhoneOwnership
    ),
  ]);
}

// saga actions

function* requestPhoneConfirmationPinCode({
  payload,
}: SagaPayload<typeof actions.requestPhoneConfirmationPinCode>): SagaReturn {
  try {
    yield put(
      actions.setForm({
        form: {
          formType: 'confirmOwnership',
          state: 'init',
          phoneId: payload.phoneId,
          resendTime: undefined,
        },
      })
    );
    const recaptcha = yield call(getRecaptchaToken);
    const {
      resendTime,
    }: Awaited<ReturnType<typeof api.phoneSendConfirmation>> = yield call(
      api.phoneSendConfirmation,
      { phoneId: payload.phoneId, recaptcha }
    );
    yield put(
      actions.setForm({
        form: {
          formType: 'confirmOwnership',
          state: 'edit',
          phoneId: payload.phoneId,
          resendTime,
        },
      })
    );
  } catch (e) {
    console.error('[requestPhoneConfirmationPinCode]', e);
    showError500OrUnknownToast(e);
    yield put(actions.setForm({ form: undefined }));
  }
}

function* requestConfirmPhoneOwnership({
  payload,
}: SagaPayload<typeof actions.requestConfirmPhoneOwnership>): SagaReturn {
  try {
    const recaptcha = yield call(getRecaptchaToken);
    const phone: Awaited<ReturnType<typeof api.phoneConfirmOwnership>> =
      yield call(api.phoneConfirmOwnership, {
        phoneId: payload.phoneId,
        recaptcha,
        code: payload.pinCode,
      });
    yield put(actions.setForm({ form: undefined }));
    yield put(actions.setPhones({ phones: [phone] }));
  } catch (e) {
    console.error('[requestConfirmPhoneOwnership]', e);
    const validationErrors = getErrors(e);
    yield put(actions.setForm({ form: { validationErrors }, merge: true }));
  }
}
function* getPhones(): SagaReturn {
  try {
    yield put(actions.setIsFetching(true));
    const phones: Awaited<ReturnType<typeof api.getPhones>> = yield call(
      api.getPhones
    );
    yield put(actions.setPhones({ phones }));
  } catch (e: unknown) {
    console.error('[getPhones saga] Error', e);
    yield put(actions.setErrors({ common: { base: getError(e) } }));
  } finally {
    yield put(actions.setIsFetching(false));
  }
}

function* getNPIPhones(): SagaReturn {
  try {
    yield put(actions.setIsFetching(true));
    const npiPhones: Awaited<ReturnType<typeof api.getNPIPhones>> = yield call(
      api.getNPIPhones
    );
    yield put(actions.setNPIPhones(npiPhones));
  } catch (e) {
    yield put(actions.setErrors({ common: { base: getError(e) } }));
  } finally {
    yield put(actions.setIsFetching(false));
  }
}

function* deleteMobilePhone(
  action: PayloadActions['requestDeletePhone']
): SagaReturn {
  const oldPhones: PhonesState['phones'] = yield select(
    (state: RootState) => state.phones.phones
  );
  const newPhones = produce(oldPhones, (draft) => {
    draft.allIds.splice(draft.allIds.indexOf(action.payload), 1);
  });
  const mobilePhones = newPhones.allIds.map((id: string) => newPhones.byId[id]);
  yield put(actions.setPhones({ phones: mobilePhones, replace: true }));
  try {
    yield call(api.deleteMobilePhone, action.payload);
  } catch (e) {
    console.error('[deleteMobilePhone saga] Error', e);
    const phones = oldPhones.allIds.map((id) => oldPhones.byId[id]);
    yield put(actions.setPhones({ phones, replace: true }));
    yield put(actions.setErrors({ common: { common: getError(e) } }));
  }
}

function* allowResultsDelivery(
  action: PayloadActions['requestSetAllowDelivery']
): SagaReturn {
  const thePhone: Phone = yield select(
    (state: RootState) => state.phones.phones.byId[action.payload.id]
  );
  const newPhone = produce(thePhone, (phone) => {
    if ('allowResultsDelivery' in phone) {
      phone.allowResultsDelivery = action.payload.allow;
    }
  });
  yield put(actions.setPhones({ phones: [newPhone] }));
  try {
    yield call(api.allowResultsDelivery, action.payload);
  } catch (e) {
    yield put(actions.setPhones({ phones: [thePhone] }));
    yield put(actions.setErrors({ common: { common: getError(e) } }));
  }
}

function* makePhoneAsMain(
  action: PayloadActions['requestSetPhoneAsMain']
): SagaReturn {
  const prevState: PhonesState['phones'] = yield select(
    (state: RootState) => state.phones.phones
  );
  const prevMainId = prevState.idOfMain;
  let prevMain: Phone | null = null;
  if (prevMainId) {
    prevMain = { ...prevState.byId[prevMainId] };
  }

  const newMainPhone: Phone = {
    ...prevState.byId[action.payload],
  };
  if (newMainPhone.type === PhoneType.MOBILE_PHONE) {
    newMainPhone.main = true;
  }
  yield put(actions.setIsFetching(true));
  yield put(actions.setPhones({ phones: [newMainPhone] }));
  try {
    yield call(api.makePhoneAsMain, action.payload);
    // replace in profile if successful
    const profile: AccountDetails = yield select(selectProfileData);

    if (profile) {
      const data = produce(profile, (draft) => {
        draft.phone = newMainPhone.phone;
        draft.avatarUrl = undefined;
      });
      yield put(profileActions.setAccountDetails(data));
    }
  } catch (e) {
    console.error('[makePhoneAsMain saga] Error', e);
    if (prevMain) {
      yield put(actions.setPhones({ phones: [prevMain] }));
    }
    yield put(actions.setErrors({ common: { common: getError(e) } }));
  } finally {
    actions.setIsFetching(false);
  }
}

function* requestSavePhone(
  action: PayloadActions['requestSavePhone']
): SagaReturn {
  try {
    const phoneOrResendTime: Awaited<ReturnType<typeof api.savePhone>> =
      yield call(api.savePhone, action.payload);
    if ('resendTime' in phoneOrResendTime) {
      yield put(actions.setResendTime(phoneOrResendTime.resendTime));
      yield put(actions.setEditState('pin-code'));
    } else {
      yield put(actions.setPhones({ phones: [phoneOrResendTime] }));
      yield put(actions.setEditState(null));
    }
  } catch (e) {
    const error = getError(e);
    if (action.payload.source === 'new') {
      yield put(actions.setValidationErrors({ phone: error }));
    } else {
      yield put(actions.setValidationErrors({ npiPhone: error }));
    }
  }
}

function* requestConfirmMobilePhone(
  action: PayloadActions['requestConfirmMobilePhone']
): SagaReturn {
  try {
    const recaptcha: string = yield call(getRecaptchaToken);
    const phone: Awaited<ReturnType<typeof api.confirmMobilePhone>> =
      yield call(api.confirmMobilePhone, { recaptcha, ...action.payload });
    yield put(actions.setPhones({ phones: [phone] }));
    yield put(actions.setEditState(null));
  } catch (e) {
    if (isAxiosError(e) && e.response?.data.error) {
      yield put(actions.setValidationErrors({ code: getError(e) }));
      return;
    }
    yield put(actions.setErrors({ common: { common: getError(e) } }));
  }
}

function* resendPinCode({
  payload,
}: SagaPayload<typeof actions.requestResendPinCode>): SagaReturn {
  const recaptcha: string = yield call(getRecaptchaToken);
  try {
    const { resendTime }: Awaited<ReturnType<typeof codeApi.resendCode>> =
      yield call(codeApi.resendCode, { recaptcha, codeType: payload.codeType });
    if (payload.codeType === CodeType.AddMobilePhone) {
      yield put(actions.setResendTime(resendTime));
    } else {
      yield put(actions.setForm({ merge: true, form: { resendTime } }));
    }
  } catch (e) {
    const err = getError(e);
    if (err === ValidationErrorType.RESEND_CODE_TIMEOUT_EXCEEDED) {
      const formData: PhonesState['form'] = yield select(
        (state: RootState) => state.phones.form
      );
      if (formData && formData?.formType === 'new-phone') {
        yield put(actions.requestSavePhone(formData));
      } else {
        console.error('[resendPinCode] Form data is not found');
      }
    }
    throw e;
  }
}
