import { ModuleType } from '@/api/__generated__/webApi';
import { TKeys, useTranslate } from '@/i18n';
import { UrlToModuleTypeMap, useParams } from '@/routes/appRoutes.ts';

const pageHeaders: Record<ModuleType, TKeys<'companies'>> = {
  [ModuleType.LIS]: 'LIS',
  [ModuleType.PASSPORT]: 'PASSPORT',
  [ModuleType.PROFESSIONAL]: 'PROFESSIONAL',
};
const pageDescriptions: Record<ModuleType, TKeys<'companies'>> = {
  [ModuleType.LIS]:
    'THE_LAB_SERVICE_HELPS_YOU_PERFORM_REGULAR_ANALYSIS_CHECKS_COLLATES',
  [ModuleType.PASSPORT]: 'PASSPORT_PAGE_DESCRIPTION',
  [ModuleType.PROFESSIONAL]: 'PROFESSIONAL_PAGE_DESCRIPTION',
};
export const useModuleName = () => {
  const { module } = useParams();
  const { t } = useTranslate('companies');
  const moduleType = module ? UrlToModuleTypeMap[module] : null;
  return {
    description: moduleType && t(pageDescriptions[moduleType]),
    name: moduleType && t(pageHeaders[moduleType]),
    moduleType,
  };
};
