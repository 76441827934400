import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { TranslationKeys, TranslationNS } from './TranslationKeys';
import { Namespace } from 'i18next';
import { useMemo } from 'react';

export type TKeys<
  T extends TranslationNS = 'validationErrors' | 'common' | 'profile',
> =
  | keyof TranslationKeys[T]
  | keyof TranslationKeys['common']
  | keyof TranslationKeys['validationErrors'];

export const useTranslate = <T extends TranslationNS>(ns: T) => {
  const { t, ready, i18n } = useTranslation(ns);
  return useMemo(() => ({ t, ready, i18n }), [i18n, ready, t]) as {
    t: (
      str: TKeys<T> | undefined | null,
      params?:
        | Record<string, string | number | null | undefined>
        | string
        | undefined
    ) => string;
    ready: boolean;
    i18n: UseTranslationResponse<Namespace, unknown>['i18n'];
  };
};
