import React, { FC } from 'react';
import { useTranslate } from '@/i18n';
import { toast, ToastOptions } from 'react-toastify';

import IconError from 'ui-kit/lib/icons/IconsFilled/24/Alert/Error';
import { Typography } from '@mui/material';
import { customErrorStyles, IconCloseBtn } from '@/components/Toast/Toast.tsx';

// eslint-disable-next-line react-refresh/only-export-components
const ErrorToastContent: FC<{
  header?: string;
  text: string;
}> = ({ header, text }) => {
  const { t } = useTranslate('common');
  return (
    <>
      <Typography variant={'14_18_700'} sx={{ mb: 4 }}>
        {header ?? t('TOAST_ERROR_HEADER')}
      </Typography>
      <Typography variant={'14_18_500'}>{text}</Typography>
    </>
  );
};
export const showErrorToast = (
  data: { header?: string; text: string },
  options?: ToastOptions
) => {
  toast.error(() => <ErrorToastContent {...data} />, {
    type: toast.TYPE.DEFAULT,
    icon: <IconError />,
    closeButton: <IconCloseBtn />,
    className: customErrorStyles,
    toastId: data.text,
    ...options,
  });
  toast.update(data.text, options);
};
