import { useTranslate } from '@/i18n';
import * as yup from 'yup';

export const useValidationSchema = () => {
  const { t } = useTranslate('common');
  return yup.object({
    description: yup
      .string()
      .required(t('FIELD_IS_REQUIRED'))
      .max(255, t('CHARACTER_LIMIT_EXCEEDED')),
  });
};
