import { Button, ButtonProps, Tooltip } from 'ui-kit';
import { FC, PropsWithChildren, ReactNode, useEffect } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { Module, ModuleStatus } from '@/api/__generated__/webApi';
import { useAppDispatch } from '@/store/store';
import { companiesActions } from '@/store/companies/slice';
import { UUID } from '@/types';
import { Stack, SxProps, Theme } from '@mui/material';
import { InfoIcon } from 'ui-kit/lib/icons/24';
import { Link } from 'react-router-dom';
import { inheritDataTestId, INHERITED_DATA_TEST_ID } from '@/utils/testId.ts';

interface GoToModuleButtonProps {
  href?: string;
  children?: ReactNode;
  data?: { companyId: UUID; module: Module };
}

export const GoToModuleButton: FC<GoToModuleButtonProps> = ({
  children,
  href,
  data,
  ...props
}) => {
  const { module, companyId } = data ?? {};
  const dispatch = useAppDispatch();
  const { t } = useTranslate('common');

  useEffect(() => {
    if (!module || !companyId) {
      return;
    }
    if (module.status !== 'SUCCESSFUL') {
      const timerId = setInterval(() => {
        dispatch(
          companiesActions.requestModuleStatus({
            moduleId: module.id,
            companyId,
          })
        );
      }, 1000 * 10);
      return () => clearInterval(timerId);
    }
  }, [companyId, dispatch, module]);
  const disabled = module?.status && module.status !== ModuleStatus.SUCCESSFUL;
  const disabledTooltipText = disabled
    ? module?.status === ModuleStatus.OFF
      ? t('THIS_FEATURE_IS_DISABLED_PLEASE_CONTACT_SUPPORT_TO_ENABLE_IT')
      : t('THIS_COMPONENT_IS_CURRENTLY_BEING_SET_UP_')
    : undefined;
  return (
    <ModuleButton
      href={href}
      disabled={disabled}
      disabledTooltipText={disabledTooltipText}
      data-test-id={INHERITED_DATA_TEST_ID}
      {...props}
    >
      {children}
    </ModuleButton>
  );
};

export const ModuleButton: FC<
  PropsWithChildren<ButtonProps> & { disabledTooltipText?: string }
> = ({ disabled, disabledTooltipText, ...props }) => {
  const renderIcon = () => (
    <Stack sx={styles.icon}>
      <InfoIcon size={18} />
    </Stack>
  );
  return disabled ? (
    <Tooltip
      title={disabledTooltipText}
      placement={'right'}
      sx={styles.tooltip}
      data-test-id={inheritDataTestId(props, 'tooltip')}
    >
      <Button
        data-test-id={INHERITED_DATA_TEST_ID}
        {...props}
        href={undefined}
        color={'secondary'}
        variant={'contained'}
        component={'a'}
        sx={styles.button}
        disabled
        endIcon={renderIcon()}
      />
    </Tooltip>
  ) : (
    <Button
      data-test-id={INHERITED_DATA_TEST_ID}
      {...props}
      to={disabled ? undefined : props.href}
      color={'secondary'}
      variant={'contained'}
      component={Link}
      sx={styles.button}
    />
  );
};

const styles = {
  button: {
    height: 26,
    alignItems: 'center',
    backgroundColor: 'secondary.main',
    color: 'common.white',
    paddingY: 0,
    paddingX: 12,
    borderRadius: '26px',
    fontSize: '14px',
    gap: 0,
    '.MuiButton-icon': {
      marginLeft: 10,
    },
    '&.Mui-disabled': {
      pointerEvents: 'all',
      backgroundColor: (t) => t.palette.btn.solid.bg.disabled,
      color: (t) => t.palette.btn.solid.content.disabled,
    },
  },
  tooltip: { maxWidth: 180 },
  icon: { justifyContent: 'center' },
} satisfies Record<string, SxProps<Theme>>;
