/** @jsxImportSource @emotion/react */
import { memo } from 'react';
import {
  IconView,
  liClassname,
  MenuListItem,
  MenuButton,
  selectedClassName,
  GAP_WIDTH,
} from './styles';
import cn from 'classnames';
import { css as _ } from '@mui/material';
import { To } from 'react-router-dom';
import { inheritDataTestId, INHERITED_DATA_TEST_ID } from '@/utils/testId.ts';

interface SubMenuItemProps {
  title: string;
  subtitle?: string;
  selected?: boolean;
  icon?: JSX.Element;
  showIcon?: boolean;
  onClick?: () => void;
  to?: To;
  exact?: boolean;
  disabled?: boolean;
}

export const SubMenuItem = memo(
  ({
    title,
    selected,
    subtitle,
    icon,
    showIcon,
    onClick,
    to,
    exact,
    disabled,
    ...props
  }: SubMenuItemProps) => {
    return (
      <MenuListItem
        data-test-id={INHERITED_DATA_TEST_ID}
        to={to ?? ''}
        disabled={disabled}
        end={exact}
        tabIndex={0}
        role={'button'}
        onClick={disabled ? () => {} : onClick}
        className={cn({ [selectedClassName]: selected }, liClassname)}
        {...props}
      >
        <MenuButton
          data-test-id={inheritDataTestId(props, 'menu-button')}
          disabled={disabled}
          css={_`min-height: 56px; gap: ${GAP_WIDTH}px`}
          icon={!!icon && showIcon}
        >
          {icon && showIcon && <IconView>{icon}</IconView>}
          <div>
            <div css={_`display: flex; align-items: center; gap: 20px;`}>
              {title}
            </div>
            {subtitle && <p>{subtitle}</p>}
          </div>
        </MenuButton>
      </MenuListItem>
    );
  }
);
SubMenuItem.displayName = 'SubMenuItem';
