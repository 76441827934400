import React, { FC } from 'react';
import { Box, Tab as MuiTab, TabTypeMap } from '@mui/material';
import ArrowRight from 'ui-kit/lib/icons/24/Arrow/ChevronRight';

export const Tab: FC<TabTypeMap<{}>['props']> = (props) => {
  return (
    <MuiTab
      {...props}
      iconPosition={'end'}
      icon={
        <Box
          component={ArrowRight}
          width={24}
          height={18}
          sx={{ path: { stroke: (t) => t.palette.grey[700] } }}
        />
      }
    />
  );
};
