import { useAppDispatch, useAppSelector } from '@/store/store';
import { useMemo, useState } from 'react';
import { confirmChangeAccountPassword } from '@/store/profile-new-password/api';
import { profilePwdChangeActions } from '@/store/profile-new-password/slice';
export const useProfileNewPwdState = () => useAppSelector((s) => s.pwdChange);
export const usePinCodeRequest = () => {
  const dispatch = useAppDispatch();
  const state = useProfileNewPwdState();
  const [success, setSuccess] = useState(false);
  const callbacks = useMemo(() => {
    return {
      async send(data: { code: string; recaptcha: string }) {
        try {
          await dispatch(confirmChangeAccountPassword(data)).unwrap();
          setSuccess(true);
        } catch {
          //
        }
      },
      resetValidationErrors() {
        return dispatch(profilePwdChangeActions.resetValidationErrors());
      },
      reset() {
        dispatch(profilePwdChangeActions.reset());
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
    validationErrors: state.validationErrors,
    success,
  };
};
