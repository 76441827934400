import { createSelector } from '@reduxjs/toolkit';
import { RootState, useAppSelector } from '@/store/store';
import { SelectedPermissions } from '@/features/module/LIMS/types/SelectedPermissions';
import { Permission } from '@/api/__generated__/webApi';
import { useParams } from '@/routes/appRoutes.ts';

export const putToTree = (
  selected: SelectedPermissions,
  permission: Permission
) => {
  if (!selected[permission.action]) {
    selected[permission.action] = {};
  }
  if (!selected[permission.action]?.[permission.group]) {
    selected[permission.action]![permission.group] = [];
  }

  selected[permission.action]![permission.group].push(permission);
};
interface PermissionProps {
  moduleId?: string | undefined;
  companyId?: string | undefined;
  roleId?: string | undefined;
}

const selectPermissions = createSelector(
  // Input selectors:
  (state: RootState) => state.module.selected,
  (state: RootState) => state.module.permissions,
  (state: RootState) => state.module.initializedFor,
  // Third input selector for our extra parameters:
  (_state: RootState, props: PermissionProps) => props,

  // The "result function" that uses the results of the input selectors:
  (selected, permissions, initializedFor, { moduleId, companyId, roleId }) => {
    const initialized =
      moduleId === initializedFor?.moduleId &&
      companyId === initializedFor?.companyId &&
      roleId === initializedFor?.roleId;
    if (!initialized) {
      return {};
    }
    const tree: SelectedPermissions = {};
    for (const uuid of selected) {
      const perm = permissions[uuid];
      putToTree(tree, perm);
    }
    return tree;
  }
);
export const useSelPermissionsSelector = (): SelectedPermissions => {
  const params = useParams();
  return useAppSelector((s) => selectPermissions(s, params));
};
