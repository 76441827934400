import React, { FC } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Nowrap } from '@/components';
import { Avatar } from '@/components/Avatar';
import { Tooltip } from 'ui-kit';
import { inheritDataTestId } from '@/utils/testId.ts';

interface UserNameProps {
  avatarSrc: string | undefined;
  className?: string;
  children?: string;
  lines?: number;
}
const styles = {
  label: {
    typography: { xs: '14_18_500' },
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
  },
  avatar: { flex: '0 0' },
  container: { display: 'flex', alignItems: 'center', gap: 12, minWidth: 0 },
};

export const ListAvatar = styled(Avatar)(({ theme: t }) => ({
  backgroundColor: 'transparent',
  'svg path': { fill: t.palette.grey[600] },
}));
ListAvatar.defaultProps = {
  size: 22,
};
export const UserName: FC<UserNameProps> = ({
  children,
  avatarSrc,
  className,
  lines,
  ...props
}) => {
  return (
    <Box sx={styles.container} className={className}>
      <ListAvatar sx={styles.avatar} src={avatarSrc} />
      <Tooltip
        placement={'bottom'}
        title={children ?? ''}
        data-test-id={inheritDataTestId(props, 'tooltip')}
      >
        <Typography sx={styles.label}>
          <Nowrap lines={lines}>{children}</Nowrap>
        </Typography>
      </Tooltip>
    </Box>
  );
};
