import { useAppDispatch } from '@/store/store';
import { useEffect, useLayoutEffect } from 'react';
import { getLimsTreeItems, getRolePermissions } from '@/store/module/api';
import { useParams } from '@/routes/appRoutes';
import { moduleActions } from '@/store/module/slice';

import { NEW_ROLE_ID_STUB } from '@/features/module/constants';

export const usePageInit = () => {
  const dispatch = useAppDispatch();
  const { moduleId, companyId, roleId } = useParams();

  useLayoutEffect(() => {
    return () => {
      dispatch(moduleActions.resetSelected());
    };
  }, [dispatch]);

  useEffect(() => {
    void (async () => {
      if (moduleId && companyId && roleId) {
        await dispatch(getLimsTreeItems({ moduleId, companyId }));
        if (roleId === NEW_ROLE_ID_STUB) {
          dispatch(moduleActions.setPreSelected([]));
        } else {
          await dispatch(getRolePermissions({ moduleId, companyId, roleId }));
        }
      }
    })();
  }, [companyId, dispatch, moduleId, roleId]);
};
