import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { DateControl, DateControlProps } from '@/components/DateControl';
import { getErrorForDisplay } from '@/components/FormikRedux/useFormikError';

export const DateControlF: FC<
  Omit<DateControlProps, 'name' | 'value'> & { name: string }
> = (props) => {
  return (
    <Field {...props}>
      {({ form, field, meta }: FieldProps) => {
        const e = getErrorForDisplay({
          error: meta.error,
          value: meta.value,
          touched: meta.touched,
          submitCount: form.submitCount,
        });
        return (
          // eslint-disable-next-line props-validator/validate-props
          <DateControl {...props} {...field} error={e} />
        );
      }}
    </Field>
  );
};
