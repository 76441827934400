import { all, call, put, takeLeading } from 'redux-saga/effects';
import { permissionsActions as actions } from './slice';
import { SagaPayload, SagaReturn } from '../common/types';
import { client } from '@/api/client/ApiClient';
export function* permissionSagas() {
  yield all([
    takeLeading(actions.requestAllPermissions, requestAllPermissions),
    takeLeading(
      actions.requestCompanyPermissions,
      requestPermissionsForCompany
    ),
  ]);
}

function* requestAllPermissions(): SagaReturn {
  const {
    data: permissions,
  }: Awaited<ReturnType<typeof client.getAccountCompaniesPermissions>> =
    yield call(client.getAccountCompaniesPermissions);
  yield put(actions.updatePermissions(permissions));
}

function* requestPermissionsForCompany({
  payload,
}: SagaPayload<typeof actions.requestCompanyPermissions>) {
  const {
    data: permissions,
  }: Awaited<ReturnType<typeof client.getAccountCompanyPermissions>> =
    yield call(client.getAccountCompanyPermissions, payload.companyId);
  yield put(actions.updatePermissions([permissions]));
}
