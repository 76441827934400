import { FormikErrors, FormikProps } from 'formik';
import { TKeys, useTranslate } from '@/i18n/useTranslate';
import { useRef, useEffect, useCallback, useState } from 'react';
import { isEmpty } from 'lodash-es';

export const expandMaxErr = (
  str: TKeys | undefined | null
): [TKeys<'validationErrors'>] => {
  const value =
    str && /max_size/i.test(str)
      ? ['MAX_SIZE_', { count: str.match(/max_size_(\d+)/i)?.[1] }]
      : [str];
  return value as [TKeys<'validationErrors'>];
};

export const useTranslatedErrors = <T>(
  errors: Partial<Record<string, TKeys<'validationErrors'>>> | undefined | null
) => {
  const { t } = useTranslate('validationErrors');
  const [translated, setTranslated] = useState<FormikErrors<T> | undefined>(
    undefined
  );
  useEffect(() => {
    if (isEmpty(errors)) {
      setTranslated(undefined);
      return;
    }
    const tmt = setTimeout(() => {
      const translated = Object.entries(errors).reduce<Record<string, string>>(
        (acc, [key, value]) => {
          acc[key] = t(...expandMaxErr(value));
          return acc;
        },
        {}
      );
      setTranslated(translated as FormikErrors<T>);
    });
    return () => {
      clearTimeout(tmt);
    };
  }, [errors, t]);
  return translated;
};

export const useConnectServerValidationErrors = <T>(
  errors: Partial<Record<string, TKeys<'validationErrors'>>> | null | undefined,
  resetSeverErrors: () => void
) => {
  const { t } = useTranslate('validationErrors');
  const ref = useRef<FormikProps<T> | null>(null);
  const translatedErrors = useTranslatedErrors<T>(errors);
  const resetRef = useRef(resetSeverErrors);
  resetRef.current = resetSeverErrors;
  useEffect(() => {
    if (translatedErrors) {
      ref.current?.setErrors(translatedErrors);
      ref.current?.setSubmitting(false);
    }
  }, [translatedErrors, t]);

  const resetErrorsHook = useCallback(() => {
    if (errors && Object.keys(errors).length) {
      resetRef.current?.();
    }
  }, [errors]);

  return {
    innerRef: ref,
    validate: resetErrorsHook,
  };
};
