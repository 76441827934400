import React, { FC, memo } from 'react';
import { MobilePhone } from '@/api/__generated__/webApi';
import { groupPhones } from './groupPhones';
import { PopupPhone } from './PopupPhone';
import { Nowrap } from '@/components';
import { EMPTY } from '@/constants';
import { FormattedPhoneNumber } from 'ui-kit';
import { INHERITED_DATA_TEST_ID } from '@/utils/testId.ts';

export const PhonesWithTooltip: FC<{ phones: MobilePhone[] | undefined }> =
  memo(({ phones, ...props }) => {
    const { firstExistingByPriority: phoneData } = groupPhones(phones);
    return (
      <>
        {phoneData?.phone ? (
          <PopupPhone
            data-test-id={INHERITED_DATA_TEST_ID}
            data={phones}
            {...props}
          >
            <Nowrap>
              <FormattedPhoneNumber>{phoneData.phone}</FormattedPhoneNumber>
            </Nowrap>
          </PopupPhone>
        ) : (
          EMPTY
        )}
      </>
    );
  });
PhonesWithTooltip.displayName = 'PhonesDisplayWithTooltip';
