import { AnyAction } from 'redux';
import { Statuses } from '@/api/client/types';
import { authActions as actions } from '@/store/auth/slice';
import axios from 'axios';
import { ValidationErrorType } from '@/types/ValidationError';

export const skipErrorHandler = (_error: unknown): AnyAction[] => [];

interface CommonError {
  response?: {
    status?: number;
    data?: unknown;
    statusText?: string;
  };
}
export const isCommonNetworkError = (
  e: unknown | { response: { status: number; statusText: string } }
): e is CommonError => {
  const err: CommonError = e as CommonError;
  return !!err?.response?.status;
};
export const baseErrorHandler = (error: unknown): AnyAction[] => {
  if (!axios.isAxiosError(error) && !isCommonNetworkError(error)) {
    return skipErrorHandler(error);
  }

  switch (error?.response?.status) {
    case Statuses.BAD_REQUEST: {
      if (error?.response?.data.error || error?.response?.data?.fieldErrors) {
        return [actions.setValidationErrors(error.response.data)];
      }
      return skipErrorHandler(error);
    }
    case Statuses.UNAUTHORIZED:
      // redirect to login page will be made by means of global api client interceptor
      return [
        actions.setValidationErrors({
          error: ValidationErrorType.ACCOUNT_NOT_FOUND,
          fieldErrors: [],
        }),
      ];
    default: {
      if (error?.response?.status && error?.response?.statusText) {
        return [
          actions.setBaseError({
            status: error.response.status,
            statusText: error.response.statusText,
          }),
        ];
      }
      return [actions.setBaseError({ status: 0, statusText: 'Unknown Error' })];
    }
  }
};
