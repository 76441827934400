/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface AccountInviteVerificationDTO {
  contact?: Contact;
  phone?: string;
}

export interface ActivationRequest {
  password: string;
  phone: string;
  recaptcha?: string;
}

// @ts-expect-error for ts types the error is not critical
export type ActivationResendRequest = UtilRequiredKeys<
  BaseResendRequest,
  'type'
> & {
  phone: string;
};

/** Object with error message «Internal Server Error» */
export interface BaseError {
  /** Error message */
  message?: string;
}

/** Base resend request */
// @ts-expect-error for ts types the error is not critical
export type BaseResendRequest = BaseBaseResendRequest &
  (
    | BaseBaseResendRequestTypeMapping<'ACTIVATION', ActivationResendRequest>
    | BaseBaseResendRequestTypeMapping<
        'RECOVERY_EMAIL',
        RecoveryEmailResendRequest
      >
    | BaseBaseResendRequestTypeMapping<
        'RECOVERY_PHONE',
        RecoverySMSResendRequest
      >
    | BaseBaseResendRequestTypeMapping<
        'REGISTRATION',
        RegistrationResendRequest
      >
    | BaseBaseResendRequestTypeMapping<'TWO_FACTOR', TwoFactorResendRequest>
  );

export enum CodeType {
  REGISTRATION = 'REGISTRATION',
  ACTIVATION = 'ACTIVATION',
  RECOVERY_PHONE = 'RECOVERY_PHONE',
  RECOVERY_EMAIL = 'RECOVERY_EMAIL',
  TWO_FACTOR = 'TWO_FACTOR',
}

export interface CompanyAuthResponse {
  redirectUrl?: string;
}

export interface CompanyAuthentication {
  /** @uniqueItems true */
  permissions?: string[];
  state?: CompanyAuthenticationState;
}

export enum CompanyAuthenticationState {
  ACTIVE = 'ACTIVE',
  REQUIRED_RENEW = 'REQUIRED_RENEW',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export interface Contact {
  /** @format date */
  birthDate?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
}

export enum CookieType {
  ACCESS = 'ACCESS',
  REFRESH = 'REFRESH',
  FINGERPRINT = 'FINGERPRINT',
}

export interface CookieWrapper {
  domain?: string;
  httpOnly?: boolean;
  /** @format int32 */
  maxAge?: number;
  name?: string;
  path?: string;
  secure?: boolean;
  type?: CookieType;
  value?: string;
}

export interface EmailConfirmRequest {
  code: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  email: string;
}

export interface EmailRecoveryCheckRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  email: string;
  recaptcha?: string;
}

export interface EmailRecoveryRequest {
  /**
   * @minLength 0
   * @maxLength 255
   */
  email: string;
  password: string;
  recaptcha?: string;
}

export interface FieldError {
  error?: string;
  field?: string;
}

export interface IntrospectTokenRequest {
  accessToken: string;
  /** @format uuid */
  company?: string;
  fingerprint: string;
  module?: ModuleType;
}

export interface IntrospectTokenResponse {
  active?: boolean;
  companyAuthentication?: CompanyAuthentication;
  /** @uniqueItems true */
  permissions?: string[];
}

export interface InvitationResultResponse {
  companyName?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  phone?: string;
}

export interface MFAConfirmRequest {
  code: string;
  mfaType: MFAType;
  phone: string;
}

export interface MFAConflictResponse {
  availableMFATypes?: MFAType[];
}

export interface MFAResendTimeResponse {
  mfaType?: MfaResendTimeResponseMfaTypeEnum;
  /** @format int64 */
  resendTime?: number;
}

export enum MFAType {
  SMS = 'SMS',
  TOTP = 'TOTP',
}

export enum ModuleType {
  PASSPORT = 'PASSPORT',
  LIS = 'LIS',
  PROFESSIONAL = 'PROFESSIONAL',
}

export interface PasswordRequest {
  password: string;
  recaptcha?: string;
}

export interface PhoneConfirmRequest {
  code: string;
  phone: string;
}

export interface PhoneRecoveryCheckRequest {
  phone: string;
  recaptcha?: string;
}

export interface PhoneRecoveryRequest {
  password: string;
  phone: string;
  recaptcha?: string;
}

export type RecoveryEmailResendRequest = UtilRequiredKeys<
  BaseBaseResendRequest,
  'type'
> & {
  /**
   * @minLength 0
   * @maxLength 255
   */
  email: string;
};

export type RecoverySMSResendRequest = UtilRequiredKeys<
  BaseBaseResendRequest,
  'type'
> & {
  phone: string;
};

export interface RegistrationRequest {
  /**
   * @minLength 0
   * @maxLength 50
   */
  firstName: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  lastName: string;
  password: string;
  phone: string;
  recaptcha?: string;
}

export type RegistrationResendRequest = UtilRequiredKeys<
  BaseBaseResendRequest,
  'type'
> & {
  phone: string;
};

export interface RenewTokenRequest {
  fingerprint: string;
  refreshToken: string;
}

export interface ResendTimeResponse {
  /** @format int64 */
  resendTime: number;
}

export interface RevokeTokenRequest {
  fingerprint?: string;
  refreshToken?: string;
}

export interface SignInMFARequest {
  mfaType?: MFAType;
  password: string;
  phone: string;
  recaptcha?: string;
}

export interface SignInRequest {
  password: string;
  phone: string;
  recaptcha?: string;
}

export type TwoFactorResendRequest = UtilRequiredKeys<
  BaseBaseResendRequest,
  'type'
> & {
  phone: string;
};
/** Base resend request */
interface BaseBaseResendRequest {
  recaptcha?: string;
  type: CodeType;
}

type BaseBaseResendRequestTypeMapping<Key, Type> = {
  type: Key;
} & Type;

export enum MfaResendTimeResponseMfaTypeEnum {
  SMS = 'SMS',
  TOTP = 'TOTP',
}

export interface OpenidSignInParams {
  webUrl: string;
  /** @format uuid */
  companyId: string;
}
