/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { DnDFileInput, FileChangeHandler } from './DnDFileInput';
import { DnDZoneViewPhotoUpload } from './DndZoneViewPhotoUpload';
import { FileContextWrapper } from '@/components/FileInput/FileContextWrapper';
import { Stack } from '@mui/material';
import { inheritDataTestId } from '@/utils/testId.ts';

/**
 * Same as DnDFileInput -
 * meaning it is DnDFileInput using different view for drop zone and having one more parameter - src
 */
export const PhotoUpload: FC<{
  src: string | undefined;
  round?: boolean;
  onChange: FileChangeHandler;
}> = ({ src, round = true, onChange, ...props }) => {
  return (
    <Stack
      justifyContent={'center'}
      alignItems={'center'}
      data-test-id={inheritDataTestId(props)}
    >
      <FileContextWrapper onChange={onChange}>
        <DnDFileInput
          data-test-id={inheritDataTestId(props, 'dnd-file-input')}
          dropZoneView={<DnDZoneViewPhotoUpload src={src} round={round} />}
          multiple={false}
          accept={'image/*'}
        />
      </FileContextWrapper>
    </Stack>
  );
};
