import { Stack } from '@mui/material';
import { UUID } from '@/types';
import { Card, CardButton } from './Card';
import { Scrollbar } from '@/components/Scrollbar';
import { boxXPaddings } from '@/components';
import { useBreakpoints } from 'ui-kit';
import {
  useQrActions,
  useQrData,
  useRequestListData,
} from '@/store/profile-qr/hooks';
import { toDataUri } from '@/utils/toDataUrl';
import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';
export interface QrInfo {
  description: string;
  url: string;
  id: UUID;
}

export const Cards = () => {
  const { sm } = useBreakpoints();
  useRequestListData();
  const d = useQrData();
  const a = useQrActions();
  return (
    <Scrollbar
      data-test-id={testId(DATA_TEST_IDS.authenticationCards + '_scrollbar')}
      sx={{ display: 'flex', '.ps__rail-x': { display: 'none' } }}
    >
      <Stack
        direction={'row'}
        gap={18}
        sx={{ paddingX: boxXPaddings, flexShrink: 0 }}
      >
        {sm && (
          <CardButton
            data-test-id={testId(
              DATA_TEST_IDS.authenticationCards + '_card-button'
            )}
            onClick={a.createNew}
          />
        )}
        {d.qrs.allIDs.map((id) => {
          const item = d.qrs.byID[id];
          if (!item) return null;
          return (
            <Card
              data-test-id={testId(DATA_TEST_IDS.authenticationCards + '_card')}
              key={id}
              url={toDataUri(item.qrCode)}
              onClick={() => a.editQr(id)}
              description={item.description}
            />
          );
        })}
      </Stack>
    </Scrollbar>
  );
};
