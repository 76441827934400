import { Link } from '@/components/Text';
import React, { FC } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { Button } from '@/components/Button';
import { useDispatch } from 'react-redux';
import { authActions } from '@/store/auth/slice';
import { Space } from '../../types';
import { useAuthState } from '@/store/auth/hooks';
import { Statuses } from '@/api/client/types';
import { AppRoutes } from '@/routes/appRoutes';
import { Stack, Typography } from '@mui/material';
import { AuthHeader } from '@/features/authentication/common/AuthHeader';

import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

export const ErrorFragment: FC<{ space: Space }> = ({ space }) => {
  const { t } = useTranslate('auth');
  const { error } = useAuthState();
  const dispatch = useDispatch();
  const lockedAccount = error?.status === Statuses.LOCKED;
  let description = '';
  switch (space) {
    case Space.Registration:
      description = t('REG_SYSTEM_UNAVAILABLE');
      break;
    case Space.Login:
      description = t('SIGN_IN_SYSTEM_UNAVAILABLE');
      break;
    case Space.Recovery:
      description = t('RECOVERY_SYSTEM_UNAVAILABLE');
  }

  return (
    <ErrorView
      headerTitle={t('ERROR_TITLE')}
      description={lockedAccount ? t('ACCOUNT_LOCKED') : description}
      link={
        space === Space.Registration && (
          <Link
            data-test-id={testId(
              DATA_TEST_IDS.authErrorFragment + '_link-sign-in' + `_${space}`
            )}
            to={{ pathname: AppRoutes.AUTH, search: window.location.search }}
          >
            {t('SIGN_IN')}
          </Link>
        )
      }
      button={
        <Button
          data-test-id={testId(
            DATA_TEST_IDS.authErrorFragment +
              '_button-to-login-page' +
              `_${space}`
          )}
          fullWidth
          color={'secondary'}
          type={'submit'}
          sx={{ mb: 6 }}
          onClick={() => dispatch(authActions.goToStep('index'))}
        >
          {lockedAccount ? t('TO_LOGIN_PAGE') : t('TRY_NOW')}
        </Button>
      }
    />
  );
};

export const ErrorView: FC<{
  headerTitle: string;
  link?: JSX.Element | false;
  description?: string;
  button: JSX.Element;
}> = ({ headerTitle, link, description, button }) => {
  return (
    <Stack
      sx={{
        flex: { sm: '1 1 auto' },
        width: '100%',
        justifyContent: { xs: 'space-between', sm: 'start' },
      }}
    >
      <Stack sx={{ flex: { sm: '1 1 auto' } }}>
        <AuthHeader title={headerTitle} sx={{ mb: { lg: 64, sm: 48, xs: 36 } }}>
          {link}
        </AuthHeader>
        <Typography
          variant={'16_24_400'}
          mb={{ xs: 368 }}
          sx={{ typography: { sm: '16_20_500' } }}
        >
          {description}
        </Typography>
      </Stack>
      {button}
    </Stack>
  );
};
