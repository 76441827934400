import { useSelPermissionsSelector } from '@/features/module/LIMS/hooks/useSelPermissionsSelector';
import { useDeferredValue, useEffect, useState } from 'react';
import { ActionName } from '@/types';
import { getPath, TreePath } from '@/features/module/LIMS/utils/getPath';
import { useParams } from '@/routes/appRoutes.ts';
import { usePermissionsSelector } from '@/features/module/LIMS/hooks/usePermissionsSelector.ts';

export const useSelectedPermissions = () => {
  const params = useParams();
  const state = usePermissionsSelector(params);
  const selectedPermissions = useSelPermissionsSelector();
  const [data, setData] = useState<
    Record<
      ActionName,
      Array<{
        permissions: TreePath[];
        groups: TreePath[];
      }>
    >
  >({
    [ActionName.Access]: [],
    [ActionName.Action]: [],
    [ActionName.Delete]: [],
    [ActionName.Role]: [],
  });
  const deferredData = useDeferredValue(data);

  useEffect(() => {
    for (const action of [
      ActionName.Access,
      ActionName.Action,
      ActionName.Delete,
      ActionName.Role,
    ]) {
      const selected = selectedPermissions[action];
      const items: Array<{
        permissions: TreePath[];
        groups: TreePath[];
      }> = [];
      for (const [groupId, permissions] of Object.entries(selected ?? {})) {
        const obj = {
          permissions: permissions.map((p) => ({
            id: p.id,
            label: p.description,
          })),
          groups: getPath(groupId, state.groups),
        };
        items.push(obj);
      }
      setData((p) => {
        return {
          ...p,
          [action]: items,
        };
      });
    }
  }, [selectedPermissions, state.groups, state.selected]);

  return {
    initLoading: state.initLoading,
    data: deferredData,
  };
};
