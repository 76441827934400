/** @jsxImportSource @emotion/react */
import { Box, styled } from '@mui/material';

export interface TextProps {
  mt?: number;
  mb?: number;
  pt?: number;
  pb?: number;
  lh?: number;
  fz?: number;
  weight?: number;
}
/**
 * @deprecated
 * use Typography element instead (with proper variant)
 */
export const H2 = styled('h2')<TextProps>`
  font-weight: 700;
  font-size: ${({ fz }) => `${fz ?? ''}px`};
  line-height: ${({ lh }) => `${lh ?? ''}px`};
  margin-top: ${({ mt }) => `${mt ?? 0}px`};
  margin-bottom: ${({ mb }) => `${mb ?? 0}px`};
`;
H2.defaultProps = {
  mt: 0,
  mb: 48,
  fz: 32,
  lh: 36,
};

/**
 * @deprecated
 * use Typography element instead (with proper variant)
 */
export const H3 = styled(Box)<TextProps>`
  font-weight: ${({ weight }) => weight ?? 700};
  font-size: ${({ fz }) => `${fz ?? ''}px`};
  line-height: ${({ lh }) => `${lh ?? ''}px`};
  margin-top: ${({ mt }) => `${mt ?? 0}px`};
  margin-bottom: ${({ mb }) =>
    `${mb ?? 0}px ${mb != null ? '!important' : ''}`};
`;
H3.defaultProps = {
  component: 'h3',
};
/**
 * @deprecated
 * use Typography element instead (with proper variant)
 */
export const H4 = styled('h4')<TextProps>`
  font-weight: ${({ weight }) => weight ?? 700};
  font-size: ${({ fz }) => `${fz ?? '18'}px`};
  line-height: ${({ lh }) => `${lh ?? ''}px`};
  margin-top: ${({ mt }) => `${mt ?? 0}px`};
  margin-bottom: ${({ mb }) => `${mb ?? 0}px`};
`;
/**
 * @deprecated
 * use Typography element instead (with proper variant)
 */
export const H5 = styled('h5')<TextProps>`
  font-weight: ${({ weight }) => weight ?? 700};
  font-size: ${({ fz }) => `${fz ?? '16'}px`};
  line-height: ${({ lh }) => `${lh ?? ''}px`};
  margin-top: ${({ mt }) => `${mt ?? 0}px`};
  margin-bottom: ${({ mb }) => `${mb ?? 0}px`};
`;
H5.defaultProps = {
  mb: 0,
  mt: 0,
  lh: 20,
};
/**
 * @deprecated
 * use Typography element instead (with proper variant)
 */
export const Text = styled('p')<TextProps>`
  padding: ${({ pb, pt }) => `${pt ?? 0}px 0 ${pb ?? 0}px`};
  margin: ${({ mb, mt }) => `${mt ?? 0}px 0 ${mb ?? 0}px`};
  font-size: ${({ fz }) => `${fz ?? 0}px`};
  color: ${({ color }) => color};
  line-height: ${({ lh }) => `${lh ?? 0}px`};
  font-weight: ${({ weight }) => weight ?? 0};
`;
Text.defaultProps = {
  mb: 0,
  mt: 0,
  fz: 16,
  lh: 20,
  weight: 400,
};
