import React, { ChangeEvent, FC } from 'react';
import { NodeState } from '@/types/NodeState';
import { UUID } from '@/types';
import { TreeListItem, VirtualList } from 'ui-kit';
import { Permission, PermissionGroup } from '@/api/__generated__/webApi';
import { useEventEffect } from '@/features/module/LIMS/fragments/useEventEffect.tsx';
import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';
export const TreeList: FC<{
  list: Array<NodeState<PermissionGroup | Permission>>;
  onExpand?: (expanded: boolean, id?: UUID) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>, id: UUID) => void;
  editable?: boolean;
  match?: string;
}> = ({ list, onExpand, onChange, editable, match }) => {
  const onExpand_ = useEventEffect(onExpand);
  const onChange_ = useEventEffect((e: ChangeEvent<HTMLInputElement>) =>
    onChange?.(e, e.currentTarget.name)
  );

  return (
    <VirtualList
      data-test-id={testId(DATA_TEST_IDS.fragmentsTreeList + '_scrollbar')}
      data={list}
      estimatedItemHeight={40}
      sx={styles.leftTreeSection}
    >
      {(list, measureItem) => {
        return list.map(
          ({ children, label, id, level, expanded, selected }, i) => {
            return (
              <TreeListItem
                data-test-id={testId(
                  DATA_TEST_IDS.fragmentsTreeList + '_tree-list-item' + i
                )}
                ref={(el) => measureItem(i, el)}
                disabled={!editable}
                hasChildren={!!children.length}
                label={label}
                key={id}
                id={id}
                checkboxId={`${id}_checkbox`}
                name={id}
                level={level - 1}
                expanded={expanded}
                checked={!!selected}
                indeterminate={selected === 'indeterminate'}
                onExpand={onExpand_}
                onChange={onChange_}
                match={match}
              />
            );
          }
        );
      }}
    </VirtualList>
  );
};

const styles = {
  leftTreeSection: {
    padding: { xs: 0, sm: '18px 0 18px 18px' },
    height: '100%',
  },
};
