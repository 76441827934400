/** @jsxImportSource @emotion/react */
import { CompanyPermissionName, PartialRecord, UUID } from '@/types';
import { NormalizedState } from '@/store/common/normalized';
import { useTranslate } from '@/i18n/useTranslate';
import { useDispatch } from 'react-redux';
import {
  useCompaniesData,
  useCompanyDetailsGetter,
} from '@/store/companies/hooks';
import { useAvatarUpload, useProfileData } from '@/store/profile/hooks';
import { getName } from '@/utils/getName';
import { useMQuery } from '@/hooks/useMQuery';
import { useCallback, useEffect, useMemo } from 'react';
import { authActions } from '@/store/auth/slice';
import { ProfileMenuItem } from './ProfileMenu';
import LogoutIcon from 'ui-kit/lib/icons/24/System/SignOut';
import { useCompanyLogo } from '@/store/companies/hooks/useCompanyLogo';
import { profileActions } from '@/store/profile/slice';
import {
  Company,
  CompanyData,
  Module,
  ModuleType,
  NPI1Data,
  NPI2Data,
} from '@/api/__generated__/webApi';
import { envs } from '@/constants/envs';
import { useCompanyPermissionsValidator } from '@/store/permissions/useCompanyPermissionsValidator.tsx';
import { QueuedTaskType, toSearchParams } from '@/utils/toSearchParams.tsx';
import { Box } from '@mui/material';

interface CompanyItem {
  id: UUID;
  label: string | null | undefined;
  title: string | null | undefined;
  items: Array<{
    href?: string;
    onClick?: () => void;
    label: string;
    id: UUID;
  }>;
}
export const getAllCompanyData = (
  companyId: UUID,
  companies: NormalizedState<Omit<Company, 'modules'>, string>,
  modules: PartialRecord<string, NormalizedState<Module, string>>,
  details: PartialRecord<string, NPI1Data | NPI2Data | CompanyData>
) => {
  const company = companies.byID[companyId];
  if (typeof company === 'undefined') {
    return undefined;
  }
  const companyModules = modules?.[companyId]?.allIDs.map(
    (mId: UUID) => modules?.[companyId]?.byID[mId]
  );
  const companyDetails = details[companyId];
  return {
    company,
    modules: companyModules,
    details: companyDetails,
  };
};
export const useMenuItems = () => {
  const { t } = useTranslate('profile');
  const dispatch = useDispatch();
  const { details: profile } = useProfileData() ?? {};
  const { companies, modules } = useCompaniesData(false);
  const { openDialog: openCompanyAvatarDialog } = useCompanyLogo();
  const has = useCompanyPermissionsValidator();
  const { details, getDetails } = useCompanyDetailsGetter();
  const { openDialog } = useAvatarUpload();
  const title = getName(profile?.contact, true);
  const { mobile, tablet } = useMQuery();

  const handleLogout = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  useEffect(() => {
    for (const companyId of companies.allIDs) {
      getDetails(companyId);
    }
  }, [companies.allIDs, companies.byID, getDetails]);

  const menuItems = useMemo(() => {
    const dynamicItems: ProfileMenuItem[] | undefined[] = companies.allIDs
      .map((companyId) => {
        const companyData = getAllCompanyData(
          companyId,
          companies,
          modules,
          details
        );
        if (
          !companyData ||
          !companyData.modules?.length ||
          (companyData.modules.length === 1 &&
            companyData.modules[0]?.type === ModuleType.PASSPORT)
        ) {
          return undefined;
        }
        const companyItem: CompanyItem = {
          id: companyId,
          label: companyData.company.name,
          title: companyData.company.npi
            ? t('NPI_N', { npi: companyData.company.npi })
            : '',
          items: companyData.modules
            ?.map((module) => {
              if (module?.type === ModuleType.PASSPORT) {
                return null;
              }
              return {
                to: {
                  pathname: '',
                  search: `?${toSearchParams({ companyId, moduleType: module?.type ?? '', queuedTask: QueuedTaskType.ModuleRedirect })}`,
                },
                label: t(module?.type),
                id: module?.id ?? '',
              };
            })
            .filter(Boolean) as CompanyItem['items'],
        };
        if (has(CompanyPermissionName.CompanyEditLogo, companyId) && mobile) {
          companyItem.items?.unshift({
            onClick: () => openCompanyAvatarDialog(companyId),
            label: t('ADD_PHOTO'),
            id: 'company-add-photo' + companyId,
          });
        }
        return companyItem;
      })
      .filter(Boolean) as ProfileMenuItem[];

    const items: ProfileMenuItem[] = [
      {
        label: t('PATIENT_PORTAL'),
        id: 'patient-portal',
        href: envs.PATIENT_PORTAL_URL,
      },
      ...(mobile
        ? [
            {
              onClick: () => openDialog(),
              label: t('ADD_PHOTO'),
              id: 'add-photo',
            },
          ]
        : []),
      ...(dynamicItems ?? []),
      {
        onClick: () => {
          dispatch(profileActions.setDisplayInfoAboutSystem(true));
        },
        label: t('ABOUT_SYSTEM'),
        id: 'about-system',
      },
      ...(tablet || mobile
        ? [
            {
              id: 'docs',
              label: t('USER_GUIDE'),
              href: envs.HELP_URL,
            },
            {
              id: 'support',
              label: t('CONTACT_SUPPORT'),
              href: envs.CONTACT_SUPPORT_URL,
            },
          ]
        : []),
      {
        onClick: handleLogout,
        label: t('LOG_OUT'),
        icon: (
          <Box
            component={LogoutIcon}
            size={18}
            color={(t) => t.palette.ddrow.iconright.default}
          />
        ),
        id: 'log-out',
      },
    ];

    return items;
  }, [
    companies,
    details,
    dispatch,
    handleLogout,
    has,
    mobile,
    modules,
    openCompanyAvatarDialog,
    openDialog,
    t,
    tablet,
  ]);

  return {
    menuItems,
    title,
  };
};
