import {
  Content,
  FloatMenu,
  GridContainer,
  MENU_COLLAPSED_CLASS,
  PageBody,
  styles,
} from './styles';
import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Backdrop, Box, GlobalStyles, Stack } from '@mui/material';
import PanelHide from 'ui-kit/lib/icons/24/Action/PanelHide';
import PanelExpand from 'ui-kit/lib/icons/24/Action/PanelExpand';
import { useOnLoginInit } from './hooks/useOnLoginInit';
import classNames from 'classnames';
import { SidebarContainer } from '@/features/sidebar';
import { Button } from '@/components';
import { useTranslate } from '@/i18n/useTranslate';
import { useMenuPersistentState } from './hooks/useMenuPersistentState';
import { Scrollbar, useBreakpoints } from 'ui-kit';
import { CollapseButton } from './fragments/CollapseButton';
import { Dialogs } from './fragments/Dialogs';
import { HeaderStripe } from './fragments/HeaderStripe';
import { globalStyles } from './globalStyles.tsx';
import { useEnterToModule } from '@/features/module/useEnterToModule.tsx';

import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

export const CONTENT_BOX_ID = 'content_body';
const useToggleMenu = () => {
  const { lg } = useBreakpoints();
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => {
    if (!menuOpen) {
      window.document.body.classList.add('sandwich-menu-open');
      const selectedMenuItem = window.document
        .querySelector?.('.grid-nav-panel')
        ?.querySelector('ul > li.selected');
      selectedMenuItem?.scrollIntoView?.();
    } else {
      window.document.body.classList.remove('sandwich-menu-open');
    }
    setMenuOpen((m) => !m);
  }, [menuOpen]);
  const closeMenu = useCallback(() => {
    window.document.body.classList.remove('sandwich-menu-open');
    setMenuOpen(false);
  }, []);
  useEffect(() => {
    if (lg) {
      window.document.body.classList.remove('sandwich-menu-open');
      setMenuOpen(false);
    }
  }, [lg]);
  return {
    menuOpen,
    toggleMenu,
    closeMenu,
  };
};

export const MainLayout: FC = () => {
  const { t } = useTranslate('common');
  const { lg, xsOnly, smOnly, mdOnly } = useBreakpoints();
  const desktopAndWider = lg;
  const { menuOpen, closeMenu, toggleMenu } = useToggleMenu();
  const [collapsed, setCollapsed] = useMenuPersistentState(false);
  useOnLoginInit();
  useEnterToModule();
  return (
    <>
      <GlobalStyles styles={globalStyles} />
      <PageBody>
        <HeaderStripe
          onClick={() => (menuOpen ? closeMenu() : undefined)}
          toggleMenu={toggleMenu}
          menuIsOpen={menuOpen}
        />
        <Box sx={styles.main} className={'Main'}>
          <GridContainer
            className={classNames(
              collapsed && desktopAndWider ? MENU_COLLAPSED_CLASS : '',
              'GridContainer'
            )}
          >
            <Box
              sx={styles.gridNavPanel}
              className={'grid-nav-panel'}
              onClick={closeMenu}
              data-test-id={testId(
                DATA_TEST_IDS.mainLayout + '_grid-nav-panel'
              )}
            >
              <FloatMenu>
                <Scrollbar
                  data-test-id={testId(DATA_TEST_IDS.mainLayout + '_scrollbar')}
                  sx={{ '.ps__thumb-y': { zIndex: 1 } }}
                >
                  <SidebarContainer collapsed={collapsed} />
                </Scrollbar>
                {desktopAndWider && (
                  <Stack
                    direction={'row'}
                    sx={{
                      justifyContent: 'end',
                      [`.${MENU_COLLAPSED_CLASS} &`]: {
                        justifyContent: 'center',
                      },
                    }}
                    pb={20}
                  >
                    <Button
                      data-test-id={testId(
                        DATA_TEST_IDS.mainLayout + '_button-hide-panel'
                      )}
                      variant={'text'}
                      color={'secondary'}
                      endIcon={<PanelHide />}
                      onClick={() => setCollapsed(true)}
                      sx={{
                        paddingRight: 9,
                        marginRight: 12,
                        [`.${MENU_COLLAPSED_CLASS} &`]: {
                          display: 'none',
                        },
                      }}
                    >
                      {t('HIDE_PANEL')}
                    </Button>
                    <CollapseButton
                      onClick={() => setCollapsed(false)}
                      data-test-id={testId(
                        DATA_TEST_IDS.mainLayout + '_collapse-button'
                      )}
                    >
                      <Box
                        component={PanelExpand}
                        sx={{ color: 'white!important' }}
                      />
                    </CollapseButton>
                  </Stack>
                )}
              </FloatMenu>
            </Box>
            <Content id={CONTENT_BOX_ID}>
              <Outlet />
            </Content>
            <Backdrop
              sx={styles.backdrop}
              open={menuOpen && (xsOnly || smOnly || mdOnly)}
              onClick={toggleMenu}
              data-test-id={testId(DATA_TEST_IDS.mainLayout + '_backdrop')}
            />
          </GridContainer>
        </Box>
      </PageBody>
      <Dialogs />
    </>
  );
};
