import React, { FC } from 'react';
import { Tooltip } from 'ui-kit';
import { SxProps, Theme, tooltipClasses, TooltipProps } from '@mui/material';
import IconSVGWarning from 'ui-kit/lib/icons/24/Warning/Info';
import { IconContainer } from '@/components/IconContainer';
import { inheritDataTestId } from '@/utils/testId.ts';
export const AlertSign: FC<{
  title: string;
  className?: string;
  placement?: TooltipProps['placement'];
  ['data-test-id']?: string;
}> = ({ title, className, placement, ...props }) => {
  return (
    <Tooltip
      data-test-id={inheritDataTestId(props, 'tooltip')}
      placement={placement ?? 'bottom'}
      title={title ?? ''}
      sx={styles[tooltipClasses.tooltip]}
    >
      <IconContainer
        sx={styles.iconContainer}
        className={className}
        data-test-id={props['data-test-id']}
      >
        <IconSVGWarning />
      </IconContainer>
    </Tooltip>
  );
};

const styles = {
  iconContainer: {
    cursor: 'pointer',
    color: (t) => t.palette.btn.tertiary.transparent.default,
  },
  [tooltipClasses.tooltip]: { maxWidth: 200 },
} satisfies Record<string, SxProps<Theme>>;
