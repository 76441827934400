import React, { FC, RefObject, useCallback, useRef, useState } from 'react';
import { HorizontalResizerBar } from '@/features/module/LIMS/Resizer/HorizontalResizerBar.tsx';

interface ColParams {
  min: number;
  width: number;
}
const calcConstrainedWidth = (
  col1: ColParams,
  col2: ColParams | undefined,
  addition: number
) => {
  if (addition < 0) {
    if (col1.width < col1.min) {
      addition = 0;
    } else {
      addition = Math.max(-(col1.width - col1.min), addition);
    }
  } else if (col2) {
    if (col2.width < col2.min) {
      addition = 0;
    } else {
      addition = Math.min(col2.width - col2.min, addition);
    }
  }
  return col1.width + addition;
};

export const HorizontalResizer: FC<{
  setWidth: (width: number) => void;
  parentRef: RefObject<HTMLDivElement>;
  adjacentRef?: RefObject<HTMLDivElement>;
  min?: number;
  minAdjacent?: number;
}> = ({ setWidth, parentRef, adjacentRef, min = 300, minAdjacent = 300 }) => {
  const [active, setActive] = useState(false);
  const setWidthCbRef = useRef(setWidth);
  setWidthCbRef.current = setWidth;

  const handleOnMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setActive(true);
      const initialWidth = parentRef.current?.getBoundingClientRect().width;
      const adjacentBlockWidth =
        adjacentRef?.current?.getBoundingClientRect().width;
      const start = e.pageX;
      let constrainedParentWidth = 0;
      if (!initialWidth) {
        return;
      }
      const mousemove = (e: MouseEvent) => {
        const addition = e.pageX - start;

        constrainedParentWidth = calcConstrainedWidth(
          { min, width: initialWidth },
          minAdjacent && adjacentBlockWidth
            ? { min: minAdjacent, width: adjacentBlockWidth }
            : undefined,
          addition
        );
        if (parentRef.current)
          parentRef.current.style.width = `${constrainedParentWidth}px`;
      };
      document.addEventListener('mousemove', mousemove);
      document.addEventListener('mouseup', function mouseup() {
        document.removeEventListener('mousemove', mousemove);
        document.removeEventListener('mouseup', mouseup);
        setWidthCbRef.current(constrainedParentWidth);
        setActive(false);
      });
    },
    [adjacentRef, min, minAdjacent, parentRef]
  );

  const handleOnTouchDown = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      setActive(true);
      const initialWidth = parentRef.current?.getBoundingClientRect().width;
      const adjacentBlockWidth =
        adjacentRef?.current?.getBoundingClientRect().width;
      const start = e.touches[0].pageX;
      let constrainedParentWidth = 0;
      if (!initialWidth) {
        return;
      }
      const touchmove = (e: TouchEvent) => {
        const addition = e.touches[0].pageX - start;

        constrainedParentWidth = calcConstrainedWidth(
          { min, width: initialWidth },
          minAdjacent && adjacentBlockWidth
            ? { min: minAdjacent, width: adjacentBlockWidth }
            : undefined,
          addition
        );
        if (parentRef.current)
          parentRef.current.style.width = `${constrainedParentWidth}px`;
      };
      document.addEventListener('touchmove', touchmove);
      document.addEventListener('touchend', function touchend() {
        document.removeEventListener('touchmove', touchmove);
        document.removeEventListener('touchend', touchend);
        setWidthCbRef.current(constrainedParentWidth);
        setActive(false);
      });
    },
    [adjacentRef, min, minAdjacent, parentRef]
  );

  return (
    <HorizontalResizerBar
      position={'right'}
      active={active}
      onMouseDown={handleOnMouseDown}
      onTouchStart={handleOnTouchDown}
    />
  );
};
