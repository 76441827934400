/**
 * Temp solution that need to be replaced with ui-kit component
 */

import { Stack } from '@mui/material';
import { ReactNode } from 'react';

export interface BreadcrumbsNewProps {
  children: ReactNode;
}
export const BreadcrumbsNew = ({ children }: BreadcrumbsNewProps) => {
  return (
    <Stack direction={'row'} sx={styles.breadcrumbs}>
      {children}
    </Stack>
  );
};

const styles = {
  breadcrumbs: {
    gap: 4,
  },
};
