import { useParams } from '@/routes/appRoutes';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { NormalizedState } from '@/store/common/normalized';
import { UUID } from '@/types';
import { companiesActions as actions } from '../slice';
import { useCompaniesState } from './useCompaniesState';
import { Module, ModuleType } from '@/api/__generated__/webApi';

const allModuleTypes = Object.values(ModuleType);
const order = {
  [ModuleType.LIS]: 1,
  [ModuleType.PROFESSIONAL]: 2,
  [ModuleType.PASSPORT]: 0,
};
const getAllModules = () => {
  return allModuleTypes
    .sort((a, b) => {
      return order[a] > order[b] ? 1 : order[a] < order[b] ? -1 : 0;
    })
    .map((t) => ({ type: t, disabled: false }));
};

const getModulesWithState = (modules: NormalizedState<Module> | undefined) => {
  const availableModules = getAllModules();
  if (modules) {
    for (const id of modules.allIDs) {
      const { type } = modules.byID[id] ?? {};
      if (type && allModuleTypes.includes(type)) {
        availableModules[allModuleTypes.indexOf(type)].disabled = true;
      }
    }
  }
  return availableModules;
};

export const useCompany = (id: string | undefined) => {
  const {
    companies,
    modules: modulesState,
    fetching,
    error,
  } = useCompaniesState();
  const dispatch = useDispatch();

  const { company } = useMemo(() => {
    if (!id || !companies?.byID?.[id]) {
      return {};
    }
    const company = companies.byID[id];

    return {
      company,
      modules: company ? getModulesWithState(modulesState?.[company.id]) : null,
    };
  }, [companies.byID, id, modulesState]);

  const callbacks = useMemo(() => {
    return {
      resetError: () => {
        dispatch(actions.setError(null));
      },
    };
  }, [dispatch]);

  return {
    company,
    fetching,
    error,
    ...callbacks,
  };
};
export const useCompanyData = (companyId?: UUID) => {
  const params = useParams();
  const state = useCompaniesState();
  const company = companyId
    ? state.companies?.byID[companyId]
    : params.companyId
      ? state.companies?.byID[params.companyId]
      : null;
  const details = params.companyId
    ? state.companyDetails?.[params.companyId]
    : null;
  return {
    company,
    name: company?.name,
    details,
    error: state.error,
    fetching: state.fetching,
  };
};

export const useCompaniesData = (active = true) => {
  const dispatch = useDispatch();
  const { fetching, error, companies, modules } = useCompaniesState();

  useEffect(() => {
    if (active) {
      dispatch(actions.requestCompanies());
    }
  }, [active, dispatch]);

  return {
    fetching,
    error,
    companies,
    modules,
  };
};
