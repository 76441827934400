import { TreeListItem, UUID } from '@/types';
import { Permission, PermissionGroup } from '@/api/__generated__/webApi';
const ROOT_NODE_ID = 'ROOT';
const isPermission = (d: PermissionGroup | Permission): d is Permission =>
  'description' in d;
export const makeTree = (
  groups: PermissionGroup[],
  permissions: Permission[]
) => {
  const groupsRecord = groups.reduce<Record<UUID, PermissionGroup>>(
    (acc, item) => {
      acc[item.id] = item;
      return acc;
    },
    {}
  );
  const permissionsRecord: Record<UUID, Permission> = {};
  const groupsInUse: Record<
    UUID,
    Array<TreeListItem<PermissionGroup | Permission>>
  > = {
    [ROOT_NODE_ID]: [],
  };
  const actionNameMap = {
    DELETE: 'Delete permissions',
    ACTION: 'Action permissions',
    ACCESS: 'Access permissions',
    ROLE: 'Role permissions',
  };
  const inTree = new Set();
  for (const p of permissions) {
    if (!p.action || !p.group) continue;

    const permission = { ...p };

    const idFromPermission = (p: Permission) => {
      return `${p.group}_${p.action}`;
    };
    if (groupsRecord[idFromPermission(permission)]) {
      permission.group = groupsRecord[idFromPermission(permission)].id;
    } else {
      const newGroup = {
        id: idFromPermission(permission),
        parent: permission.group,
        name: actionNameMap[permission.action],
      };
      groupsRecord[newGroup.id] = newGroup;
      permission.group = newGroup.id;
    }

    permissionsRecord[permission.id] = permission;
    let pointer: PermissionGroup | Permission | undefined = permission;
    while (pointer) {
      if (inTree.has(pointer.id)) {
        break;
      }
      const node: TreeListItem<PermissionGroup | Permission> = {
        label: isPermission(pointer) ? pointer.description : pointer.name,
        id: pointer.id,
        children: [],
        data: pointer,
      };
      inTree.add(pointer.id);

      const groupId: string | undefined = isPermission(pointer)
        ? pointer.group
        : pointer.parent;
      if (!groupId || groupsInUse[groupId]) {
        groupsInUse[groupId ?? ROOT_NODE_ID].push(node);
      } else {
        groupsInUse[groupId] = [node];
      }
      const isGroup = !('action' in pointer);
      if (isGroup) {
        if (groupsInUse[pointer.id]) {
          node.children = groupsInUse[pointer.id];
        } else {
          groupsInUse[pointer.id] = node.children;
        }
      }
      pointer = groupId ? groupsRecord[groupId] : undefined;
    }
  }

  return {
    treeBranches: groupsInUse[ROOT_NODE_ID],
    permissions: permissionsRecord,
    groups: groupsRecord,
  };
};
