/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { useProfileData } from '@/store/profile/hooks';
import { getName } from '@/utils/getName';
import { Avatar } from '@/components/Avatar';
import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';
interface PAProps {
  size: number;
  className?: string;
  noLetters?: boolean;
  noUpload?: boolean;
}

export const ProfileAvatar: FC<PAProps> = ({
  size,
  className,
  noLetters,
  noUpload,
}) => {
  const { details: profile } = useProfileData();
  const name = getName(profile?.contact);
  return (
    <Avatar
      data-test-id={testId(DATA_TEST_IDS.profileProfileAvatar + '_avatar')}
      size={size}
      text={noLetters ? undefined : name}
      src={profile?.avatarUrl}
      className={className}
      uploadIcon={!noUpload}
    />
  );
};
