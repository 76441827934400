import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InfiniteScrollContainer,
  WhiteBox,
} from '@/components';
import React from 'react';
import {
  useInitInsuranceCarriers,
  useInsuranceCarriersActionsAndData,
} from '@/store/insurance/hooks';
import { Tab, TabPanel, Tabs, useTabs } from '@/components/TabPanel';
import { useTranslate } from '@/i18n/useTranslate';
import { Box, Stack, Typography } from '@mui/material';
import { useSearch } from '@/hooks/useSearch';
import { ListItem } from '@/features/company-details/insurance/ListItem';
import { CompanyListItem } from '@/features/company-details/insurance/CompanyListItem';
import { Scrollbar } from '@/components/Scrollbar';
import { INPUT_TIP_HEIGHT } from '@/components/common/Common';
import { SearchControl, useBreakpoints } from 'ui-kit';
import DATA_TEST_IDS from './dataTestIds.ts';
import { testId } from '@/utils/testId';

const boxPaddings = { xs: 24, lg: 36 };
export const Insurance = () => {
  const { xsOnly, smOnly, mdOnly, lg } = useBreakpoints();
  const {
    searchCarrier,
    addCarrier,
    removeCarrier,
    removeLastCarrier,
    loadMore,
    carriersItems,
    companyCarriersItems,
    removeConfirmationDialog,
    closeRemoveConfirmation,
  } = useInsuranceCarriersActionsAndData();
  const { handleChange: handleSearchChange, input } = useSearch((filter) => {
    searchCarrier({ filter });
  });
  const { t } = useTranslate('insurance');
  useInitInsuranceCarriers();
  const { index, handleChange } = useTabs(1);

  const companyInsuranceCarriersListContent = companyCarriersItems.length ? (
    companyCarriersItems.map((item, i) => {
      return (
        <CompanyListItem
          data-test-id={testId(
            DATA_TEST_IDS.insuranceInsurance + '_company-list-item' + i
          )}
          key={item.id}
          label={item.label}
          onClick={() => removeCarrier({ carrierId: item.id })}
        />
      );
    })
  ) : (
    <Typography variant={'14_18_500'} color={'grey.400'} px={boxPaddings}>
      {t('YOU_HAVE_NOT_ADDED_ANY_COMPANIES')}
    </Typography>
  );

  const searchControl = (
    <SearchControl
      data-test-id={testId(
        DATA_TEST_IDS.insuranceInsurance + '_search-control-insurance-company'
      )}
      value={input.value}
      placeholder={t('SEARCH_BY_INSURANCE_COMPANY')}
      onChange={handleSearchChange}
    />
  );

  let content = <></>;
  if (xsOnly) {
    content = (
      <>
        <Stack sx={{ paddingX: boxPaddings }}>
          <Tabs value={index} onChange={handleChange} variant={'fullWidth'}>
            <Tab
              value={1}
              label={t('ALL')}
              data-test-id={testId(
                DATA_TEST_IDS.insuranceInsurance + '_tab-all'
              )}
            />
            <Tab
              value={2}
              label={t('CONNECTED')}
              data-test-id={testId(
                DATA_TEST_IDS.insuranceInsurance + '_tab-connected'
              )}
            />
          </Tabs>
        </Stack>
        <Stack mt={24}>
          <TabPanel value={1} selected={index}>
            <Box
              sx={[
                {
                  paddingX: boxPaddings,
                  paddingBottom: 24 - INPUT_TIP_HEIGHT,
                },
              ]}
            >
              {searchControl}
            </Box>
            <InfiniteScrollContainer
              onLoad={loadMore}
              threshold={1000}
              data-test-id={testId(
                DATA_TEST_IDS.insuranceInsurance + '_carriers-list-scrollbar'
              )}
            >
              {carriersItems.length ? (
                carriersItems.map((item, index) => {
                  return (
                    <ListItem
                      data-test-id={testId(
                        DATA_TEST_IDS.insuranceInsurance +
                          '_list-item-carrier' +
                          index
                      )}
                      key={item.id}
                      label={item.label}
                      disabled={item.disabled}
                      logoSrc={item.src}
                      onClick={() => addCarrier({ carrierId: item.id })}
                    />
                  );
                })
              ) : (
                <Typography
                  variant={'14_18_500'}
                  color={'grey.400'}
                  px={boxPaddings}
                >
                  {t('INSURANCE_CARRIERS_NOT_FOUND')}
                </Typography>
              )}
            </InfiniteScrollContainer>
          </TabPanel>
          <TabPanel value={2} selected={index}>
            <Scrollbar
              sx={{ maxHeight: 500 }}
              data-test-id={testId(
                DATA_TEST_IDS.insuranceInsurance + '_carriers-scrollbar-xs'
              )}
            >
              {companyInsuranceCarriersListContent}
            </Scrollbar>
          </TabPanel>
        </Stack>
      </>
    );
  }

  if (smOnly || mdOnly) {
    content = (
      <>
        <Stack direction={'row'}>
          <Stack
            data-test-id={testId(
              DATA_TEST_IDS.insuranceInsurance + '_md-column-1'
            )}
            sx={(t) => ({
              flex: '1 0 50%',
              minWidth: 10,
              borderRight: '1px solid',
              borderColor: t.palette.secondary[300],
            })}
          >
            <Stack p={boxPaddings} gap={24}>
              <Stack height={42}>
                <Typography variant={'h2'} mb={0}>
                  {t('COMPANIES')}
                </Typography>
              </Stack>
              {searchControl}
            </Stack>
            <InfiniteScrollContainer
              onLoad={loadMore}
              threshold={1000}
              maxHeight={'66vh'}
              data-test-id={testId(
                DATA_TEST_IDS.insuranceInsurance + '_scroll-carriers2'
              )}
            >
              {carriersItems.length ? (
                carriersItems.map((item, index) => {
                  return (
                    <ListItem
                      data-test-id={testId(
                        DATA_TEST_IDS.insuranceInsurance +
                          '_list-item-carriers2' +
                          index
                      )}
                      key={item.id}
                      label={item.label}
                      disabled={item.disabled}
                      logoSrc={item.src}
                      onClick={() => addCarrier({ carrierId: item.id })}
                    />
                  );
                })
              ) : (
                <Typography
                  variant={'14_18_500'}
                  color={'grey.400'}
                  px={boxPaddings}
                >
                  {t('INSURANCE_CARRIERS_NOT_FOUND')}
                </Typography>
              )}
            </InfiniteScrollContainer>
          </Stack>
          <Stack
            sx={{ flex: '1 0 50%', minWidth: 10 }}
            data-test-id={testId(
              DATA_TEST_IDS.insuranceInsurance + '_md-column-2'
            )}
          >
            <Stack p={boxPaddings}>
              <Stack direction={'row'} alignItems={'end'} gap={12}>
                <Typography variant={'h2'} mb={0}>
                  {t('CONNECTED_COMPANIES')}
                </Typography>
                <Typography variant={'14_18_500'} color={'grey.400'} pb={2}>
                  {t('TOTAL_', { count: companyCarriersItems.length })}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              sx={{ flex: '1 1 auto', position: 'relative', minHeight: '60vh' }}
            >
              <Scrollbar
                data-test-id={testId(
                  DATA_TEST_IDS.insuranceInsurance + '_carriers-scrollbar-md'
                )}
                sx={{
                  position: 'absolute',
                  width: '100%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                {companyInsuranceCarriersListContent}
              </Scrollbar>
            </Stack>
          </Stack>
        </Stack>
      </>
    );
  }

  if (lg) {
    content = (
      <>
        <Stack direction={'row'}>
          <Stack
            data-test-id={testId(
              DATA_TEST_IDS.insuranceInsurance + '_lg-column-1'
            )}
            sx={(t) => ({
              flex: '1 0 60%',
              minWidth: 10,
              borderRight: '1px solid',
              borderColor: t.palette.secondary[300],
            })}
          >
            <Stack p={boxPaddings} pb={'24px!important'} gap={12}>
              <Stack>
                <Typography variant={'h2'} mb={0}>
                  {t('ALL_AVAILABLE_COMPANIES')}
                </Typography>
              </Stack>
              {searchControl}
            </Stack>
            <InfiniteScrollContainer
              data-test-id={testId(
                DATA_TEST_IDS.insuranceInsurance + '_carriers-scrollbar-lg'
              )}
              onLoad={loadMore}
              threshold={1000}
              maxHeight={'66vh'}
            >
              {carriersItems.length ? (
                carriersItems.map((item, index) => {
                  return (
                    <ListItem
                      data-test-id={testId(
                        DATA_TEST_IDS.insuranceInsurance +
                          '_list-item-carrier-item' +
                          index
                      )}
                      key={item.id}
                      label={item.label}
                      disabled={item.disabled}
                      logoSrc={item.src}
                      onClick={() => addCarrier({ carrierId: item.id })}
                    />
                  );
                })
              ) : (
                <Typography
                  variant={'14_18_500'}
                  color={'grey.400'}
                  px={boxPaddings}
                >
                  {t('INSURANCE_CARRIERS_NOT_FOUND')}
                </Typography>
              )}
            </InfiniteScrollContainer>
          </Stack>
          <Stack
            sx={{ flex: '1 0 40%', minWidth: 10 }}
            data-test-id={testId(
              DATA_TEST_IDS.insuranceInsurance + '_lg-column-2'
            )}
          >
            <Stack p={boxPaddings}>
              <Stack direction={'row'} alignItems={'end'} gap={12}>
                <Typography variant={'h2'}>
                  {t('CONNECTED_COMPANIES')}
                </Typography>
                <Typography
                  variant={'14_18_500'}
                  color={'grey.400'}
                  sx={{ whiteSpace: 'nowrap' }}
                  pb={2}
                  data-test-id={testId(
                    DATA_TEST_IDS.insuranceInsurance + '_counter-of-total-items'
                  )}
                >
                  {t('TOTAL_', { count: companyCarriersItems.length })}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              sx={{ flex: '1 1 auto', position: 'relative', minHeight: '60vh' }}
            >
              <Scrollbar
                data-test-id={testId(
                  DATA_TEST_IDS.insuranceInsurance +
                    '_company-carriers-scrollbar-lg'
                )}
                sx={{
                  position: 'absolute',
                  width: '100%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                {companyInsuranceCarriersListContent}
              </Scrollbar>
            </Stack>
          </Stack>
        </Stack>
      </>
    );
  }

  return (
    <>
      <WhiteBox
        sx={{
          paddingX: '0!important',
          pb: '0!important',
          paddingTop: { xs: 24, sm: 0 },
          height: { xs: '100%!important', sm: 'auto' },
        }}
      >
        {content}
      </WhiteBox>
      <Dialog
        data-test-id={testId(
          DATA_TEST_IDS.insuranceInsurance + '_dialog-insurance-bill-type'
        )}
        open={!!removeConfirmationDialog}
        onClose={closeRemoveConfirmation}
        size={'sm'}
      >
        <DialogTitle
          onClose={closeRemoveConfirmation}
          data-test-id={testId(
            DATA_TEST_IDS.insuranceInsurance +
              '_dialog-title-insurance-bill-type'
          )}
        >
          {t('INSURANCE_BILL_TYPE')}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'left!important' }}>
          <Typography variant={'16_20_500'}>
            {t('INSURANCE_BILL_TYPE_WILL_BE_DISABLED_')}
          </Typography>
        </DialogContent>
        <DialogActions spaceBetween>
          <Button
            data-test-id={testId(
              DATA_TEST_IDS.insuranceInsurance + '_dialog-button-cancel'
            )}
            variant={'outlined'}
            color={'secondary'}
            onClick={closeRemoveConfirmation}
          >
            {t('CANCEL')}
          </Button>
          <Button
            data-test-id={testId(
              DATA_TEST_IDS.insuranceInsurance + '_dialog-button-ok'
            )}
            color={'secondary'}
            onClick={() => {
              if (
                removeConfirmationDialog &&
                'carrierId' in removeConfirmationDialog
              )
                removeLastCarrier({
                  carrierId: removeConfirmationDialog?.carrierId,
                });
            }}
          >
            {t('OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
