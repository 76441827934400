import { FC } from 'react';
import { Box, CircularProgress, Fade } from '@mui/material';
export const LoadingBackdrop: FC<{ open: boolean | undefined }> = ({
  open,
}) => {
  return (
    <Fade
      in={open}
      timeout={{ enter: 500, exit: 250 }}
      style={{ transitionDelay: '500ms' }}
    >
      <Box
        sx={{
          zIndex: 1,
          background: '#ffffffa2',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    </Fade>
  );
};
