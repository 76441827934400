import { Box, styled, SxProps, Theme, Typography } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { spreadSx } from '@/utils/spreadSx.ts';

const ButtonContainer = styled('div')`
  display: flex;
  gap: 24px;
  justify-content: end;
  flex-direction: row-reverse;
  .mobile-layout & {
    width: 100%;
    justify-content: space-between;
    button {
      width: 100%;
    }
  }
  .tablet-layout & {
    justify-content: space-between;
    flex: 1 1;
  }
`;
export const BoxFooter: FC<
  PropsWithChildren<{
    text?: ReactNode;
    className?: string;
    spaceBetween?: boolean;
    sx?: SxProps<Theme>;
  }>
> = ({ children, text, className, spaceBetween = true, sx }) => {
  return (
    <Box
      mt={{
        xs: 42,
        sm: 36,
      }}
      className={className}
      sx={[
        {
          display: 'flex',
          gap: 24,
          alignItems: 'center',
          justifyContent: 'end',
        },
        ...spreadSx(sx),
      ]}
    >
      {text && (
        <Box
          sx={{
            flex: '100 1 auto',
            display: 'flex',
            alignItems: 'center',
            '.mobile-layout &': { display: 'none' },
          }}
        >
          {text && <Typography variant={'16_20_500'}>{text}</Typography>}
        </Box>
      )}
      <ButtonContainer
        className="bf-buttons"
        sx={
          spaceBetween && !text
            ? { justifyContent: 'space-between', width: '100%' }
            : {}
        }
      >
        {children}
      </ButtonContainer>
    </Box>
  );
};
