import { useAppDispatch } from '@/store/store';
import { useEffect, useMemo, useState } from 'react';
import { UUID } from '@/types';
import { useBoxBreakpoints, useCheckboxTreeController } from 'ui-kit';
import { CONTENT_BOX_ID } from '@/features/layouts/MainLayout/MainLayout';
import { moduleActions } from '@/store/module/slice.ts';
import { useParams } from '@/routes/appRoutes.ts';
import { usePermissionsSelector } from '@/features/module/LIMS/hooks/usePermissionsSelector.ts';

export const useTreeItems = () => {
  const { roleId, moduleId, companyId } = useParams();
  const {
    selected,
    permissions,
    preSelected,
    groups,
    limsPermissionsTree,
    initLoading,
  } = usePermissionsSelector({ roleId, moduleId, companyId });
  const dispatch = useAppDispatch();
  const boxBreakpoints = useBoxBreakpoints(CONTENT_BOX_ID, ['xs']);
  const isMobilePortraitView = boxBreakpoints.xsOnly;
  const {
    expandedNode: expandedNodeId,
    treeState,
    expand,
    search,
    collapse,
    select,
    deselect,
    showNode,
    setPreselected,
  } = useCheckboxTreeController({
    tree: limsPermissionsTree,
    compactMode: !!isMobilePortraitView,
    onInitialized: () => {
      if (roleId && moduleId && companyId) {
        dispatch(
          moduleActions.setInitLoadingIsDone({ roleId, moduleId, companyId })
        );
      }
    },
    onEmitSelected: (selected) => {
      dispatch(moduleActions.setSelected(selected));
    },
  });

  useEffect(() => {
    if (preSelected) {
      setPreselected(preSelected);
    } // ignored setPreselected cb
    // eslint-disable-next-line
  }, [preSelected]);

  const [searchStr, setSearchStr] = useState('');

  const callbacks = useMemo(() => {
    return {
      search: (str: string) => {
        setSearchStr(str);
        search(str);
      },
      showNode: (uuid: UUID) => {
        const labelOfTheNode = showNode(uuid);
        setSearchStr(labelOfTheNode);
      },
    };
  }, [search, showNode]);

  const treePath = useMemo(() => {
    if (!expandedNodeId) {
      return;
    }
    const path: Array<{ id: UUID; label: string; disabled?: boolean }> = [];
    let nodeId: UUID | undefined = expandedNodeId;
    while (nodeId) {
      path.unshift({
        id: nodeId,
        label: groups[nodeId] ? groups[nodeId].name : 'All',
      });
      nodeId =
        nodeId === 'ROOT' ? undefined : (groups[nodeId]?.parent ?? 'ROOT');
    }
    if (path[path.length - 1]) path[path.length - 1].disabled = true;
    return path;
  }, [expandedNodeId, groups]);

  useEffect(() => {
    setSearchStr('');
  }, [isMobilePortraitView]);

  return {
    searchStr,
    allSelected: Object.keys(permissions).length === selected.length,
    allDeselected: !selected.length,
    initLoading,
    treeState,
    treePath,
    expand,
    collapse,
    select,
    deselect,
    ...callbacks,
  };
};
