import React, { FC, memo } from 'react';
import { InputControl, InputControlProps } from 'ui-kit';
import { FastField, FastFieldProps } from 'formik';
import { getErrorForDisplay } from '@/components/FormikRedux/useFormikError';
import { INHERITED_DATA_TEST_ID } from '@/utils/testId.ts';

export const InputControlF: FC<
  Omit<InputControlProps, 'name'> & { name: string }
> = memo((props) => {
  return (
    <FastField {...props}>
      {({ field, form, meta }: FastFieldProps) => {
        const e = getErrorForDisplay({
          error: meta.error,
          value: meta.value,
          touched: meta.touched,
          submitCount: form.submitCount,
        });
        return (
          <InputControl
            data-test-id={INHERITED_DATA_TEST_ID}
            {...props}
            {...field}
            error={e}
          />
        );
      }}
    </FastField>
  );
});
InputControlF.displayName = 'InputControlF';
