import { UUID } from '@/types';
import { useCompaniesState } from './useCompaniesState';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { companiesActions, FormNames } from '../slice';

export const useCompanyLogo = () => {
  const state = useCompaniesState();
  const dispatch = useDispatch();
  const companyId = state.forms[FormNames.UploadAvatar]?.companyId;
  const callbacks = useMemo(() => {
    return {
      upload: (file: File) => {
        if (companyId) {
          dispatch(
            companiesActions.requestUploadCompanyLogo({
              companyId,
              data: { file },
            })
          );
        } else {
          console.error(
            '[useCompanyLogo#upload] Error. companyId is undefined'
          );
        }
      },
      openDialog: (companyId: UUID) => {
        dispatch(
          companiesActions.setForm({
            forms: { [FormNames.UploadAvatar]: { state: 'active', companyId } },
            merge: true,
          })
        );
      },
      closeDialog: () => {
        dispatch(
          companiesActions.setForm({
            forms: { [FormNames.UploadAvatar]: { state: 'done' } },
            merge: false,
          })
        );
      },
    };
  }, [companyId, dispatch]);
  return {
    companyId,
    dialogOpened: state.forms[FormNames.UploadAvatar]?.state === 'active',
    ...callbacks,
  };
};
