import {
  initialColors,
  paletteColors,
  paletteColorsV2,
} from './vivicaUiKitColors';
import { ThemeColors } from './types';
import { createTheme } from '@mui/material';
import IconSVGCheckbox from '@/images/icons/icon_checkbox_unchecked.svg?react';
import IconSVGCheckboxChecked from '@/images/icons/icon_checkbox_checked.svg?react';
import { alpha } from '@/theme/utils';

export const customColors: ThemeColors = {
  dropdownShadow: 'rgba(77, 77, 77, 0.07)',
  bodyColor: initialColors.secondary5,
  defaultTextColor: paletteColorsV2.grey['800'],
  defaultTextColorAuth: paletteColorsV2.grey['800'],
  sliderBgColor: initialColors.primary,
  primary: initialColors.primary,
  secondary: initialColors.secondary,
  secondaryLight: initialColors.secondaryLight,
  secondary5: initialColors.secondary5,
  secondary10: initialColors.secondary10,
  link: {
    color: initialColors.secondary,
    hover: initialColors.secondaryDark,
    active: initialColors.secondaryLight,
  },
  background: initialColors.white,
  background2: initialColors.secondary5,
  borderLight: alpha(initialColors.secondary, 14),
  all: { ...initialColors, ...paletteColors },
} as const;

export const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
      mobile: 0,
      tablet: 600,
      tabletLs: 900,
      desktop: 1200,
    },
  },
  spacing: 1,
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'transparent',
          },
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          sx: {
            backgroundColor: 'white',
            boxShadow: 'none',
            borderRadius: 0,
            '.mobile-layout &': { marginLeft: '24px', marginRight: '24px' },
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:focus-visible': {
            boxShadow: `inset 0 0 0 2px ${theme.palette.alert.focus} !important`,
          },
        }),
      },
      defaultProps: {
        style: { fontFamily: 'inherit' },
        disableRipple: true,
      },
    },
    MuiPopover: {
      defaultProps: {
        PaperProps: {
          sx: {
            filter: 'drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.15));',
            boxShadow: 'none',
          },
        },
        TransitionProps: { timeout: 0 },
      },
    },
    MuiBackdrop: {
      defaultProps: {
        sx(theme) {
          return {
            backgroundColor: theme.palette.grey[800],
            opacity: '0.5 !important',
          };
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          boxShadow: 'none',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            background: 'none',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: () => ({
          boxSizing: 'border-box',
          backgroundColor: '#fff',
          borderRadius: 0,
          '&:hover': {
            backgroundColor: '#fff',
          },
          '&.Mui-focused': {
            backgroundColor: '#fff',
          },
          '&.Mui-disabled': {
            background: 'none',
          },
          '& > div': {
            padding: '6px 0 6px 0',
          },
          '& > div > div ': {
            color: '#abb3bd',
          },
          '& .MuiInputBase-root ': {
            height: 56,
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: theme.palette.grey[800],
          margin: 0,
          ...(ownerState.labelPlacement === 'top' && {
            alignItems: 'flex-start',
          }),
        }),
        label: () => ({
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '14px',
          input: {
            color: theme.palette.text.primary,
            '&.Mui-disabled': {
              color: theme.palette.text.disabled,
              '-webkit-text-fill-color': theme.palette.text.disabled,
            },
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: { margin: '0 2px 0 0' },
        listbox: ({ theme: t }) => ({
          padding: 0,
          border: '1px solid #DEE9ED',
          maxHeight: '200px',
          overflow: 'auto',
          '& > li': {
            fontSize: '14px',
            fontWeight: 400,
            '&.MuiAutocomplete-option': {
              paddingTop: 0,
              paddingBottom: 0,
            },
            '&.MuiAutocomplete-option[aria-selected="true"]': {
              background: 'none',
            },
            '&.MuiAutocomplete-option[aria-selected="true"].Mui-focused,&.MuiAutocomplete-option:hover':
              {
                color: t.palette.secondary.main,
                background: '#EEF5F8 ',
              },
          },
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <IconSVGCheckbox />,
        checkedIcon: <IconSVGCheckboxChecked />,
      },
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'none',
          },
        },
      },
    },
  },
  /**
   * @deprecated do not use it - provide all colors through extending palette
   */
  colors: { ...customColors },
});
