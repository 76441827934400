import { css as _, Theme } from '@mui/material';

export const globalStyles = (theme: Theme) => _`
    body {
        background-color: ${theme.colors.bodyColor};
        padding-right: 0 !important;
        color: ${theme.palette.text.primary};
    }

    html {
        margin-right: calc(100% - 100vw);
        overflow-x: hidden;
    }

    ${theme.breakpoints.down('sm')} {
        html {
            margin-right: 0;
        }
    }
`;
