export const getFirstWordsLettersFrom = (txt: string, amount = 2) => {
  return txt
    .trim()
    .split(' ')
    .filter(Boolean)
    .slice(0, amount)
    .map((w) => w.substring(0, 1))
    .join('')
    .toUpperCase();
};
