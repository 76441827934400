import { isAxiosError } from 'axios';
import { ValidationError, ValidationErrorType } from '@/types/ValidationError';

export const safeError = (
  e: string | undefined | null,
  replaceMap?: Record<ValidationErrorType, string>
) => {
  let err = ValidationErrorType.UNKNOWN_ERROR;
  if (
    Object.keys(ValidationErrorType).includes(e ?? '') ||
    /max_size/i.test(e ?? '')
  ) {
    err = e as ValidationErrorType;
  } else {
    console.error('[safeError] Validation error type is unknown', e);
    return ValidationErrorType.UNKNOWN_ERROR;
  }
  if (replaceMap?.[err]) {
    err = replaceMap[err] as ValidationErrorType;
  }
  return err;
};
export const getValidationError = (
  errors:
    | { error: ValidationErrorType; fieldErrors: ValidationError[] }
    | ValidationError[],
  replaceMap?: Record<ValidationErrorType, string>
) => {
  if (errors instanceof Array) {
    return safeError(errors[0].error, replaceMap);
  } else {
    const { error, fieldErrors } = errors;
    return safeError(error ?? fieldErrors?.[0].error, replaceMap);
  }
};

// Error handlers
export const getError = (
  e: unknown,
  replaceMap?: Record<ValidationErrorType, string>
) => {
  if (!e) {
    return ValidationErrorType.UNKNOWN_ERROR;
  }
  if (isAxiosError(e)) {
    if (e.response?.status === 403) {
      return ValidationErrorType.INSUFFICIENT_ACCESS_RIGHTS;
    }
    if (e.response?.data) {
      return getValidationError(e.response.data, replaceMap);
    }
  }
  return ValidationErrorType.UNKNOWN_ERROR;
};
export const getErrors = (
  e: unknown
): Record<'common' | string, ValidationErrorType> => {
  if (!e) {
    return { common: ValidationErrorType.UNKNOWN_ERROR };
  }
  if (!isAxiosError(e)) {
    return { common: ValidationErrorType.UNKNOWN_ERROR };
  }
  if (e.response?.status === 403) {
    return { common: ValidationErrorType.INSUFFICIENT_ACCESS_RIGHTS };
  }
  if (e.response?.data) {
    const data: {
      error?: ValidationErrorType;
      fieldErrors?: ValidationError[];
    } = e.response.data;
    if (data.error) {
      return { common: safeError(data.error) };
    }
    if (data.fieldErrors?.length) {
      return data.fieldErrors.reduce<Record<string, ValidationErrorType>>(
        (acc, { field, error }) => {
          acc[field] = safeError(error);
          return acc;
        },
        {}
      );
    }
  }
  return { common: ValidationErrorType.UNKNOWN_ERROR };
};
