/** @jsxImportSource @emotion/react */
import { useDispatch } from 'react-redux';
import { useCompaniesState } from '@/store/companies/hooks';
import { UUID } from '@/types';
import { companiesActions } from '@/store/companies/slice';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useRef } from 'react';
import { useADAuthorize } from '@/store/companies/hooks/useADConnect';
import { ModuleType } from '@/api/__generated__/webApi';
import {
  KnownSearchParams,
  QueuedTaskType,
  SearchParamNames,
  toSearchParams,
} from '@/utils/toSearchParams.tsx';

export const useEnterToModule = () => {
  const dispatch = useDispatch();
  const state = useCompaniesState();
  const [searchParams, setSearchParams] = useSearchParams();
  const authorize = useADAuthorize();

  const enter = useCallback(
    ({
      moduleType,
      companyId,
      isRedirectBackAfterAuth,
    }: {
      moduleType: ModuleType;
      companyId: UUID;
      isRedirectBackAfterAuth?: boolean;
    }) => {
      const company = state.companies.byID[companyId];

      if (!company?.locked) {
        dispatch(
          companiesActions.requestEnterModule({
            moduleType,
            companyId,
          })
        );
      } else {
        if (isRedirectBackAfterAuth) {
          console.error(
            '[useEnterModule] Authorization already been requested, though the company is still locked'
          );
          return;
        }
        authorize({
          companyId,
          url: currentUrlWithSearchParams({
            companyId,
            moduleType,
            queuedTask: QueuedTaskType.ModuleRedirect,
            isRedirectBackAfterAuth: 'YES',
          }),
        });
      }
    },
    [authorize, dispatch, state.companies.byID]
  );

  const enterCallbackRef = useRef(enter);
  enterCallbackRef.current = enter;

  useEffect(() => {
    if (
      searchParams.get(SearchParamNames.QueuedTask) ===
      QueuedTaskType.ModuleRedirect
    ) {
      const moduleType = searchParams.get(
        SearchParamNames.ModuleType
      ) as ModuleType;
      const companyId = searchParams.get(SearchParamNames.CompanyId);
      const isRedirectBackAfterAuth = !!searchParams.get(
        SearchParamNames.IsRedirectBackAfterAuth
      );

      if (moduleType && companyId) {
        enterCallbackRef.current({
          moduleType,
          companyId,
          isRedirectBackAfterAuth,
        });
      }
      setSearchParams('');
    }
  }, [searchParams, setSearchParams]);
};

const currentUrlWithSearchParams = (searchParams: KnownSearchParams) => {
  return `${window.location.href}${
    window.location.href.includes('?') ? '&' : '?'
  }${toSearchParams(searchParams)}`;
};
