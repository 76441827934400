import React, { useEffect, useRef, useState } from 'react';

export type FileEvent = { target: { name: string; files: FileList } };
export const useFileOperations = ({
  onChange,
  name,
  multiple,
}: {
  onChange?: (e: FileEvent, files: FileList) => void;
  name?: string;
  multiple?: boolean;
}) => {
  const [filesRecord, setFilesRecord] = useState<Record<string, File>>({});
  const handleDelete = (fileName: string) => {
    setFilesRecord((old) => {
      const st = {
        ...old,
      };
      delete st[fileName];
      return st;
    });
  };
  const updateTheState = (files: FileList | undefined | null) => {
    if (!files?.length) {
      return;
    }
    const newFiles: Record<string, File> = {};

    if (!multiple) {
      const file = files[0];
      if (file) {
        setFilesRecord({ [file.name]: file });
      }
      return;
    }

    for (const file of files) {
      newFiles[file.name] = file;
    }

    setFilesRecord((old) => {
      return {
        ...old,
        ...newFiles,
      };
    });
  };
  const handleChange: React.ChangeEventHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    updateTheState(e.target.files);
  };
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  useEffect(() => {
    const dt = new DataTransfer();
    for (const file of Object.values(filesRecord)) {
      dt.items.add(file);
    }
    const evt = {
      target: {
        name: name ?? '',
        files: dt.files,
        value: dt.files,
      },
    };
    onChangeRef.current?.(evt, dt.files);
  }, [filesRecord, name]);

  return {
    files: Object.values(filesRecord),
    handleDelete,
    handleAddFiles: updateTheState,
    handleChange,
  };
};
