import React, { FC, MouseEventHandler } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { Link } from '@/components';
import ArrowLeft from 'ui-kit/lib/icons/24/Arrow/ChevronLeft';
import { inheritDataTestId } from '@/utils/testId.ts';

export const BackButton: FC<{
  onClick: MouseEventHandler<HTMLAnchorElement>;
}> = ({ onClick, ...props }) => {
  const { t } = useTranslate('common');
  return (
    <Breadcrumbs
      sx={{
        mb: {
          xs: 16,
          sm: 24,
        },
      }}
    >
      <Link
        to={''}
        onClick={onClick}
        data-test-id={inheritDataTestId(props, '_link-back')}
      >
        <ArrowLeft size={18} /> {t('BACK')}
      </Link>
    </Breadcrumbs>
  );
};
