import { forwardRef } from 'react';
import IconQuestionMark from 'ui-kit/lib/icons/24/Warning/Question';
import { IconButton } from '@/components/IconButton';
import { INHERITED_DATA_TEST_ID } from '@/utils/testId.ts';

export const QuestionMarkButton = forwardRef<
  HTMLButtonElement,
  { onClick: React.MouseEventHandler }
>((props, ref) => {
  return (
    <IconButton
      data-test-id={INHERITED_DATA_TEST_ID}
      ref={ref}
      color={'quaternary'}
      {...props}
      size={'lg'}
    >
      <IconQuestionMark />
    </IconButton>
  );
});
QuestionMarkButton.displayName = 'Bt';
