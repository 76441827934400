import { CompanyHeader } from '@/features/company/fragments/CompanyHeader';
import React, { FC, lazy, Suspense } from 'react';
import { useSessionOwnerData } from '@/store/profile/hooks';
import { useParams } from '@/routes/appRoutes';
import { errorToast, successToast } from '@/components/Toast';
import { TKeys, useTranslate } from '@/i18n/useTranslate';

import { useCompanyPermissions } from '@/store/permissions/useCompanyPermissionsValidator.tsx';

const UsersControlReact = lazy(
  () => import('@/features/users-control/UsersControlReact')
);

export const CompanyUsersControlPage: FC = () => {
  const { companyId } = useParams();
  const { account: sessionOwner } = useSessionOwnerData();
  const { t } = useTranslate('companies');
  const p = useCompanyPermissions(companyId);
  return (
    <>
      <CompanyHeader />
      <Suspense fallback={null}>
        <UsersControlReact
          companyId={companyId}
          accountId={sessionOwner?.id}
          permissions={p.permissions}
          xModuleType={'PASSPORT'}
          onEvent={(e) => {
            switch (e.detail.type) {
              case 'NOTIFY_SUCCESS':
                successToast({
                  text: t(e.detail.text as TKeys, {
                    at: e.detail.data as string,
                  }),
                });
                break;
              case 'NOTIFY_ERROR':
                errorToast({ text: e.detail.text as TKeys });
                break;
              case 'WRONG_PERMISSIONS':
                p.update();
            }
          }}
        />
      </Suspense>
    </>
  );
};
