import { FC, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import IconSVGSuccess from 'ui-kit/lib/icons/IconsFilled/24/Alert/Success';
import IconSVGWarning from 'ui-kit/lib/icons/IconsFilled/24/Alert/Attention';
import IconSVGInfo from 'ui-kit/lib/icons/IconsFilled/24/Alert/Information';
import IconSVGError from 'ui-kit/lib/icons/IconsFilled/24/Alert/Error';
import { Nowrap } from '@/components/Text';

interface Props {
  children: ReactNode;
  type: 'warning' | 'success' | 'info' | 'error';
}

export const Header: FC<Props> = ({ type, children }) => {
  let icon;
  switch (type) {
    case 'success':
      icon = <Box component={IconSVGSuccess} sx={styles.icon} />;
      break;
    case 'warning':
      icon = <Box component={IconSVGWarning} sx={styles.icon} />;

      break;
    case 'info':
      icon = <Box component={IconSVGInfo} sx={styles.icon} />;
      break;
    case 'error':
      icon = <Box component={IconSVGError} sx={styles.icon} />;
      break;
    default:
      icon = <></>;
  }

  return (
    <Typography component={'span'} sx={styles.container} variant={'18_24_700'}>
      {icon}
      <Nowrap>{children}</Nowrap>
    </Typography>
  );
};

const styles = {
  icon: { minWidth: 20, flexShrink: 0 },
  container: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    minWidth: 0,
    svg: {
      padding: '0 12px 0 0',
    },
  },
};
