import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import IconCheckMarkDisabled from 'ui-kit/lib/icons/24/Warning/Success';
import { inheritDataTestId } from '@/utils/testId.ts';
export const CompanyListItem: FC<{
  label: string;
  onClick: () => void;
}> = ({ label, onClick, ...props }) => {
  return (
    <Stack
      data-test-id={inheritDataTestId(props)}
      onClick={onClick}
      direction={'row'}
      sx={styles.container}
    >
      <Stack
        justifyContent={'center'}
        sx={(t) => ({ color: t.palette.secondary.main })}
      >
        <IconCheckMarkDisabled size={24} />
      </Stack>
      <Stack justifyContent={'center'}>
        <Typography variant={'14_18_500'}>{label}</Typography>
      </Stack>
    </Stack>
  );
};
const styles = {
  container: {
    gap: { xs: 7, sm: 12 },
    minHeight: 58,
    paddingLeft: 24,
    paddingRight: 12,
    paddingY: 6,
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
};
