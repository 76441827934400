import { FC, PropsWithChildren } from 'react';
import { css, styled } from '@mui/material';
import { alpha } from '@/theme/utils';

const StyledTr = styled('tr')<{ error?: boolean }>(
  ({ theme: th, error }) => css`
    border-bottom: 1px solid
      ${error ? alpha(th.palette.primary.main, 14) : th.colors.borderLight};
    background-color: ${error ? alpha(th.palette.primary.main, 7) : ''};
    height: 48px;
    &:hover {
      tbody & {
        border-bottom: 1px solid
          ${!error
            ? alpha(th.palette.secondary.main, 14)
            : alpha(th.palette.primary.main, 14)};
        background-color: ${!error && alpha(th.palette.secondary.main, 7)};
      }
    }
  `
);
export const Tr: FC<
  PropsWithChildren<{ className?: string; error?: boolean }>
> = ({ children, className, error, ...props }) => {
  return (
    <StyledTr className={className} error={error} {...props}>
      {children}
    </StyledTr>
  );
};
