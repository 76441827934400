import { useRef } from 'react';

export const useEventEffect = <ARGS extends Array<unknown>>(
  fn: ((...d: ARGS) => void) | undefined
) => {
  const onExpandRef = useRef(fn);
  onExpandRef.current = fn;
  const cb = useRef<typeof fn>((...args: ARGS) => {
    onExpandRef.current?.(...args);
  }).current;
  return cb;
};
