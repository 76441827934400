import {
  BoxFooter,
  BoxHeader,
  boxPaddingsSx,
  boxXPaddings,
  boxYPaddings,
  WhiteBox,
} from '@/components/WhiteBox';
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC, Fragment, useRef } from 'react';
import {
  useNewRoleForm,
  useRoles,
  useUpdateDetails,
} from '@/store/companies/hooks';
import { Button } from '@/components/Button';
import { UrlToModuleTypeMap, useParams } from '@/routes/appRoutes';
import { CompanyPermissionName } from '@/types';
import { CreateNewRoleForm } from './fragments/CreateNewRoleForm';
import { Stack, SxProps, Theme } from '@mui/material';
import { CompanyHeader } from '@/features/company/fragments/CompanyHeader';
import IconSVGPlus from 'ui-kit/lib/icons/24/Math/Plus';
import { Grid, Nothing } from '@/components';
import { PermissionsKeeper } from '@/store/permissions/PermissionsKeeper';
import { useEditRoleForm } from '@/store/companies/hooks/useEditRoleForm';
import { EditRoleForm } from '@/features/module/ModulePage/fragments/EditRoleForm';
import { ModuleType } from '@/api/__generated__/webApi';
import { isEqual } from 'lodash-es';
import { Scrollbar, useBoxBreakpoints } from 'ui-kit';
import { RolesLists } from '@/features/module/ModulePage/RolesLists.tsx';
import { CONTENT_BOX_ID } from '@/features/layouts/MainLayout/MainLayout.tsx';
import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

const useValuesChanged = (data: Record<string, string | undefined | null>) => {
  const ref = useRef(data);
  if (!isEqual(ref.current, data)) {
    ref.current = data;
    return true;
  }
  return false;
};

export const ModulePage: FC = () => {
  const { t, ready } = useTranslate('companies');
  useUpdateDetails();
  const {
    createNewRoleFormIsShown,
    rollbackCreateNewRoleFormData,
    createNewRole,
  } = useNewRoleForm();
  const { editRoleFormShown, resetEditRoleForm } = useEditRoleForm();
  const { roles, customRolesIds, fetching } = useRoles();
  const { module, companyId, moduleId } = useParams();
  const { lg } = useBoxBreakpoints(CONTENT_BOX_ID, ['lg']);
  const moduleType = module ? UrlToModuleTypeMap[module] : null;
  const formsAreShown = editRoleFormShown || createNewRoleFormIsShown;
  const forAllXLScreensAndLarger = lg;
  const forAllScreensSmallerThanXl = !lg;

  const changed = useValuesChanged({ module, companyId, moduleId });
  if (changed) {
    rollbackCreateNewRoleFormData();
    resetEditRoleForm();
  }

  if (!ready) {
    return null;
  }

  let content = <>...</>;

  const systemRolesHeadersMap: Record<ModuleType, string> = {
    [ModuleType.LIS]: t('LIS_SYSTEM_ROLES'),
    [ModuleType.PASSPORT]: t('PASSPORT_SYSTEM_ROLES'),
    [ModuleType.PROFESSIONAL]: t('HEALTHCARE_PROFESSIONAL_SYSTEM_ROLES'),
  };

  const canHaveCustomRoles = moduleType !== ModuleType.PASSPORT;

  const createNewRoleForm = (sx?: SxProps<Theme>) => (
    <CreateNewRoleForm back={rollbackCreateNewRoleFormData} sx={sx} />
  );
  const editRoleForm = (sx?: SxProps<Theme>) => (
    <EditRoleForm back={resetEditRoleForm} sx={sx} />
  );

  const isEmpty = !roles?.allIDs.length;
  const isLoading = fetching && isEmpty;

  content = (
    <>
      <PermissionsKeeper
        show={(has) => has(CompanyPermissionName.CompanyViewAllRoles)}
      >
        <Scrollbar
          data-test-id={testId(
            DATA_TEST_IDS.modulePageModulePage + '_scrollbar'
          )}
          sx={{ px: boxXPaddings }}
          shadow
        >
          <BoxHeader sx={[{ pt: boxYPaddings }, { pb: '0!important' }]}>
            {moduleType && systemRolesHeadersMap[moduleType]}
          </BoxHeader>
          {isLoading ? (
            <Nothing>{t('LOADING')}</Nothing>
          ) : isEmpty ? (
            <Nothing>{t('EMPTY')}</Nothing>
          ) : (
            <RolesLists
              roles={roles}
              customRoleIds={customRolesIds}
              disableEdit={formsAreShown}
            />
          )}
        </Scrollbar>
      </PermissionsKeeper>
      <PermissionsKeeper
        show={(has) => has(CompanyPermissionName.ModuleCreateCustomRole)}
      >
        {canHaveCustomRoles && !formsAreShown ? (
          <BoxFooter sx={styles.boxFooter}>
            <Button
              data-test-id={testId(
                DATA_TEST_IDS.modulePageModulePage + '_button-add-role'
              )}
              color="primary"
              onClick={createNewRole}
              startIcon={<IconSVGPlus />}
            >
              {t('ADD_ROLE')}
            </Button>
          </BoxFooter>
        ) : (
          <Stack height={boxYPaddings} />
        )}
        {forAllScreensSmallerThanXl && formsAreShown && (
          <Stack sx={[boxPaddingsSx, {}]}>
            {createNewRoleFormIsShown && createNewRoleForm()}
            {editRoleFormShown && editRoleForm()}
          </Stack>
        )}
      </PermissionsKeeper>
    </>
  );

  return (
    <Fragment key={module}>
      <CompanyHeader />
      <Grid container spacing={{ xs: 24, lg: 36 }} sx={lg ? styles.gridLg : {}}>
        <Grid xs height={'100%'}>
          <WhiteBox sx={[styles.wb, lg ? styles.wbLg : {}]}>{content}</WhiteBox>
        </Grid>
        {forAllXLScreensAndLarger && (
          <>
            {createNewRoleFormIsShown && (
              <Grid xs={4}>
                <WhiteBox>
                  <BoxHeader mb={30}>{t('CUSTOM_ROLES')}</BoxHeader>
                  {createNewRoleForm({ height: '100%' })}
                </WhiteBox>
              </Grid>
            )}
            {editRoleFormShown && (
              <Grid xs={4}>
                <WhiteBox>
                  <BoxHeader mb={30}>{t('EDIT_ROLE')}</BoxHeader>
                  {editRoleForm({ height: '100%' })}
                </WhiteBox>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Fragment>
  );
};

const styles = {
  boxFooter: {
    ...boxPaddingsSx,
    paddingY: { xs: 18, sm: '24px!important' },
    marginTop: '0!important',
  },
  wb: { padding: '0!important', height: '100%' },
  wbLg: { minHeight: 0 },
  gridLg: { minHeight: 0, height: '100%' },
} satisfies Record<string, SxProps<Theme>>;
