import { useLocation, useNavigate } from 'react-router-dom';

export interface TabType {
  path: string;
  id: string;
}

export const useRouteTabs = (
  TABS: TabType[],
  defaultTab: TabType | null = null
) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Identify the tab that corresponds to the current route
  const activeTab = TABS.find((tab) => location.pathname.startsWith(tab.path));
  // Function to change the route (and effectively switch tabs)
  const handleTabChange = (newPath: string) => {
    navigate(newPath);
  };

  return {
    activeTab: activeTab || defaultTab,
    handleTabChange,
  };
};
