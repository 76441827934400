import React, { FC } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { Nothing, WhiteBox } from '@/components';
import { Box, Typography } from '@mui/material';
import { Button } from 'ui-kit';
import { styles } from './styles';
import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';
export const NoAddressView: FC<{ onAdd: () => void }> = ({ onAdd }) => {
  const { t } = useTranslate('profile');
  return (
    <WhiteBox>
      <Typography
        variant={'h2'}
        mb={{
          xs: 12,
          lg: 24,
        }}
      >
        {t('CONTACT_ADDRESS')}
      </Typography>
      <Nothing sx={styles.nothing}>{t('YOU_HAVENT_ADDED_AN_ADDRESS')}</Nothing>
      <Box>
        <Button
          variant={'outlined'}
          onClick={onAdd}
          data-test-id={testId(
            DATA_TEST_IDS.noAddressView + '_button-add-address'
          )}
        >
          {t('ADD_ADDRESS')}
        </Button>
      </Box>
    </WhiteBox>
  );
};
