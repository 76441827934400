import React from 'react';
import ReactDOM from 'react-dom/client';
import { ContextWrapper } from './ContextWrapper';
import { AppRouter } from '@/routes/AppRouter';
import { CookieDialog } from './features/cookies/CookieDialog';
import './scripts';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// eslint-disable-next-line react-refresh/only-export-components
const AppContainer: React.FC = () => {
  return (
    <ContextWrapper>
      <AppRouter />
      <CookieDialog />
    </ContextWrapper>
  );
};

root.render(<AppContainer />);
