import { FC } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { Button } from '@/components/Button';
import { useNavigate } from 'react-router';
import { Space } from '../../types';
import { AppRoutes } from '@/routes/appRoutes';
import { Stack, Typography } from '@mui/material';
import { AuthHeader } from '@/features/authentication/common/AuthHeader';
import { ConfirmResponse } from '@/api';
import { getName } from '@/utils/getName';
import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

export const SuccessFragment: FC<{ space: Space; info?: ConfirmResponse }> = ({
  space,
  info,
}) => {
  const { t } = useTranslate('auth');
  const navigate = useNavigate();
  let pathname: string = AppRoutes.AUTH;
  const onClickOk = () => {
    navigate({
      pathname,
      search: space === Space.Activation ? '' : window.location.search,
    });
  };

  let descriptionText = '';
  switch (space) {
    case Space.Activation:
      pathname = AppRoutes.PROFILE;
      if (info) {
        descriptionText = t('REGISTRATION_COMPLETED_SUCCESSFULLY__ACTIVATION', {
          phone: info?.phone ?? '',
          name: getName(info, true),
          company: info?.companyName,
        });
      } else {
        descriptionText = t('REGISTRATION_COMPLETED_SUCCESSFULLY_');
      }
      break;
    case Space.Registration:
      pathname = AppRoutes.PROFILE;
      descriptionText = t('REGISTRATION_COMPLETED_SUCCESSFULLY_');
      break;
    case Space.Recovery:
      descriptionText = t('ACCOUNT_PASSWORD_HAS_BEEN_CHANGED_SUCCESSFULLY');
      break;
  }

  return (
    <Stack width={'100%'} justifyContent={{ xs: 'space-between', sm: 'start' }}>
      <Stack sx={{ mb: { sm: 380, lg: 305 }, flex: { sm: '1 1 auto' } }}>
        <AuthHeader
          title={t('CONGRATULATIONS!')}
          sx={{ mb: { xs: 36, sm: 48, lg: 64 } }}
        />
        <Typography
          sx={{
            whiteSpace: 'pre-wrap',
            typography: { sm: '16_20_500', xs: '14_18_500' },
          }}
        >
          {descriptionText}
        </Typography>
      </Stack>
      <Button
        data-test-id={testId(DATA_TEST_IDS.successFragment + '_button-ok')}
        fullWidth
        color={'secondary'}
        onClick={onClickOk}
      >
        {t('OK')}
      </Button>
    </Stack>
  );
};
