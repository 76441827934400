import { UUID } from '@/types';

interface LocalItem {
  id: UUID;
  name: string;
  parent?: UUID;
}

export interface TreePath {
  id: UUID;
  label: string;
}

export const getPath = (startId: UUID, r: Record<UUID, LocalItem>) => {
  const path: TreePath[] = [];
  let pointer: LocalItem | undefined = r[startId];
  while (pointer) {
    const el = {
      label: pointer.name,
      id: pointer.id,
    };
    path.push(el);
    pointer = pointer.parent ? r[pointer.parent] : undefined;
  }
  return path.reverse();
};
