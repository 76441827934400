/** @jsxImportSource @emotion/react */
import { useDispatch } from 'react-redux';
import { useProfileState } from '@/store/profile/hooks';
import { useTranslate } from '@/i18n/useTranslate';
import { profileActions } from '@/store/profile/slice';
import { Dialog, DialogContent, DialogTitle } from '@/components';

import DATA_TEST_IDS from './dataTestIds';
import { testId } from '@/utils/testId';

export const AboutSystem = () => {
  const dispatch = useDispatch();
  const { aboutSystemShown } = useProfileState();
  const { t } = useTranslate('common');
  const hideAboutSystem = () => {
    dispatch(profileActions.setDisplayInfoAboutSystem(false));
  };
  return (
    <Dialog
      data-test-id={testId(DATA_TEST_IDS.fragmentsAboutSystem + '_dialog')}
      open={!!aboutSystemShown}
      size={'xs'}
      onClose={hideAboutSystem}
    >
      <DialogTitle
        data-test-id={testId(
          DATA_TEST_IDS.fragmentsAboutSystem + '_dialog-title'
        )}
        onClose={hideAboutSystem}
      >
        {t('ABOUT_SYSTEM')}
      </DialogTitle>
      <DialogContent>{t('VIVICA_AND_THE_VIVICA_LOGO_ARE_')}</DialogContent>
    </Dialog>
  );
};
