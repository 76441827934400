import { useParams } from '@/routes/appRoutes.ts';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@/store/store.ts';
import { useCallback } from 'react';
import { CompanyPermissionName, UUID } from '@/types';
import { permissionsActions } from '@/store/permissions/slice.ts';

export const useCompanyPermissionsValidator = (
  companyIdFromParams?: boolean
) => {
  const { companyId } = useParams();
  const permissionsStore = useSelector((state: RootState) => state.permissions);
  const hasPermission = useCallback(
    (permission: CompanyPermissionName, companyId?: UUID) => {
      if (!companyId) {
        console.error(
          '[useCompanyPermissionsValidator] CompanyId is not provided'
        );
      }
      const companyPermissions =
        companyId && permissionsStore.permissions?.byID[companyId];
      if (!companyPermissions) {
        return false;
      }
      return companyPermissions.permissions.includes(permission);
    },
    [permissionsStore.permissions?.byID]
  );

  const companyPermissions =
    companyId && permissionsStore.permissions?.byID[companyId];
  const hasPermissionStaticCompanyId = useCallback(
    (permission: CompanyPermissionName) => {
      if (!companyPermissions) {
        return false;
      }
      return companyPermissions.permissions.includes(permission);
    },
    [companyPermissions]
  );

  return companyIdFromParams ? hasPermissionStaticCompanyId : hasPermission;
};
export const useCompanyPermissions = (companyId: UUID | undefined) => {
  const permissionsStore = useSelector((state: RootState) => state.permissions);
  const dispatch = useAppDispatch();
  const permissions = companyId
    ? permissionsStore.permissions?.byID[companyId]
    : undefined;
  const cb = useCallback(() => {
    if (companyId) {
      return dispatch(
        permissionsActions.requestCompanyPermissions({ companyId })
      );
    } else {
      console.error('[requestCompanyPermissions] CompanyId is undefined');
    }
  }, [companyId, dispatch]);
  return {
    update: cb,
    permissions,
  };
};
