import { FC, PropsWithChildren } from 'react';
import { useParams } from '@/routes/appRoutes.ts';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { CompanyPermissionName } from '@/types';

interface KeeperProps {
  show: (has: (permission: CompanyPermissionName) => boolean) => boolean;
  replaceWith?: JSX.Element;
}
export const PermissionsKeeper: FC<PropsWithChildren<KeeperProps>> = ({
  children,
  show,
  replaceWith,
}) => {
  const { companyId } = useParams();
  const permissionsStore = useSelector((state: RootState) => state.permissions);
  const companyPermissions =
    companyId && permissionsStore.permissions?.byID[companyId];

  if (!companyId || !permissionsStore.permissions || !companyPermissions) {
    return replaceWith ?? null;
  }
  const hasPermission = (permission: CompanyPermissionName) =>
    companyPermissions.permissions.includes(permission);
  if (!show(hasPermission)) {
    return replaceWith ?? null;
  }

  return <>{children}</>;
};
