import React, { PropsWithChildren, useLayoutEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import store, { browserHistory } from '@/store/store';
import {
  unstable_HistoryRouter as HistoryRouter,
  HistoryRouterProps,
} from 'react-router-dom';
import { useMQuery } from '@/hooks/useMQuery';
import { ThemeProvider } from '@/theme/ThemeProvider';
import { ToastContainer } from '@/components/Toast/ToastContainer';
import '@/css/styles.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'normalize.css';
import '@/i18n/i18n';
import { useTranslate } from '@/i18n/useTranslate';
import { Settings } from 'luxon';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { injectStore } from '@/api/client/interceptors';
import { UiKitThemeProvider } from 'ui-kit';
import { GoogleLoaderProvider } from 'ui-kit/lib/address';
import { envs } from '@/constants';

injectStore(store);
const persistor = persistStore(store);

export const ContextWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const matchedQueries = useMQuery();
  const { mobile, tablet } = matchedQueries;
  const { i18n } = useTranslate('common');

  Settings.defaultLocale = i18n.language;
  useLayoutEffect(() => {
    if (mobile) {
      window.document.body.classList.add('mobile-layout');
      window.document.body.classList.remove('tablet-layout');
    } else if (tablet) {
      window.document.body.classList.add('tablet-layout');
      window.document.body.classList.remove('mobile-layout');
    } else {
      window.document.body.classList.remove('mobile-layout');
      window.document.body.classList.remove('tablet-layout');
    }
  }, [mobile, tablet]);

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HistoryRouter
          history={browserHistory as unknown as HistoryRouterProps['history']}
        >
          <ThemeProvider>
            <UiKitThemeProvider>
              <GoogleLoaderProvider
                apiKey={envs.GOOGLE_API_KEY}
                language={'en-US'}
              >
                {children}
              </GoogleLoaderProvider>
              <ToastContainer />
            </UiKitThemeProvider>
          </ThemeProvider>
        </HistoryRouter>
      </PersistGate>
    </ReduxProvider>
  );
};
